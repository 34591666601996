import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Collapse,
  Dropdown,
  Image,
  Menu,
  Space,
  Spin,
  Typography,
} from "antd";
import { Inventory } from "../../redux/reducers/InventoryReducer";
import { Store } from "../../redux/reducers/StoreReducer";
import styled from "styled-components";
import {
  CaretDownFilled,
  CaretDownOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  EditOutlined,
} from "@ant-design/icons";
import getSymbolFromCurrency from "currency-symbol-map";
import { inventories } from "../../constants/TestData";
import { ColumnTypes } from "../../consignerScreens/ConsignerInventory";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { getLowestPriceForVariantFromAPI } from "../../redux/actions/inventoryActions";
import { useAppSelector } from "../../redux/hooks";
import { InventoryPageTypes } from "../../screens/Inventory";
import moment from "moment";
import check from "../../assets/images/svg/check.svg";
import InvoiceModal from "../../screens/Inventory/InvoiceModal";
// Styled components
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  //   width: 100%;
  @media (max-width: 768px) {
    border-radius: 4px;
    border: 1px solid #ddd;
    order: 2;
  }
`;

const ProductRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background-color: #fff;
  border-radius: 4px;
  border-bottom: 1px solid rgba(221, 221, 221, 1);
  // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const StylesCollapsePanel = styled(CollapsePanel)`
  .ant-collapse-content-box {
    padding: 0;
  }
`;

const ProductImageContainer = styled.div`
  flex: 0 0 133px;
  width: auto;
  display: flex;
  padding: 5px;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  position: relative;
  .ant-image {
    object-fit: contain;
    max-height: 100%;
    flex: 1;
  }
  .ant-checkbox-wrapper {
    position: absolute;
    flex: 0 0 auto;
    top: 5px;
    left: 0;
    z-index: 2;
  }
  @media (max-width: 768px) {
    flex: 0 0 77px;
  }
`;

const ProductDetails = styled.div`
  flex: 1;
  padding: 5px 16px;
  gap: 18px;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  &.pricing {
    & > div {
      &:not(.Requested) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
  &.center {
    align-items: center;
  }
  &:first-of-type {
    border-left: 1px solid #ddd;
  }
  b {
    align-self: stretch;
    flex: 1;
    a {
      color: inherit;
      white-space: nowrap;
    }
  }
  & > div {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: space-between;
    flex-wrap: nowrap;
    span {
      flex: 1;
      // width: 90px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between; // space-between;
      p {
        margin: 0 0 3px;
        color: #292d32;
      }
      label {
        color: var(--Text---Grey, #989898);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
      }
    }
  }
  @media (max-width: 768px) {
    border-bottom: 1px solid #ddd;
    border-right: none;
    font-size: 10px;
    b {
      font-size: inherit;
    }
    & > div {
      font-size: inherit;
      gap: 16px;
      span {
        justify-content: flex-start;
        font-size: inherit;
        label {
          font-size: inherit;
        }
      }
    }
    &.center {
      align-items: flex-start;
    }
  }
`;

export const ActionButtons = styled.div`
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  div {
    display: flex;
    gap: 11px;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    // padding: 11px 19px;
    align-self: stretch;
    .ant-btn {
      color: #232323;
    }
  }
  @media (max-width: 768px) {
    flex: 0 0 auto;
    padding: 0 8px;
    justify-content: flex-end;
    // div {
    //   flex-direction: row;
    //   justify-content: space-between;
    // }
    .ant-btn {
      // min-width: 32px;
      max-width: 32px;
      max-height: 32px;
      font-size: 16px;
      height: 32px;
      flex-shrink: 0;
      border-radius: 8px;
      background: #b2c7eb;
      &.ant-btn-ghost {
        font-size: 16px;
      }
      span:not(.anticon) {
        display: none;
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  border-radius: 8px;
  width: 130px;
  max-height: 32px;
  flex-shrink: 0;
  flex: 1;
  text-transform: capitalize;
  &.ant-btn-ghost {
    display: flex;
    padding: 11.5px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    flex-grow: 0;
    border-radius: 8px;
    border: 1px solid var(--Line, #ddd);
    background: var(--White, #fff);
    color: var(--Text---Grey, #989898);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &.ant-btn-dangerous {
    background-color: transparent;
    color: initial;
  }
  &:not(.ant-btn-primary) {
    border-radius: 4px;
    // background: var(--Grey---Button-Fill, #f4f4f4);
  }
  @media (max-width: 768px) {
    max-height: 50px;
    padding: 0;
  }
`;

export const ProductRowMobile = styled.div`
  padding-top: 13px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ddd;
`;

const ProductRowHidden = styled.span`
  display: none;
  padding: 16px 10px;
  justify-content: space-evenly;
  border-top: 1px solid #ddd;
  &.expanded {
    display: flex;
  }
  &:first-of-type {
    border-bottom: 1px solid #ddd;
  }
  div {
    display: flex;
    gap: 13px;
  }
  b {
    font-weight: 500;
    font-size: 10px;
  }
  label {
    color: var(--Text---Grey, #989898);
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 15px */
  }
`;

const OrderItemCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & > div {
    flex: 1;
    margin: 0; /* Ensures no extra space is added on the left or right */
  }
`;

export const ProductRowMobileTop = styled.span`
  flex: 1;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 10px;
  .ant-checkbox-wrapper {
    align-self: start;
    position: absolute;
    z-index: 100;
  }
  & > div {
    display: flex;
    & > b {
      font-weight: 500;
      margin-bottom: 13px;
    }
    span {
      div {
        b {
          font-weight: 500;
        }
        label {
          color: var(--Text---Grey, #989898);
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 15px */
        }
      }
    }
  }
`;
const SkuDivMargin = styled.div`
  margin-bottom: 1.4rem;
`;

export const InventoryCardMobileView = ({
  dataSource,
  //   columnSrc,
  //   priceMap,
  status,
}: {
  dataSource: any[];
  //   columnSrc: any[];
  //   priceMap: any[];
  status: any;
}) => {
  const { store } = useAppSelector((state) => state.StoreReducer);
  const [expandedKey, setExpandedKey] = useState(-1);

  const truncateString = (string: any, maxLength: number) => {
    if (string.length > maxLength) {
      return string.substring(0, maxLength) + "...";
    } else {
      return string;
    }
  };

  return (
    <Wrapper>
      {dataSource.map((inventory, key) => (
        <ProductRowMobile key={key}>
          <ProductRowMobileTop>
            {/* {columnSrc
              .find((col) => col.dataIndex === "id")
              ?.render(inventory.id, inventory)} */}
            <Image
              src={inventory.product?.image}
              width={77}
              alt=""
              style={{ flex: "0 0 77px" }}
            />
            <div style={{ flex: 1, flexDirection: "column", fontSize: 12 }}>
              <b style={{ flex: 1 }}>
                {truncateString(inventory.product?.title, 22)}
              </b>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 16,
                    flex: 1,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <b style={{ color: "rgba(116, 116, 116, 1)" }}>
                      {inventory.option1Value}
                    </b>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <b style={{ color: "rgba(116, 116, 116, 1)" }}>
                      {inventory.option2Value}
                    </b>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <b style={{ color: "rgba(116, 116, 116, 1)" }}>
                      {inventory.product?.sku}
                    </b>
                  </div>

                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <b>
                      {getSymbolFromCurrency(store.currency)}
                      {inventory.price}
                    </b>
                    {inventory?.requestedPrice && (
                      <b style={{ color: "green", display: "block" }}>
                        {getSymbolFromCurrency(store.currency)}
                        {inventory.requestedPrice}
                      </b>
                    )}
                    <label>Price</label>
                  </div> */}
                  {/* {status === InventoryPageTypes.Requested && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        flex: 1,
                      }}
                    >
                      <b>
                        {priceMap.find(
                          (rec) =>
                            rec.key ===
                            `${inventory.option1Value}-${inventory?.option2Value}-${inventory?.option3Value}-${inventory?.location}-${inventory?.storeId}-${inventory?.product?.id}`.replace(
                              /[\s]/g,
                              ""
                            )
                        )?.qty ?? inventory.qty}
                      </b>
                      <label>Current stock</label>
                    </div>
                  )} */}
                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <b>{inventory.option2Value}</b>
                    <label>Condition</label>
                  </div> */}
                </span>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 16,
                    flex: 1,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "5px",
                      flex: 1,
                    }}
                  >
                    <label>Price: </label>
                    <b style={{ color: "rgba(116, 116, 116, 1)" }}>
                      {getSymbolFromCurrency(store.currency)}
                      {inventory.price}
                    </b>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "5px",
                      flex: 1,
                    }}
                  >
                    <label>Cost: </label>
                    <b style={{ color: "rgba(116, 116, 116, 1)" }}>
                      {getSymbolFromCurrency(store.currency)}
                      {inventory.cost}
                    </b>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "5px",
                      flex: 1,
                    }}
                  >
                    <label>Profit: </label>
                    <b style={{ color: "rgba(116, 116, 116, 1)" }}>
                      {getSymbolFromCurrency(store.currency)}
                      {inventory.cost}
                    </b>
                  </div>
                </span>
                {/* <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                    flex: 1,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <label>Size</label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <b>
                      {getSymbolFromCurrency(store.currency)}
                      {inventory.payoutAmount
                        ? Number(inventory.payoutAmount).toFixed(1)
                        : "0"}
                    </b>
                    <label>Payout</label>
                  </div>
                  {status === InventoryPageTypes.Requested && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        flex: 1,
                      }}
                    >
                      <b>
                        {getSymbolFromCurrency(store.currency)}
                        {priceMap.find(
                          (rec) =>
                            rec.key ===
                            `${inventory.option1Value}-${inventory?.option2Value}-${inventory?.option3Value}-${inventory?.location}-${inventory?.storeId}-${inventory?.product?.id}`.replace(
                              /[\s]/g,
                              ""
                            )
                        )?.price ?? inventory.price}
                      </b>
                      <label>Current lowest price</label>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <b>{inventory.location ?? inventory.option3Value}</b>
                    <label>Location</label>
                  </div>
                </span> */}
              </div>
            </div>
            <ActionButtons>
              {/* {columnSrc
                .find((col) => col.dataIndex === "operation")
                ?.render("", inventory)} */}
            </ActionButtons>
          </ProductRowMobileTop>
        </ProductRowMobile>
      ))}
    </Wrapper>
  );
};

interface LogsCardViewProps {
  orders: any[];
  store: Store;
  loading: boolean;
  //   columnSrc: any[];
  //   nestedView?: boolean;
  //   groupedData?: any[];
  //   priceMap: { price: number; qty: number; key: string }[];
  //   setPriceMap: any;
  setShowAcceptInventoryModal?: any;
  setSelectedRowKeys?: any;
  setShowRequestedInventoryModal?: any;
  status?: number;
  isInvoice?: boolean;
  invoiceRecord?: any;
  ordersView?: boolean;
  //   groupedDataOrder: any[];
  setIsInvoiceModal: () => void;
  handleInvoiceRecord?: any;
  filters?: any;
  //   handleViewClickProp: (inventory: any) => void;
}

const LogsCardView = (data: LogsCardViewProps) => {
  const {
    orders: dataSource,
    loading,
    store,
    // columnSrc,
    // nestedView,
    // groupedData,
    // groupedDataOrder,
    // priceMap,
    // setPriceMap,
    status,
    // setSelectedRowKeys,
    // setShowAcceptInventoryModal,
    // setShowRequestedInventoryModal,
    isInvoice,
    invoiceRecord,
    setIsInvoiceModal,
    // ordersView,
    // handleViewClickProp,
    handleInvoiceRecord,
    filters,
  } = data;
  const [activeKey, setActiveKey] = useState("");
  const { isMobile } = useAppSelector((state) => state.AppReducer);
  const [fetchOngoing, setFetchOngoing] = useState(false);

  //   const fetchLowestPricesAndQtyOfVariants = async (data: Inventory[]) => {
  //     setFetchOngoing(true);
  //     const prcMap = [...priceMap];
  //     for (const inventoryToReturn of data) {
  //       const key = `${inventoryToReturn.option1Value}-${
  //         inventoryToReturn?.option2Value
  //       }-${inventoryToReturn?.option3Value}-${inventoryToReturn?.location}-${
  //         inventoryToReturn?.storeId
  //       }-${
  //         inventoryToReturn?.productId ?? inventoryToReturn?.product.id
  //       }`.replace(/[\s]/g, "");
  //       const dataFound = prcMap.find((data) => data.key === key);
  //       if (dataFound === undefined || !dataFound) {
  //         const lowestPriceData = await getLowestPriceForVariantFromAPI({
  //           option1Value: inventoryToReturn?.option1Value ?? "",
  //           option2Value: inventoryToReturn?.option2Value ?? undefined,
  //           option3Value: inventoryToReturn?.option3Value ?? undefined,
  //           location: inventoryToReturn?.location ?? "",
  //           productId: Number(inventoryToReturn?.product?.id) ?? 0,
  //         });
  //         prcMap.push({
  //           key,
  //           qty: lowestPriceData.qty,
  //           price: lowestPriceData.price,
  //         });
  //       }
  //     }
  //     setPriceMap(prcMap);
  //     setFetchOngoing(false);
  //   };
  //   const handleViewClick = (inventory) => {
  //     handleViewClickProp(inventory);
  //   };
  //   useEffect(() => {
  //     if (
  //       !dataSource ||
  //       nestedView ||
  //       status !== InventoryPageTypes.Requested ||
  //       fetchOngoing
  //     )
  //       return;
  //     fetchLowestPricesAndQtyOfVariants(dataSource);
  //   }, [dataSource, nestedView, status, fetchOngoing]);
  //   useEffect(() => {
  //     console.log("collapse header====", activeKey, groupedDataOrder);
  //   }, [groupedData, setActiveKey, activeKey]);
  const CollapseHeader = (record: any) => {
    const { activeKey, image, title, keyItem, quantity, sku } = record;
    const expandIcon =
      activeKey === keyItem ? (
        <CaretDownOutlined style={{ flex: "0 0 30px" }} />
      ) : (
        <CaretRightOutlined style={{ flex: "0 0 30px" }} />
      );
    return (
      <>
        <InvoiceModal
          isInvoiceModal={isInvoice}
          invoiceRecord={invoiceRecord}
          closeInvoiceModal={setIsInvoiceModal}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {expandIcon}
          {status === InventoryPageTypes.Find && (
            <img
              height={52.5}
              src={image}
              width={52.5}
              style={{ flex: 1, objectFit: "contain" }}
            />
          )}

          <span
            style={{
              flex: 3,
              fontSize: 14,
              fontWeight: 600,
              lineHeight: "150%",
            }}
          >
            {title}
          </span>
          {status === InventoryPageTypes.Find && (
            <span
              style={{
                flex: 2,
                fontSize: 14,
                fontWeight: 600,
                lineHeight: "150%",
              }}
            >
              {sku}
            </span>
          )}
          <span
            style={{
              flex: "0 0 auto",
              padding: "4px 8px 4px 0",
              fontWeight: 600,
              fontSize: 12,
            }}
          >
            {quantity} Units
          </span>
          <span>
            {status &&
            [
              InventoryPageTypes.Incoming,
              InventoryPageTypes.Requested,
            ].includes(status) ? (
              // <StyledButton
              //   onClick={() => {
              //     setSelectedRowKeys(
              //       inventories.map((inventory) => inventory.id)
              //     );
              //     if (status === InventoryPageTypes.Incoming)
              //       setShowAcceptInventoryModal(true);
              //     else setShowRequestedInventoryModal(true);
              //   }}
              // >
              //   Accept Alll
              // </StyledButton>
              <img
                src={check}
                style={{ cursor: "pointer" }}
                // onClick={() => {
                //   setSelectedRowKeys(
                //     inventories.map((inventory) => inventory.id)
                //   );
                //   if (status === InventoryPageTypes.Incoming)
                //     setShowAcceptInventoryModal(true);
                //   else setShowRequestedInventoryModal(true);
                // }}
              />
            ) : (
              ""
            )}
          </span>
        </div>
      </>
    );
  };
  const CollapseHeaderOrder = (record: any) => {
    const {
      activeKey,
      title,
      keyItem,
      quantity,
      sku,
      inventories,
      shippingCost,
      total,
      name,
      consigner,
      status,
      buyerName,
      sellerName,
    } = record;
    const expandIcon =
      activeKey === keyItem ? (
        <CaretDownOutlined style={{ flex: "0 0 30px" }} />
      ) : (
        <CaretRightOutlined style={{ flex: "0 0 30px" }} />
      );
    console.log("===inventories====", inventories);

    const totalCost = inventories?.reduce(
      (acc: number, item: any) => acc + +item.cost,
      0
    );
    return (
      <>
        <InvoiceModal
          isInvoiceModal={isInvoice}
          invoiceRecord={invoiceRecord}
          closeInvoiceModal={setIsInvoiceModal}
          isSeller={filters?.isSeller}
        />
        <OrderItemCard>
          <div>
            <label style={{ color: "grey", fontWeight: "400" }}>
              {filters.isSeller ? "Seller" : "Buyer"}
            </label>
            <p
              style={{
                fontWeight: "600",
                fontSize: "0.9em",
                marginTop: "4px",
                lineHeight: "1rem",
              }}
            >
              {filters.isSeller ? sellerName : buyerName}
            </p>
          </div>
          <div>
            <label style={{ color: "grey", fontWeight: "400" }}>
              {filters.isSeller ? "Expected Revenue" : "Total Revenue"}
            </label>
            <p
              style={{
                fontWeight: "600",
                fontSize: "0.9em",
                marginTop: "4px",
                lineHeight: "1rem",
              }}
            >
              {getSymbolFromCurrency(store?.currency)}
              {total}
            </p>
          </div>
          <div>
            <label style={{ color: "grey", fontWeight: "400" }}>Cost</label>
            <p
              style={{
                fontWeight: "600",
                fontSize: "0.9em",
                marginTop: "4px",
                lineHeight: "1rem",
              }}
            >
              {getSymbolFromCurrency(store?.currency)}
              {totalCost}
            </p>
          </div>
          <div>
            <label style={{ color: "grey", fontWeight: "400" }}>Profit</label>
            <p
              style={{
                fontWeight: "600",
                fontSize: "0.9em",
                marginTop: "4px",
                lineHeight: "1rem",
              }}
            >
              0
            </p>
          </div>
          <div>
            <label style={{ color: "grey", fontWeight: "400" }}>
              Order Number
            </label>
            <p
              style={{
                fontWeight: "600",
                fontSize: "0.9em",
                marginTop: "4px",
                lineHeight: "1rem",
              }}
            >
              {name}
            </p>
          </div>
          <div>
            <label style={{ color: "grey", fontWeight: "400" }}>Units</label>
            <p
              style={{
                fontWeight: "600",
                fontSize: "0.9em",
                marginTop: "4px",
                lineHeight: "1rem",
              }}
            >
              {quantity}
            </p>
          </div>
          <div>
            <label style={{ color: "grey", fontWeight: "400" }}>
              Shipping Cost
            </label>
            <p
              style={{
                fontWeight: "600",
                fontSize: "0.9em",
                marginTop: "4px",
                lineHeight: "1rem",
              }}
            >
              {getSymbolFromCurrency(store?.currency)}
              {shippingCost}
            </p>
          </div>
          <div>
            <label style={{ color: "grey", fontWeight: "400" }}>Status</label>
            <p
              style={{
                fontWeight: "600",
                fontSize: "0.9em",
                marginTop: "4px",
                lineHeight: "1rem",
              }}
            >
              {status}
            </p>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              style={{
                background: "transparent",
                border: "none",
                color: "black",
                textDecoration: "underline",
                cursor: "pointer",
                fontWeight: "500",
              }}
              //   onClick={() => handleViewClick(inventory)}
            >
              View
            </button>
            <div
              style={{
                padding: "0px 5px",
                border: "1px solid rgba(221, 221, 221, 1)",
                borderRadius: "4px",
              }}
            >
              {/* {expandIcon} */}
              <DownOutlined style={{ width: "0.7em", height: "0.7em" }} />
            </div>
          </div>
          {/* <span
            style={{
              flex: 3,
              fontSize: 14,
              fontWeight: 600,
              lineHeight: "150%",
            }}
          >
            {title}
          </span> */}
          {/* {status === InventoryPageTypes.Find && (
            <span
              style={{
                flex: 2,
                fontSize: 14,
                fontWeight: 600,
                lineHeight: "150%",
              }}
            >
              {sku}
            </span>
          )}
          <span>
            {status &&
            [
              InventoryPageTypes.Incoming,
              InventoryPageTypes.Requested,
            ].includes(status) ? (
              // <StyledButton
              //   onClick={() => {
              //     setSelectedRowKeys(
              //       inventories.map((inventory) => inventory.id)
              //     );
              //     if (status === InventoryPageTypes.Incoming)
              //       setShowAcceptInventoryModal(true);
              //     else setShowRequestedInventoryModal(true);
              //   }}
              // >
              //   Accept Alll
              // </StyledButton>
              <img
                src={check}
                style={{ cursor: "pointer" }}
                // onClick={() => {
                //   setSelectedRowKeys(
                //     inventories.map((inventory) => inventory.id)
                //   );
                //   if (status === InventoryPageTypes.Incoming)
                //     setShowAcceptInventoryModal(true);
                //   else setShowRequestedInventoryModal(true);
                // }}
              />
            ) : (
              ""
            )}
          </span> */}
        </OrderItemCard>
      </>
    );
  };

  const CollapseHeaderOrderMobile = (record: any) => {
    const { total, name, consigner, inventories } = record;
    const totalCost = inventories?.reduce(
      (acc: number, item: any) => acc + +item.cost,
      0
    );

    return (
      <>
        <InvoiceModal
          isInvoiceModal={isInvoice}
          invoiceRecord={invoiceRecord}
          closeInvoiceModal={setIsInvoiceModal}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {/* {status === InventoryPageTypes.Find && (
            <img
              height={52.5}
              src={image}
              width={52.5}
              style={{ flex: 1, objectFit: "contain" }}
            />
          )} */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                padding: "0px 5px",
                border: "1px solid rgba(221, 221, 221, 1)",
                borderRadius: "4px",
                height: "fit-content",
              }}
            >
              <DownOutlined style={{ width: "0.7em", height: "0.7em" }} />
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <label style={{ color: "grey", fontWeight: "400" }}>
                {filters.isSeller ? "Expected Revenue" : "Total Revenue"}
              </label>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "0.9em",
                  marginTop: "4px",
                  lineHeight: "1rem",
                }}
              >
                {getSymbolFromCurrency(store?.currency)}
                {total}
              </p>
            </div>
            <div>
              <label style={{ color: "grey", fontWeight: "400" }}>Cost</label>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "0.9em",
                  marginTop: "4px",
                  lineHeight: "1rem",
                }}
              >
                {getSymbolFromCurrency(store?.currency)}
                {totalCost}
              </p>
            </div>
            <div>
              <label style={{ color: "grey", fontWeight: "400" }}>
                Order Number
              </label>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "0.9em",
                  marginTop: "4px",
                  lineHeight: "1rem",
                }}
              >
                {name}
              </p>
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            {/* <button
              style={{
                background: "transparent",
                border: "none",
                color: "black",
                textDecoration: "underline",
                cursor: "pointer",
                fontWeight: "500",
              }}
              //   onClick={() => handleViewClick(inventory)}
            >
              View
            </button> */}
          </div>
          {/* <span
            style={{
              flex: 3,
              fontSize: 14,
              fontWeight: 600,
              lineHeight: "150%",
            }}
          >
            {title}
          </span> */}
          {/* {status === InventoryPageTypes.Find && (
            <span
              style={{
                flex: 2,
                fontSize: 14,
                fontWeight: 600,
                lineHeight: "150%",
              }}
            >
              {sku}
            </span>
          )}
          <span>
            {status &&
            [
              InventoryPageTypes.Incoming,
              InventoryPageTypes.Requested,
            ].includes(status) ? (
              // <StyledButton
              //   onClick={() => {
              //     setSelectedRowKeys(
              //       inventories.map((inventory) => inventory.id)
              //     );
              //     if (status === InventoryPageTypes.Incoming)
              //       setShowAcceptInventoryModal(true);
              //     else setShowRequestedInventoryModal(true);
              //   }}
              // >
              //   Accept Alll
              // </StyledButton>
              <img
                src={check}
                style={{ cursor: "pointer" }}
                // onClick={() => {
                //   setSelectedRowKeys(
                //     inventories.map((inventory) => inventory.id)
                //   );
                //   if (status === InventoryPageTypes.Incoming)
                //     setShowAcceptInventoryModal(true);
                //   else setShowRequestedInventoryModal(true);
                // }}
              />
            ) : (
              ""
            )}
          </span> */}
        </div>
      </>
    );
  };

  const sortSizes = (sizes: Inventory[]) => {
    const sizeOrder = ["XXS", "XS", "S", "M", "L", "XL", "XXL"];
    return sizes.sort((a, b) => {
      if (
        Number(a.option1Value?.replace(/[^0-9.]/g, "")) ||
        Number(b.option1Value?.replace(/[^0-9.]/g, ""))
      ) {
        return (
          a.option1Value?.replace(/[^0-9.]/g, "") -
          b.option1Value?.replace(/[^0-9.]/g, "")
        );
      }
      // Remove any country prefix and trim whitespace
      const sizeA = a.option1Value?.split(" ").pop();
      const sizeB = b.option1Value?.split(" ").pop();

      // Get the index of sizes from the size order
      const indexA = sizeOrder?.indexOf(sizeA);
      const indexB = sizeOrder?.indexOf(sizeB);

      // Compare the index to sort
      return indexA - indexB;
    });
  };
  //   console.log("Grouped Data", groupedData);
  const handleCollapseChange = (key) => {
    // console.log(key);
    // const dataSrc = dataSource.find((data) => data.key === key);
    // if (status == InventoryPageTypes.Requested) {
    //   fetchLowestPricesAndQtyOfVariants(dataSrc?.inventories);
    // }
    console.log(key, "===================key-=====================");
    setActiveKey(key === activeKey ? null : key);
  };
  function formatDate(timestamp) {
    console.log(timestamp, "========timestamp=========");
    const date = new Date(timestamp);

    // Options to format the date as "Mar 6, 2024"
    const options: any = { year: "numeric", month: "short", day: "numeric" };

    return date.toLocaleDateString("en-US", options);
  }
  //   console.log("===CollapseHeaderOrder=====", groupedDataOrder);

  if (loading) return <Spin />;
  else if (!isMobile)
    return (
      <Wrapper>
        <Collapse
          bordered={false}
          accordion
          expandIcon={() => null}
          activeKey={activeKey}
          onChange={handleCollapseChange}
          style={{ display: "flex", flexDirection: "column" }}
          //   onChange={handleCollapseChange}
        >
          {dataSource?.map((data, key) => {
            const { inventories } = data;
            return (
              <StylesCollapsePanel
                style={{
                  border: "0.5px solid var(--Line, #DDD)",
                }}
                key={`${data.id}`}
                header={
                  <CollapseHeaderOrder
                    keyItem={`${data?.inventories?.orderId}`}
                    activeKey={activeKey}
                    image={data?.productImage}
                    title={data?.inventories[0]?.Seller}
                    sku={data?.productSku}
                    inventories={inventories}
                    total={data?.total}
                    shippingCost={data?.shippingCost}
                    name={data?.name}
                    buyerName={data?.buyerName}
                    sellerName={data?.sellerName}
                    status={data?.inventories[0]?.status}
                    /* title={
                  data?.consigner?.firstName +
                  " " +
                  data?.consigner?.lastName
                } */
                    quantity={inventories?.length ?? 1}
                  />
                }
              >
                {sortSizes(inventories).map((inventory, recordKey) => {
                  let rangeUsed = false;
                  const { storeRequestPriceRange } = inventory;
                  if (
                    storeRequestPriceRange &&
                    storeRequestPriceRange[1] &&
                    storeRequestPriceRange[0]
                  ) {
                    rangeUsed = true;
                  }

                  return (
                    <ProductRow key={inventory.id}>
                      <ProductDetails>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              flex: "0 0 250px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                background: "white",
                                padding: "10px",
                                borderRadius: "5px",
                                marginRight: "10px",
                              }}
                            >
                              <img
                                src={inventory?.product?.image}
                                alt=""
                                style={{
                                  width: "60px",
                                }}
                              />
                            </div>
                            <p
                              style={{
                                fontWeight: "600",
                                fontSize: "0.9em",
                                marginTop: "4px",
                                lineHeight: "1rem",
                              }}
                            >
                              {inventory.product?.title}
                            </p>
                          </div>

                          <div style={{ flex: "1" }}>
                            <label style={{ color: "grey", fontWeight: "400" }}>
                              SKU
                            </label>
                            <SkuDivMargin>
                              <p
                                style={{
                                  fontWeight: "600",
                                  fontSize: "0.9em",
                                  marginTop: "4px",
                                  lineHeight: "1rem",
                                }}
                              >
                                {inventory.product?.sku?.slice(0, 12)}
                                {inventory.product?.sku.length > 12
                                  ? "..."
                                  : null}
                              </p>
                            </SkuDivMargin>
                          </div>
                          <div style={{ flex: "1" }}>
                            <label style={{ color: "grey", fontWeight: "400" }}>
                              Size
                            </label>
                            <p
                              style={{
                                fontWeight: "600",
                                fontSize: "0.9em",
                                marginTop: "4px",
                                lineHeight: "1rem",
                              }}
                            >
                              {inventory.option2Value}
                            </p>
                          </div>
                          <div style={{ flex: "1" }}>
                            <label style={{ color: "grey", fontWeight: "400" }}>
                              Quantity
                            </label>
                            <p
                              style={{
                                fontWeight: "600",
                                fontSize: "0.9em",
                                marginTop: "4px",
                                lineHeight: "1rem",
                              }}
                            >
                              {inventory.option1Value}
                            </p>
                          </div>
                          <div style={{ flex: "1" }}>
                            <label style={{ color: "grey", fontWeight: "400" }}>
                              Order ID
                            </label>
                            <p
                              style={{
                                fontWeight: "600",
                                fontSize: "0.9em",
                                marginTop: "4px",
                                lineHeight: "1rem",
                              }}
                            >
                              {inventory?.order?.name}
                            </p>
                          </div>
                          <div style={{ flex: "1" }}>
                            <label style={{ color: "grey", fontWeight: "400" }}>
                              Seller name
                            </label>
                            <p
                              style={{
                                fontWeight: "600",
                                fontSize: "0.9em",
                                marginTop: "4px",
                                lineHeight: "1rem",
                              }}
                            >
                              {inventory?.Seller}
                            </p>
                          </div>
                          <div style={{ flex: "1" }}>
                            <label style={{ color: "grey", fontWeight: "400" }}>
                              Price
                            </label>
                            <p
                              style={{
                                fontWeight: "600",
                                fontSize: "0.9em",
                                marginTop: "4px",
                                lineHeight: "1rem",
                              }}
                            >
                              {getSymbolFromCurrency(store?.currency)}
                              {inventory.price
                                ? Number(inventory.price).toFixed(1)
                                : 0}
                            </p>
                          </div>
                          <div style={{ flex: "1" }}>
                            <label style={{ color: "grey", fontWeight: "400" }}>
                              Cost
                            </label>
                            <p
                              style={{
                                fontWeight: "600",
                                fontSize: "0.9em",
                                marginTop: "4px",
                                lineHeight: "1rem",
                              }}
                            >
                              {getSymbolFromCurrency(store?.currency)}
                              {inventory.cost
                                ? Number(inventory.cost).toFixed(1)
                                : 0}
                            </p>
                          </div>
                          <div>
                            <label style={{ color: "grey", fontWeight: "400" }}>
                              Date Sold
                            </label>
                            <p
                              style={{
                                fontWeight: "600",
                                fontSize: "0.9em",
                                marginTop: "4px",
                                lineHeight: "1rem",
                              }}
                            >
                              {formatDate(data?.orderDate)}
                            </p>
                          </div>
                          <div
                            style={{
                              flex: "3",
                              marginLeft: "auto",
                              alignSelf: "center",
                              textAlign: "right",
                            }}
                          >
                            <button
                              style={{
                                background: "transparent",
                                border: "none",
                                color: "#747474",
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => handleInvoiceRecord(inventory)}
                            >
                              View
                            </button>
                          </div>
                        </div>
                      </ProductDetails>
                    </ProductRow>
                  );
                })}
              </StylesCollapsePanel>
            );
          })}
        </Collapse>
      </Wrapper>
    );
  return isMobile ? (
    <Collapse
      bordered={false}
      accordion
      expandIcon={() => null}
      style={{ display: "flex", flexDirection: "column" }}
      //   onChange={handleCollapseChange}
    >
      {dataSource?.map((data, key) => {
        1;
        const { inventories } = data;
        return (
          <CollapsePanel
            style={{
              border: "0.5px solid var(--Line, #DDD)",
            }}
            key={`${data.key}`}
            header={
              <CollapseHeaderOrderMobile
                total={data?.total}
                name={data?.name}
                consigner={data?.buyerName}
                inventories={inventories}
                /* title={
            data?.consigner?.firstName +
            " " +
            data?.consigner?.lastName
          } */
              />
            }
          >
            <InventoryCardMobileView
              dataSource={inventories}
              //   columnSrc={columnSrc}
              //   priceMap={priceMap}
              status={status}
            />
          </CollapsePanel>
        );
      })}
    </Collapse>
  ) : (
    <>
      <InvoiceModal
        isInvoiceModal={isInvoice}
        invoiceRecord={invoiceRecord}
        closeInvoiceModal={setIsInvoiceModal}
      />
    </>
  );
};

export default LogsCardView;
