import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router";
import { Button, Spin } from "antd";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  getConsigner,
  resetConsignerUpdate,
  updateConsigner,
} from "../redux/actions/consignerActions";
import { ConsignerState } from "../redux/reducers/ConsignerReducer";
import ConsignerProfileTabs from "../components/Common/ConsignerProfileTabs";
import ConsignerProfileForm from "../components/Common/ConsignerProfileForm";
import ConsignerNotificationForm from "../components/Common/ConsignerNotificationForm";

import ConsignerBankForm from "../components/Common/ConsignerBankForm";
import { UserState } from "../redux/reducers/UserReducer";
import HorizontalPageCard from "../components/Common/HorizontalPageCard";
import Content from "../constants/Content";
import { AppDispatch } from "../redux/store";
import AuthService from "../services/auth";
import { getConsignerType } from "../redux/actions/consignerTypeActions";
import RedesignStyling from "../constants/RedesignStyling";
import { PromptModal } from "./ConsignerProducts";

/**
 * Inventory Item Screen
 * renders a screen where user can edit a singular inventory item
 * TODO Tests:
 *  -
 * @returns
 */

//styles
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${RedesignStyling.PAGE_PADDING};
  & > div {
    width: 475px;

    button.actionBtn {
      display: flex;
      width: 100%;
      height: 65px;
      padding: 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 10px auto;
    }
  }
  @media (max-width: 768px) {
    padding: ${RedesignStyling.PAGE_PADDING_MOBILE};
  }
`;

const SettingsHeading = styled.h1`
  color: var(--heading-color, #2e2e2e);
  text-align: center;
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  line-height: 62px;
  width: 100%;
  margin: 85px auto 60px;
  @media (max-width: 768px) {
    margin: 25px auto 40px;
  }
`;

interface RouteParams {
  id: string;
}

export interface ConsignerFormValues {
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  phone: string;
  idNumber: string;
  accountName: string;
  bank: string | undefined;
  accountNumber: string;
  branchCode: string;
  bankType: string | undefined;
  bankData: {
    accountName: string;
    accountNumber: string;
    swiftCode: string;
    ibanNumber: string;
    routingNumber: string;
    bank: string;
    bankType: string;
    email: string;
    routingType: string;
  };
  accountType: string;
  payoutData: string;
  payoutType: string;
  storeId: string;
  emailNotifications: any;
  chargeTax?: boolean;
}

const onSave = async (
  dispatch: AppDispatch,
  consignerFormValues: ConsignerFormValues,
  id: string
) => {
  dispatch(await updateConsigner(id, consignerFormValues, false));
};

const NameAndNumber = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  margin: 52px 0 0;
  p {
    color: var(--heading-color, #2e2e2e);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    opacity: 0.6000000238418579;
  }
  b {
    color: var(--heading-color, #2e2e2e);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }
`;

export const ConsignerDetailTab = (props: any) => {
  const { firstName, lastName, idNumber } = props;

  return (
    <NameAndNumber>
      <b>
        {firstName} {lastName}
      </b>
      <p>Reseller ID: {idNumber}</p>
    </NameAndNumber>
  );
};

const Settings = () => {
  const dispatch = useAppDispatch();
  const auth = AuthService();
  //state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [phone, setPhone] = useState("");
  const [accountType, setAccountType] = useState("");
  const [payoutData, setPayoutData] = useState("");
  const [payoutType, setPayoutType] = useState("");
  const [storeId, setStoreId] = useState("");
  const [accountName, setAccountName] = useState("");
  const [bank, setBank] = useState("");
  const [branchCode, setBranchCode] = useState("");
  const [bankType, setBankType] = useState("");
  const [bankData, setBankData] = useState({
    accountName: "",
    accountNumber: "",
    swiftCode: "",
    ibanNumber: "",
    routingNumber: "",
    bank: "",
    bankType: "",
    email: "",
    routingType: "",
  });
  const [accountNumber, setAccountNumber] = useState("");
  const [emailNotifications, setEmailNotifications] = useState();
  const [chargeTax, setChargeTax] = useState(false);
  const [swiftCode, setSwiftCode] = useState("");
  const [ibanNumber, setIbanNumber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [routingType, setRoutingType] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const {
    consigner,
    consignerLoading,
    updateConsignerLoading,
    updatedConsigner,
  }: ConsignerState = useAppSelector((state) => state.ConsignerReducer);

  const { consignerType, consignerTypeLoading } = useAppSelector(
    (state) => state.ConsignerTypeReducer
  );
  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);

  useEffect(() => {
    //get consigner on new db user
    dispatch(getConsigner(dbUser && dbUser.id ? dbUser.id : ""));
  }, [dbUser]);

  useEffect(() => {
    if (consigner) {
      setEmail(consigner.email);
      setFirstName(consigner.firstName);
      setLastName(consigner.lastName);
      setPhone(consigner.phone);
      setIdNumber(consigner.id);
      setAddress(consigner.address);
      setAccountNumber(consigner.accountNumber);
      setAccountName(consigner.accountName);
      setBank(consigner.bank);
      setBankType(consigner.bankType);
      setBranchCode(consigner.branchCode);
      setAccountType(consigner.accountType);
      setPayoutData(consigner.payoutData);
      setPayoutType(consigner.payoutType);
      setStoreId(consigner.storeId);
      setRoutingType(consigner.bankData.routingType);
      setRoutingNumber(consigner.bankData.routingNumber);
      setEmailNotifications(consigner.emailNotifications);
      setChargeTax(consigner.chargeTax);
    }
  }, [consigner]);

  useEffect(() => {
    setBankData({
      accountName,
      bank,
      bankType,
      email,
      accountNumber,
      ibanNumber,
      routingNumber,
      routingType,
      swiftCode,
    });
  }, [
    accountName,
    bank,
    bankType,
    email,
    accountNumber,
    ibanNumber,
    routingNumber,
    routingType,
    swiftCode,
  ]);

  useEffect(() => {
    if (consigner?.accountType)
      //get consignerTypes on load
      dispatch(getConsignerType(consigner?.accountType));
  }, [consigner]);

  useEffect(() => {
    if (updatedConsigner !== null) {
      setOpenSuccessModal(true);
      dispatch(resetConsignerUpdate());
    }
  }, [updatedConsigner]);

  if (consignerLoading || !consigner) {
    return (
      <Container>
        <Spin />
      </Container>
    );
  }

  return (
    <Container>
      <PromptModal
        heading="Your details have been updated successfully."
        open={openSuccessModal}
        setOpen={setOpenSuccessModal}
      />
      <div>
        <SettingsHeading>Settings</SettingsHeading>
        <ConsignerProfileTabs
          tabs={[
            {
              name: "Profile",
              content: (
                <ConsignerProfileForm
                  showPasswordInput={false}
                  storeId={storeId}
                  email={email}
                  password={password}
                  firstName={firstName}
                  lastName={lastName}
                  address={address}
                  phone={phone}
                  idNumber={idNumber}
                  chargeTax={chargeTax}
                  setEmail={setEmail}
                  setPassword={setPassword}
                  setFirstName={setFirstName}
                  setLastName={setLastName}
                  setAddress={setAddress}
                  setPhone={setPhone}
                  setIdNumber={setIdNumber}
                  setStoreId={setStoreId}
                  setChargeTax={setChargeTax}
                />
              ),
            },
            {
              name: "Notifications",
              content: (
                <ConsignerNotificationForm
                  emailNotifications={emailNotifications}
                  setEmailNotifications={setEmailNotifications}
                  firstName={firstName}
                  lastName={lastName}
                  idNumber={idNumber}
                />
              ),
            },
            {
              name: "Bank",
              content: (
                <ConsignerBankForm
                  payoutData={payoutData}
                  payoutType={payoutType}
                  accountName={accountName}
                  accountNumber={accountNumber}
                  swiftCode={swiftCode}
                  ibanNumber={ibanNumber}
                  routingNumber={routingNumber}
                  routingType={routingType}
                  bank={bank}
                  bankType={bankType}
                  branchCode={branchCode}
                  setRoutingType={setRoutingType}
                  setSwiftCode={setSwiftCode}
                  setIbanNumber={setIbanNumber}
                  setRoutingNumber={setRoutingNumber}
                  setPayoutData={setPayoutData}
                  setPayoutType={setPayoutType}
                  setAccountName={setAccountName}
                  setAccountNumber={setAccountNumber}
                  setBank={setBank}
                  setBankType={setBankType}
                  setBranchCode={setBranchCode}
                  readOnly={false}
                  firstName={firstName}
                  lastName={lastName}
                  idNumber={idNumber}
                />
              ),
            },
          ]}
        />

        <Button
          className="actionBtn"
          type="primary"
          onClick={() =>
            onSave(
              dispatch,
              {
                email,
                firstName,
                lastName,
                address,
                idNumber: "",
                phone,
                accountName,
                accountNumber,
                bank,
                bankType,
                bankData,
                branchCode,
                accountType,
                payoutData,
                payoutType,
                storeId,
                emailNotifications,
                chargeTax,
              },
              consigner.id
            )
          }
        >
          {Content.CONSIGNER_ACTION_BUTTON_TEXT}
        </Button>
        <Button
          type="ghost"
          className="actionBtn"
          onClick={() => auth.logout()}
        >
          {Content.CONSIGNER_ACTION_LOGOUT_BUTTON_TEXT}
        </Button>
      </div>
    </Container>
  );
};

export default Settings;
