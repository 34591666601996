import React, { useEffect, useRef, useState } from "react";
import { Input, Button, Select, message, Table, Image, Tabs, Spin } from "antd";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  ConsignerFormOption,
  Inventory,
  InventoryState,
} from "../redux/reducers/InventoryReducer";
import {
  getInventory,
  getInventoryByCodeFromAPI,
  getInventoryFilterOptions,
} from "../redux/actions/inventoryActions";
import _ from "lodash";
import moment from "moment";
import { EditOutlined } from "@ant-design/icons";
import getSymbolFromCurrency from "currency-symbol-map";
import { Link } from "react-router-dom";
import { Consigner } from "../redux/reducers/ConsignerReducer";
import { ColumnTypes } from "../consignerScreens/ConsignerInventory";
import { StoreState } from "../redux/reducers/StoreReducer";
import { ButtonGroup } from "@mui/material";
import { Scan, ScanState } from "../redux/reducers/ScanReducer";
import { createScan, getScan, getScans } from "../redux/actions/scanActions";

const { TabPane } = Tabs;
const { Search } = Input;
const { Option } = Select;

const ScanItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh; // Full height of the viewport
  justify-content: flex-start;
  align-items: space-between;
  padding: 2rem;
  max-height: 100vh;
`;

const Title = styled.h1`
  margin-bottom: 2rem; // Adjust based on your design
`;

const StyledSearch = styled(Search)`
  width: 100%; // Adjust width based on your design
  margin-bottom: 1rem; // Space below search bar
`;

const StyledSelect = styled(Select)`
  width: 100%; // Adjust width based on your design
  margin-bottom: 2rem; // Space below the select
  height: 48px;
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 48px;
    line-height: 48px;
    span {
      height: 48px;
      line-height: 48px;
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  width: 100%; // Adjust width based on your design
`;

const StyledButton = styled(Button)`
  // Add styles for your buttons
  height: 48px;
  width: 120px;
  padding: 8px;
`;

const CButtonGroup = styled(ButtonGroup)`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  width: 100%;
  .ant-btn {
    height: 65px;
    min-width: 166px;
    &.ant-btn-text {
      color: #e4e4e4;
    }
  }
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-content-holder {
    overflow-y: scroll;
    max-height: 100vh;
  }
`;

/* const LocationIndicator = styled.div`
  position: absolute;
  top: 50%; // Adjust based on your design
  left: calc(50% - 150px - 20px); // Adjust based on your design, 150px is half the width of the container, 20px is additional space
  transform: translateY(-50%); // Center vertically
  background-color: pink; // Placeholder for your indicator background
  border-radius: 50%; // Make it round
  width: 40px; // Adjust based on your design
  height: 40px; // Adjust based on your design
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5rem; // Adjust based on your design
`; */
const TabsComponent = ({
  consigners,
  inventories,
  setRunClicked,
  scannedInventories,
  loading,
  scans,
  scansLoading,
}: {
  consigners: ConsignerFormOption[];
  inventories: Inventory[];
  setRunClicked: any;
  scannedInventories: Inventory[];
  loading: boolean;
  scans: Scan[];
  scansLoading: boolean;
}) => {
  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);
  const [activeTabKey, setActiveTabKey] = useState("1");
  const dispatch = useAppDispatch();

  const onTabChange = (key) => {
    setActiveTabKey(key);
  };

  const handleRestart = () => {
    window.location.reload();
  };

  const columns = [
    {
      title: "Product",
      key: "productImage",
      dataIndex: "product.image",
      render: (_: any, record: any) => (
        <div style={{ display: "flex", gap: 8 }}>
          <Image
            src={record.product.image}
            alt=""
            style={{ width: 100, height: "auto", flex: "0 0 100px" }}
          ></Image>
          <span style={{ flex: 1 }}>
            <b>{record.product.title}</b>
            <p>{record.code}</p>
          </span>
        </div>
      ),
    },
    {
      title: "Consigner",
      key: "consignerId",
      dataIndex: "consignerId",
      render: (consignerId: string) => {
        const consigner = consigners.find(
          (con) => con.value.id === consignerId
        );
        return (
          <Link to={`/consigners/${consigner?.value.id}`} target="_blank">
            {consigner?.label}
          </Link>
        );
      },
    },
    {
      title: "Size",
      key: "option1Value",
      dataIndex: "option1Value",
      render: (_: any, record: any) => record?.option1Value ?? null,
    },
    {
      title: "SKU",
      key: "sku",
      dataIndex: "product.sku",
      render: (_: any, record: any) => record.product.sku,
    },
    {
      title: "Price",
      key: "price",
      dataIndex: "price",
      render: (_: any, record: any) =>
        `${getSymbolFromCurrency(store.currency)}${record.price}`,
    },
    {
      title: "",
      key: "status",
      dataIndex: "status",
      render: (_: any, record: any) => (
        <span style={{ display: "flex", flexDirection: "column", gap: 8 }}>
          <Button
            style={{ color: "black" }}
            type="text"
            className="underlined blue"
          >
            {record?.status === "Pending" ? "Drop off pending" : record.status}
          </Button>
          <Button
            target="_blank"
            style={{ color: "black" }}
            href={`/inventories/${record.id}`}
            type="text"
          >
            VIEW
          </Button>
        </span>
      ),
    },
    // {
    //     title: "",
    //     dataIndex: "operation",
    //     render: (_: any, record: Inventory) => (<ButtonGroup>
    //         <Button type="primary" onClick={() => setInventoryList((prev) => prev.filter((inventory) => inventory.id !== record.id))}>Remove</Button>
    //     </ButtonGroup>)
    // },
  ];

  if (loading) return <Spin />;

  return (
    <StyledTabs activeKey={activeTabKey} onChange={onTabChange}>
      <TabPane tab="Missing" key="1">
        <Table
          columns={columns}
          dataSource={inventories.filter(
            (inventory) =>
              !scannedInventories
                .map((scanInv) => scanInv.id)
                ?.includes(inventory.id)
          )}
          rowKey="id"
          footer={() => (
            <CButtonGroup>
              <Button type="text" onClick={() => setRunClicked(0)}>
                Scan Again to double check
              </Button>
              <Button type="primary">PRINT</Button>
            </CButtonGroup>
          )}
        />
      </TabPane>
      <TabPane tab="Over Scanned" key="2">
        <Table
          columns={columns}
          dataSource={scannedInventories.filter((scanInv) =>
            ["Sold", "Paid"].includes(scanInv.status)
          )}
          rowKey="id"
          footer={() => (
            <CButtonGroup>
              <Button type="text" onClick={handleRestart}>
                Scan Again to double check
              </Button>
              <Button type="primary">PRINT</Button>
            </CButtonGroup>
          )}
        />
      </TabPane>
      <TabPane tab="Logs" key="3">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 8,
          }}
        >
          {scans.map((scan, idx) => (
            <div style={{ flex: 1, padding: 8, display: "flex" }} key={idx}>
              <span
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label
                  style={{ flex: "0 0 200px", height: 50, lineHeight: "50px" }}
                >
                  {moment(scan?.createdAt).format("YY/MM/DD")}
                </label>
                <Button
                  style={{ height: 30, flex: "0 0 150px" }}
                  type="primary"
                  onClick={() => dispatch(getScan(scan.id))}
                >
                  VIEW
                </Button>
              </span>
            </div>
          ))}
        </div>
      </TabPane>
      {/* Add more TabPane components as required */}
    </StyledTabs>
  );
};

const InventoryListTable = ({
  inventories,
  consigners,
  setInventoryList,
  runClicked,
  setRunClicked,
  location,
}: {
  inventories: Inventory[];
  consigners: ConsignerFormOption[];
  setInventoryList: any;
  runClicked: number;
  setRunClicked: any;
  location: string;
}) => {
  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);
  const dispatch = useAppDispatch();
  const { scanLoading }: ScanState = useAppSelector(
    (state) => state.ScanReducer
  );

  const [visibleColumns, setVisibleColumns] = useState<ColumnTypes[number][]>();

  const columns = [
    {
      title: "Product",
      key: "productImage",
      dataIndex: "product.image",
      render: (_: any, record: any) => (
        <div style={{ display: "flex", gap: 8 }}>
          <Image
            src={record.product.image}
            alt=""
            style={{ width: 100, height: "auto", flex: "0 0 100px" }}
          ></Image>
          <span style={{ flex: 1 }}>
            <b>{record.product.title}</b>
            <p>{record.code}</p>
          </span>
        </div>
      ),
    },
    {
      title: "Consigner",
      key: "consignerId",
      dataIndex: "consignerId",
      render: (consignerId: string) => {
        const consigner = consigners.find(
          (con) => con.value.id === consignerId
        );
        return (
          <Link to={`/consigners/${consigner?.value.id}`} target="_blank">
            {consigner?.label}
          </Link>
        );
      },
    },
    {
      title: "Size",
      key: "option1Value",
      dataIndex: "option1Value",
      render: (_: any, record: any) => record?.option1Value ?? null,
    },
    {
      title: "SKU",
      key: "sku",
      dataIndex: "product.sku",
      render: (_: any, record: any) => record.product.sku,
    },
    {
      title: "Price",
      key: "price",
      dataIndex: "price",
      render: (_: any, record: any) =>
        `${getSymbolFromCurrency(store.currency)}${record.price}`,
    },
    {
      title: "",
      dataIndex: "operation",
      render: (_: any, record: Inventory) => (
        <ButtonGroup>
          <Button
            type="primary"
            onClick={() =>
              setInventoryList((prev) =>
                prev.filter((inventory) => inventory.id !== record.id)
              )
            }
          >
            Remove
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  useEffect(() => {
    if (runClicked > 0) {
      setVisibleColumns((prev) => {
        let nCols = [...columns];
        nCols.splice(5, 0, {
          title: "",
          key: "status",
          dataIndex: "status",
          render: (_: any, record: any) => (
            <span style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <Button
                style={{ color: "black" }}
                type="text"
                className="underlined blue"
              >
                {record?.status === "Pending"
                  ? "Drop off pending"
                  : record.status}
              </Button>
              <Button
                target="_blank"
                style={{ color: "black" }}
                href={`/inventories/${record.id}`}
                type="text"
              >
                VIEW
              </Button>
            </span>
          ),
        });
        return nCols;
      });
    }
  }, [runClicked]);

  useEffect(() => {
    setVisibleColumns(columns);
  }, [inventories]);

  const handleRunScan = async () => {
    await dispatch(createScan("new", inventories, location));
  };

  return (
    <Table
      columns={visibleColumns}
      dataSource={inventories}
      rowKey="id"
      footer={() => (
        <CButtonGroup>
          <Button type="text">{inventories.length} items</Button>
          <Button
            disabled={inventories.length < 1}
            type="primary"
            onClick={handleRunScan}
          >
            Run
          </Button>
        </CButtonGroup>
      )}
    />
  );
};

const ScanPage: React.FC = () => {
  const [scanResult, setScanResult] = useState("");
  const [isScanning, setIsScanning] = useState(false);
  const [scanSearchInProgress, setScanSearchInProgress] = useState(false);
  const [inventoryList, setInventoryList] = useState<Inventory[]>([]);
  const scannerInputRef = useRef<HTMLInputElement>(null);
  const {
    inventoryFilterOptions,
    inventoryFilterOptionsLoading,
    // inventory,
    inventoryLoading,
  }: InventoryState = useAppSelector((state) => state.InventoryReducer);
  const [selectedLocation, setSelectedLocation] = useState<string>();
  const [runClicked, setRunClicked] = useState(0);
  const [inventory, setInventory] = useState<Inventory>();
  const {
    inventories,
    createdScanLoading,
    createdScan,
    scans,
    scansLoading,
    scannedInventories,
    scanLoading,
  }: ScanState = useAppSelector((state) => state.ScanReducer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getInventoryFilterOptions());
    dispatch(getScans());
    window.addEventListener("focusin", handleStartClick);

    return () => {
      window.removeEventListener("focusin", handleStartClick);
    };
  }, []);

  useEffect(() => {
    if (isScanning) handleStartClick();
  }, [isScanning]);

  useEffect(() => {
    if (scanResult && scanResult !== "") {
      fetchInventory(scanResult);
    }
  }, [scanResult]);

  const fetchInventory = async (code: string) => {
    try {
      setScanSearchInProgress(true);
      const inv = await getInventoryByCodeFromAPI(code);
      setInventory(inv);
      setScanSearchInProgress(false);
    } catch (e: any) {
      console.warn(e);
    }
    // await dispatch(getInventory(id));
  };

  useEffect(() => {
    if (scanSearchInProgress) return;
    scannerInputRef.current!.value = "";
    scannerInputRef.current!.focus();
  }, [scanSearchInProgress]);

  useEffect(() => {
    if (
      runClicked === 0 &&
      inventory &&
      inventoryList.findIndex((inv) => inv.id === inventory.id) === -1
    ) {
      if (inventory.code && selectedLocation === inventory.location) {
        setInventoryList((prev) => [...prev, inventory]);
      } else {
        message.error("No items found for this location.");
      }
    }
  }, [inventory]);

  useEffect(() => {
    if (createdScan && !createdScanLoading) {
      setRunClicked(1);
    }
  }, [createdScanLoading, createdScan]);

  const handleScan = (event) => {
    const scannedCode: string = event.target.value;
    console.log(scannedCode);
    if (scannedCode) {
      const atLast = scannedCode.trim(); // scannedCode.split("/").at(-1);
      if (atLast) setScanResult(atLast);
      // Clear the input after scan
      // event.target.value = "";
      handleStartClick();
    }
    // setIsScanning(false);
  };

  const handleError = (err) => {
    console.error(err);
  };

  const handleStartClick = () => {
    // message.info("Scan ready");
    if (selectedLocation === "" || !selectedLocation) return;
    setIsScanning(true);
    scannerInputRef.current!.value = "";
    scannerInputRef.current!.focus();
  };
  return (
    <ScanItemsContainer>
      <input
        type="text"
        ref={scannerInputRef}
        onChange={_.debounce(handleScan, 300)}
        style={{
          position: "absolute",
          opacity: 0,
          left: "-1000px",
          top: "-1000px",
        }}
      />
      <Title>Scan Items</Title>
      {isScanning || inventoryList.length > 0 ? (
        runClicked > 0 ? (
          <TabsComponent
            consigners={inventoryFilterOptions.consigners}
            inventories={inventories}
            setRunClicked={setRunClicked}
            scannedInventories={
              scannedInventories && scannedInventories?.length > 0
                ? scannedInventories
                : inventoryList
            }
            loading={createdScanLoading || scanLoading || scanSearchInProgress}
            scans={scans}
            scansLoading={scansLoading}
          />
        ) : (
          <InventoryListTable
            inventories={inventoryList}
            consigners={inventoryFilterOptions.consigners}
            setInventoryList={setInventoryList}
            runClicked={runClicked}
            setRunClicked={setRunClicked}
            location={selectedLocation!}
          />
        )
      ) : (
        <div style={{ flex: 1 }}>
          {/* <StyledSearch placeholder="Search" enterButton /> */}
          {!inventoryFilterOptionsLoading && (
            <StyledSelect
              placeholder="Location"
              dropdownMatchSelectWidth={false}
              value={selectedLocation}
              onChange={(value: any) => setSelectedLocation(value)}
            >
              {/* Populate with actual location data */}
              {inventoryFilterOptions.locations.map((location) => (
                <Option key={location.label} value={location.label}>
                  {location.value}
                </Option>
              ))}
            </StyledSelect>
          )}
          {inventoryList.map((inventoryData, idx) => (
            <div key={idx}>
              {inventoryData.code} - {inventoryData.status}
            </div>
          ))}
          <ButtonsContainer>
            <StyledButton>Back</StyledButton>
            <StyledButton
              type="primary"
              disabled={isScanning}
              onClick={handleStartClick}
            >
              START
            </StyledButton>
          </ButtonsContainer>
        </div>
      )}
    </ScanItemsContainer>
  );
};

export default ScanPage;
