import React from "react";
import styled from "styled-components";
import { Input, Button, Checkbox } from "antd";
import Colors from "../../constants/Colors";
import Fonts from "../../constants/Fonts";
import { StoreState } from "../../redux/reducers/StoreReducer";
import { useAppSelector } from "../../redux/hooks";
/**
 * Consigner Profile Form
 */

const Container = styled.div`
  font-family: ${Fonts.CONSIGNER};
  width: 100%;
`;
const AuthInput = styled(Input)`
  font-family: ${Fonts.CONSIGNER};
  width: 100%;
  height: 50px;
  padding: 16px;
  margin: 10px auto;
  border: none;
  background: var(--base-white, #fff);
  font-size: 14px;
  border-radius: 10px;
  ::placeholder {
    color: rgba(122, 122, 123, 1);
    opacity: 1; /* Firefox */
  }
`;
const PassInput = styled(Input.Password)`
  font-family: ${Fonts.CONSIGNER};
  width: 100%;
  height: 50px;
  padding: 16px;
  margin: 10px auto;
  border: none;
  border-radius: 10px;
  background: var(--base-white, #fff);
  &.ant-input-affix-wrapper > .ant-input {
    font-size: 14px;
    ::placeholder {
      color: rgba(122, 122, 123, 1);
      opacity: 1; /* Firefox */
    }
  }
`;

const UserHelpActions = styled.div`
  font-family: ${Fonts.CONSIGNER};
  float: right;
  margin-bottom: 10px;
  padding: 10px 0;
  label,
  a {
    color: var(--heading-color, #2e2e2e);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }
`;

interface Props {
  onLogin(email: string, password: string): void;
  email: string;
  password: string;
  rememberSession: boolean;
  onEmailChange(newEmail: string): void;
  onPasswordChange(newPassword: string): void;
  onRememberSessionChange(newValue: boolean): void;
  onFocusOut(): void;
  loginLoading: boolean;
  title?: string;
  buttonText?: string;
}

const ResellerLoginForm = (props: Props): JSX.Element => {
  const {
    email,
    password,
    rememberSession,
    onEmailChange,
    onPasswordChange,
    onRememberSessionChange,
    onLogin,
    onFocusOut,
    loginLoading,
    buttonText,
    title,
  } = props;

  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);

  return (
    <Container>
      <AuthInput
        data-testid="email"
        value={email}
        type="email"
        onChange={(e) => onEmailChange(e.target.value)}
        placeholder="Exampla@mail.com"
        onBlur={onFocusOut}
      />
      <PassInput
        className="red"
        data-testid="password"
        value={password}
        onChange={(e) => onPasswordChange(e.target.value)}
        placeholder="Password"
      />
      <UserHelpActions>
        <a
          href="/forgot"
          data-testid="forgot"
          style={{ color: Colors.BLACK, textDecoration: "underline" }}
        >
          Forgot Password?
        </a>
      </UserHelpActions>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Button
          // disabled={!store || !store.id}
          style={{
            display: "flex",
            width: "80%",
            height: 50,
            // padding: 16,
            justifyContent: "center",
            color: "white",
            alignItems: "center",
            background: "black",
            marginTop: 30,
            marginBottom: 10,
            textAlign: "center",
            fontSize: 16,
            fontWeight: 500,
            borderRadius: 50,
            // lineHeight: 28,
          }}
          data-testid="loginBtn"
          onClick={() => onLogin(email, password)}
          type="primary"
          loading={loginLoading}
        >
          {buttonText ? buttonText : "Login"}
        </Button>
      </div>

      <div
        style={{
          textAlign: "center",
          padding: "10px",
          fontSize: 14,
          fontWeight: 300,
          color: "rgba(51, 51, 51, 1)",
        }}
      >
        Don&apos;t have an account?{" "}
        <a
          href="/storeSignup"
          data-testid="storeSignup"
          style={{
            color: "rgba(0, 0, 0, 1)",
            fontWeight: 600,
            textDecoration: "underline",
          }}
        >
          Create free account
        </a>
      </div>
    </Container>
  );
};

export default ResellerLoginForm;
