import React, { useState } from "react";
import { Form, Select, DatePicker, Button } from "antd";
import { useForm } from "antd/lib/form/Form";
import { InventoryFilterOptions } from "../../redux/reducers/InventoryReducer";
import moment from "moment";
import Input from "antd/lib/input/Input";
import styled from "styled-components";

/**
 * Orders Filter
 * Used to filer a list of orders
 * has onFilter prop which triggers on filter changes
 * form of filters
 * TODO Test
 *  - render correctly
 *  - onFilter triggers on filter changes
 */
const CustomForm = styled(Form)`
  display: flex;

  .ant-btn {
    border-radius: 8px;
    &.ant-btn-primary {
      background-color: #e1ecff;
      color: #2e2e2e;
      border: none;
    }
  }
  @media (max-width: 768px) {
    justify-content: space-around;
    .ant-form-item {
      &.orderDateRange {
        display: none;
      }
      margin: 0;
      width: 100%;
      span {
        display: flex;
        justify-content: space-evenly;
      }
    }
  }
`;

const { RangePicker } = DatePicker;

export interface FormValues {
  consigner: string;
  orderDateRange: any;
  status?: any;
  isSeller?: boolean;
}

interface Props {
  formOptions: InventoryFilterOptions;
  onFilter: (values: FormValues) => void;
  showConsigner?: boolean;
}

const OrderFilter = (props: Props) => {
  const [form] = useForm();
  const { formOptions, onFilter, showConsigner } = props;
  const dateToday = moment().add(1, "day").format("YYYY-MM-DD");
  const [selectedRange, setSelectedRange] = useState("ALL");

  let consignersWithoutStore;
  if (formOptions) {
    consignersWithoutStore = formOptions.consigners.filter(
      (consigner: any) => !consigner.value.isStoreAccount
    );
  }

  return (
    <CustomForm
      layout="inline"
      name="orderFilter"
      form={form}
      data-testid="orderFilter"
      onValuesChange={(values) => onFilter(values)}
      // style={{ marginTop: 10 }}
    >
      {showConsigner && (
        <Form.Item name="consigner">
          <Select
            placeholder="Select a Consigner"
            style={{ width: 200 }}
            showSearch
            filterOption={(input, option) =>
              String(option?.children)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            allowClear
            data-testid="consigners"
          >
            {consignersWithoutStore?.map((consigner) => (
              <Select.Option
                key={consigner.value.id}
                value={JSON.stringify(consigner.value.id)}
              >
                {consigner.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item className="orderDateRange" name="orderDateRange">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item>
        <span>
          <Button
            type={selectedRange === "24" ? "primary" : "ghost"}
            onClick={() => {
              form.setFieldsValue({
                ...form.getFieldsValue(),
                orderDateRange: [
                  moment().subtract(24, "hours").format("YYYY-MM-DD"),
                  dateToday,
                ],
              });
              onFilter(form.getFieldsValue());
              setSelectedRange("24");
            }}
          >
            24
          </Button>
          <Button
            type={selectedRange === "1W" ? "primary" : "ghost"}
            onClick={() => {
              form.setFieldsValue({
                ...form.getFieldsValue(),
                orderDateRange: [
                  moment().subtract(1, "week").format("YYYY-MM-DD"),
                  dateToday,
                ],
              });
              onFilter(form.getFieldsValue());
              setSelectedRange("1W");
            }}
          >
            1W
          </Button>
          <Button
            type={selectedRange === "1M" ? "primary" : "ghost"}
            onClick={() => {
              form.setFieldsValue({
                ...form.getFieldsValue(),
                orderDateRange: [
                  moment().subtract(1, "month").format("YYYY-MM-DD"),
                  dateToday,
                ],
              });
              onFilter(form.getFieldsValue());
              setSelectedRange("1M");
            }}
          >
            1M
          </Button>
          <Button
            type={selectedRange === "3M" ? "primary" : "ghost"}
            onClick={() => {
              form.setFieldsValue({
                ...form.getFieldsValue(),
                orderDateRange: [
                  moment().subtract(3, "months").format("YYYY-MM-DD"),
                  dateToday,
                ],
              });
              onFilter(form.getFieldsValue());
              setSelectedRange("3M");
            }}
          >
            3M
          </Button>
          <Button
            type={selectedRange === "1Y" ? "primary" : "ghost"}
            onClick={() => {
              form.setFieldsValue({
                ...form.getFieldsValue(),
                orderDateRange: [
                  moment().subtract(1, "year").format("YYYY-MM-DD"),
                  dateToday,
                ],
              });
              onFilter(form.getFieldsValue());
              setSelectedRange("1Y");
            }}
          >
            1Y
          </Button>
          <Button
            type={selectedRange === "ALL" ? "primary" : "ghost"}
            onClick={() => {
              form.setFieldsValue({
                ...form.getFieldsValue(),
                orderDateRange: undefined,
              });
              onFilter(form.getFieldsValue());
              setSelectedRange("ALL");
            }}
          >
            ALL
          </Button>
        </span>
      </Form.Item>
    </CustomForm>
  );
};

export default OrderFilter;
