import React, { useEffect, useState } from "react";
import { Modal, Button, Spin, Form, Input, Select } from "antd";
import styled from "styled-components";
import BackIcon from "../../assets/images/backIcon.svg";
import { useAppSelector } from "../../redux/hooks";
import getSymbolFromCurrency from "currency-symbol-map";
import ResendLink from "./ResendLink";
const { Option } = Select;

const CustomModal = styled(Modal)`
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  &.ant-modal {
    max-width: 100vw;
    margin: 0;
    .ant-modal-content {
      width: 812px;
      height: 716px;
      border-radius: 8px;
      overflow: hidden;
    }
  }
  @media (max-width: 768px) {
    top: 0;
    left: 0;
    transform: none;
    .ant-modal-content {
      padding: 12px;
      width: 100%;
      min-height: 100vh;
      max-height: 100%;
      overflow-y: auto;
      .ant-modal-header {
        padding: 0 0 13px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        text-transform: capitalize;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .ant-modal-body {
        padding: 0;
      }
      .ant-modal-footer {
        padding: 0;
        display: flex;
        margin: 24px auto;
        button {
          flex: 1;
        }
      }
    }
  }
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid lightgrey;
`;

const HeaderIcon = styled.button`
  border: none;
  cursor: pointer;
  display: flex;
  margin-right: 8px;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-image: url(${BackIcon});
  background-repeat: no-repeat;
  background-size: cover;
`;

const HeaderTitle = styled.h2`
  font-size: 16px;
  font-weight: 600;
  color: #747474;
  margin: 0;
`;

const HeaderOrderID = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #747474;
  margin-left: 8px;
`;

const ModalBody = styled.div`
  padding: 16px;
`;

const ContentRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid lightgrey;
`;

const OrderIdText = styled.span`
  font-size: 20px;
  font-weight: 700;
`;

const ViewButton = styled(Button)`
  background-color: rgba(0, 255, 0, 0.1);
  color: #1d8c47;
  border-radius: 20px;
  border-color: #1d8c47;
  text-decoration: underline;
  &:hover {
    background-color: rgba(0, 0, 0, 0.2); /* Darker background color on hover */
  }
`;

const DetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const InnerDetails = styled.div`
  flex: 1;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-top: 15px;
  border-bottom: 1px solid lightgrey;
`;

const Tab = styled.button<{ active: boolean }>`
  background: none;
  border: none;
  padding: 16px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: ${(props) => (props.active ? "black" : "#747474")};
  border-bottom: ${(props) => (props.active ? "2px solid black" : "none")};

  &:hover {
    color: black;
  }
`;

const ScrollableProductList = styled.div`
  padding-top: 10px;
  max-height: 400px;
  overflow-y: auto;
`;

const ProductRow = styled.div`
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const ProductImage = styled.img`
  height: 61px;
  width: 71px;
  margin-right: 10px;
  margin-left: 8px;
`;

const ProductDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ProductNameRow = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  margin-bottom: 8px;
`;

const ProductName = styled.p`
  font-weight: bold;
  font-size: 0.9em;
  margin: 0;
  line-height: 1rem;
`;

const ProductOtherDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  flex-wrap: nowrap;
`;

const DetailItem = styled.div`
  flex: 1;
  min-width: 100px;
  max-width: 120px;
`;

const Label = styled.label`
  color: grey;
  font-weight: 500;
`;

const Value = styled.p`
  font-weight: bold;
  font-size: 0.9em;
  margin-top: 4px;
  line-height: 1rem;
`;

const SkuDivMargin = styled.div`
  margin-bottom: 1.4rem;
`;

const OrderDetails = ({ orderDetails }: { orderDetails: any }) => {
  const { products, productsLoading } = useAppSelector(
    (state) => state.ProductReducer
  );
  const { store } = useAppSelector((state) => state.StoreReducer);
  return (
    <>
      {productsLoading ? (
        <Spin />
      ) : (
        !productsLoading && (
          <ScrollableProductList>
            {orderDetails?.lineItems?.map((product, key) => {
              let rangeUsed = false;
              const { storeRequestPriceRange } = product;
              if (
                storeRequestPriceRange &&
                storeRequestPriceRange[1] &&
                storeRequestPriceRange[0]
              ) {
                rangeUsed = true;
              }
              return (
                <ProductRow key={key}>
                  <ProductImage src={product?.product?.image} alt="" />
                  <ProductDetails>
                    <ProductNameRow>
                      <ProductName>{product?.title}</ProductName>
                    </ProductNameRow>
                    <ProductOtherDetails>
                      <DetailItem>
                        <Label>SKU</Label>
                        <SkuDivMargin>
                          <Value>
                            {product?.product?.sku?.slice(0, 12)}
                            {product?.product?.sku?.length > 12 ? "..." : null}
                          </Value>
                        </SkuDivMargin>
                      </DetailItem>
                      <DetailItem>
                        <Label>Condition</Label>
                        <Value>{product?.option2Value}</Value>
                      </DetailItem>
                      <DetailItem>
                        <Label>Size</Label>
                        <Value>{product?.option1Value}</Value>
                      </DetailItem>
                      f
                      <DetailItem>
                        <Label>Days Active</Label>
                        <Value>{"days"}</Value>
                      </DetailItem>
                      <DetailItem>
                        <Label>Price</Label>
                        <Value>
                          {getSymbolFromCurrency(store.currency)}
                          {rangeUsed
                            ? `${storeRequestPriceRange[0]} - ${storeRequestPriceRange[1]}`
                            : product.price}
                          {product?.requestedPrice && (
                            <b style={{ color: "green", display: "block" }}>
                              {getSymbolFromCurrency(store.currency)}
                              {product.requestedPrice}
                            </b>
                          )}
                        </Value>
                      </DetailItem>
                      <DetailItem>
                        <Label>Cost</Label>
                        <Value>
                          {getSymbolFromCurrency(store.currency)}
                          {product.cost ? Number(product.cost).toFixed(1) : "0"}
                        </Value>
                      </DetailItem>
                      <DetailItem>
                        <Label>Projected Profit</Label>
                        <Value>
                          {getSymbolFromCurrency(store.currency)}
                          {product.cost
                            ? Number(+product.price - +product.cost).toFixed(1)
                            : "0"}
                        </Value>
                      </DetailItem>
                    </ProductOtherDetails>
                  </ProductDetails>
                </ProductRow>
              );
            })}
          </ScrollableProductList>
        )
      )}
    </>
  );
};

const PaymentDetails = ({ orderDetails }: { orderDetails: any }) => {
  return (
    <ProductRow>
      <ProductDetails
        style={{
          padding: "20px",
          display: "flex",
          flexDirection: "row",
          alignItems: "start",
          justifyContent: "flex-start",
          gap: "90px",
        }}
      >
        <DetailItem>
          <Label>Payment Method</Label>
          <Value>{orderDetails?.paymentDetails}</Value>
        </DetailItem>
        {/* <DetailItem>
      <Label>Payment Terms</Label>
      <Value>{"Condition"}</Value>
    </DetailItem> */}
      </ProductDetails>
    </ProductRow>
  );
};

interface ViewOrderModaleProps {
  isModaleVisible: boolean;
  setIsModaleVisible: () => void;
  orderDetails: any;
}
export default function ViewOrderModale({
  isModaleVisible,
  setIsModaleVisible,
  orderDetails,
}: ViewOrderModaleProps) {
  const [activeTab, setActiveTab] = useState(1);
  const [inventories, setInventories] = useState<any[]>([]);
  const [openResendLink, setOpenResendLink] = useState<boolean>(false);

  const handleCancle = () => {
    setIsModaleVisible();
  };

  const handlePaymentModale = () => {
    setOpenResendLink(true);
  };
  const totalCost = orderDetails?.lineItems?.reduce((acc, order) => {
    const price = parseFloat(order?.cost);
    return acc + (isNaN(price) ? 0 : price);
  }, 0);
  const totalPrice = orderDetails?.lineItems?.reduce((acc, order) => {
    const price = parseFloat(order?.price);
    return acc + (isNaN(price) ? 0 : price);
  }, 0);
  return (
    <>
      <>
        <ResendLink isModaleVisible={openResendLink} />
      </>
      <CustomModal
        visible={isModaleVisible}
        footer={null}
        onCancel={() => handleCancle()}
      >
        <ModalHeader>
          <HeaderIcon onClick={() => handleCancle()} />
          <HeaderTitle>
            View Order <HeaderOrderID>{orderDetails?.name}</HeaderOrderID>
          </HeaderTitle>
        </ModalHeader>
        <ModalBody>
          <ContentRow>
            <OrderIdText>Order {orderDetails?.name}</OrderIdText>
            <ViewButton>Order Confirmed</ViewButton>
          </ContentRow>

          <DetailsRow>
            <InnerDetails>
              <p style={{ color: "#989898" }}>Consignor</p>
              <p>{orderDetails?.sellerName}</p>
              <p style={{ color: "#989898" }}>Units</p>
              <p>{orderDetails?.lineItems.length}</p>
            </InnerDetails>
            <InnerDetails>
              <p style={{ color: "#989898" }}>Shipping Method</p>
              <p>{orderDetails?.shippingMethod}</p>
              <p style={{ color: "#989898" }}>Total Costs</p>
              <p>{totalCost}</p>
            </InnerDetails>
            <InnerDetails>
              <p style={{ color: "#989898" }}>Tracking Number</p>
              <p>{orderDetails?.name}</p>
              <p style={{ color: "#989898" }}>Total Revenue</p>
              <p>{totalPrice - totalCost}</p>
            </InnerDetails>
            <InnerDetails>
              <p style={{ color: "#989898" }}>Shipping Cost</p>
              <p>{orderDetails?.shippingCost}</p>
            </InnerDetails>
          </DetailsRow>

          <div style={{ display: "flex", alignItems: "start", gap: "0.5rem" }}>
            <Button
              key="invoice"
              style={{
                padding: 8,
                width: 96,
                height: 40,
                color: "#FFFFFF",
                background: "black",
              }}
            >
              Invoice
            </Button>
            <Button
              key="resend"
              style={{
                padding: 8,
                width: 96,
                height: 40,
                background: "#F4F4F4",
              }}
              onClick={() => handlePaymentModale()}
            >
              Resend Link
            </Button>
          </div>

          <TabContainer>
            <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>
              Order details
            </Tab>
            <Tab active={activeTab === 2} onClick={() => setActiveTab(2)}>
              Payment details
            </Tab>
          </TabContainer>

          {activeTab === 1 ? (
            <OrderDetails orderDetails={orderDetails} />
          ) : (
            <PaymentDetails orderDetails={orderDetails} />
          )}
        </ModalBody>
      </CustomModal>
    </>
  );
}
