//
import React, { useEffect, useState } from "react";
import { CustomModal, SearchDiv } from "./inventoryStyle";
import {
  AutoComplete,
  Button,
  Checkbox,
  Col,
  Collapse,
  Drawer,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  PlusOutlined,
  CheckOutlined,
  DownOutlined,
  DeleteOutlined,
  UpOutlined,
} from "@ant-design/icons";
import ProductList from "./ProductList";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { InventoryState } from "../../redux/reducers/InventoryReducer";
import { SearchBox } from "../../components/Search/Search";
import {
  addSelectedProduct,
  clearSelectedProduct,
  getProducts,
  removeSelectedProduct,
  updateProduct,
  updateSelectedProduct,
} from "../../redux/actions/productActions";
import {
  createDraftOrderFromAPI,
  getOrders,
  getOrder,
  createOrder,
} from "../../redux/actions/orderActions";
import ProductModal from "./ProductModal";
import AddCustomProduct from "./AddCustomProduct";
import styled from "styled-components";
import getSymbolFromCurrency from "currency-symbol-map";
const { Option } = Select;
import EditOrderModale, { OrderForm } from "./EditOrderModale";
import {
  InventoryCardMobileView,
  ProductRowMobile,
  ProductRowMobileTop,
} from "../../components/Common/InventoryCardView";
import { Footer } from "antd/lib/layout/layout";
import { useForm } from "antd/lib/form/Form";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import {
  getOpenPaginatedInventoriesFromAPI,
  getPaginatedInventories,
} from "../../redux/actions/inventoryActions";
import { createInvoice } from "../../redux/actions/invoiceActions";
import { useHistory, useParams } from "react-router-dom";

const Container = styled.div`
  // padding: 0 16px;
  display: flex;
  flex-direction: column;
  #inventoryFilter {
    margin: 0 0 0 15px;
    flex-wrap: nowrap;
    &.ant-form-inline .ant-form-item:last-of-type {
      margin-right: 0;
    }
  }
  .ant-form-item-label label {
    color: rgba(116, 116, 116, 1);
    font-weight: 500;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    height: 40px;
    color: #292d32;
    border-radius: 5px;
    border: 1px solid var(--Line, #ddd);
    background: var(--White, #fff);
    align-items: center;
  }
  table {
    thead {
      .ant-checkbox-inner {
        background-color: transparent; /* Customize the check color */
      }
    }
  }
  @media (max-width: 768px) {
    background-color: #fff;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  width: 100%;
  @media (max-width: 768px) {
    border-radius: 4px;
    order: 2;
  }
`;

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-header {
    padding: 9px 16px;
  }
  .ant-drawer-body {
    padding: 0 24px;
  }
`;

const ProductRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;
const ProductDetails = styled.div`
  flex: 1;
  //   margin: 0 10px;
  padding: 5px 12px;
  //   margin-left: 70px;
  //   border-bottom: 0.5px solid var(--Line, #ddd);
  gap: 18px;
  display: flex;
  flex-direction: row;
  color: rgba(51, 51, 51, 1);
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  .ant-checkbox {
    height: 16px;
  }
  &.pricing {
    & > div {
      &:not(.Requested) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
  &.center {
    align-items: center;
  }
  b {
    align-self: stretch;
    flex: 1;
    a {
      color: inherit;
      white-space: nowrap;
    }
  }
  & > div {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 20px;
    // justify-content: space-between;
    flex-wrap: nowrap;
    span {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      p {
        margin: 0 0 3px;
        color: #292d32;
      }
      label {
        color: var(--Text---Grey, #989898);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
    }
  }
  @media (max-width: 768px) {
    border-right: none;
    font-size: 10px;
    b {
      font-size: inherit;
    }
    & > div {
      font-size: inherit;
      gap: 16px;
      span {
        justify-content: flex-start;
        font-size: inherit;
        label {
          font-size: inherit;
        }
      }
    }
    &.center {
      align-items: flex-start;
    }
  }
`;

const ProductDetailsContainer = styled.div`
  border: 0.5px solid var(--Line, #ddd);
  margin: 0 20px 20px;
  border-radius: 5px;
  & > div:not(:last-child) {
    border-bottom: 0.5px solid var(--Line, #ddd);
  }
`;
const SkuDivMargin = styled.div`
  margin-bottom: 1.4rem;
`;

const QuantityInput = styled.div`
  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  height: 35px;
  align-items: center;
  button {
    border: none;
    border-radius: 5px;
    padding: 4px 10px;
  }
  .ant-input-number {
    border: none;
    width: 38px;
    .ant-input-number-input {
      text-align: center;
    }
  }
`;

const StyledCollapse = styled(Collapse)`
  background-color: white;
  border: 1px solid rgba(221, 221, 221, 1);
  border-radius: 8px;
  .ant-collapse-header {
    padding: 10px !important;
  }
  .anticon .anticon-down {
    width: 10px;
  }
  .ant-collapse-content-active {
    border-top: 0.5px solid var(--Line, #ddd) !important;
    // padding-left: 40px;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
  .ant-collapse-expand-icon {
    align-self: center;
  }
`;
interface Product {
  id: string;
  image: string;
  title: string;
  sku: string;
  storeRequestPriceRange?: [number, number];
  price: number;
  requestedPrice?: number;
  cost?: number;
  option1Value?: string;
}
interface AddInventoryModalProp {
  isModalVisible: boolean;
  setIsModalVisible: () => void;
}

const CollapseHeader = (record: any) => {
  const {
    activeKey,
    image,
    title,
    keyItem,
    quantity,
    sku,
    inventories,
    currency,
  } = record;
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div
          style={{
            padding: "10px",
            background: "white",
            marginRight: "10px",
          }}
        >
          {image ? (
            <img src={image} width={52.5} style={{ objectFit: "contain" }} />
          ) : (
            <div
              style={{ width: 52.5, height: "auto", display: "inline-block" }}
            />
          )}
        </div>
        <div style={{ flex: "0 0 30%" }}>
          <label style={{ color: "rgba(152, 152, 152, 1)", fontWeight: "500" }}>
            Name
          </label>
          <p
            style={{
              fontWeight: "600",
              fontSize: "13px",
              marginTop: "4px",
              lineHeight: "1rem",
            }}
          >
            {title}
          </p>
        </div>
        <div style={{ flex: "1" }}>
          <label style={{ color: "rgba(152, 152, 152, 1)", fontWeight: "500" }}>
            SKU
          </label>
          <p
            style={{
              fontWeight: "600",
              fontSize: "13px",
              marginTop: "4px",
              lineHeight: "1rem",
            }}
          >
            {sku}
          </p>
        </div>
        {/* <div style={{ flex: "1" }}>
          <label style={{ color: "rgba(152, 152, 152, 1)", fontWeight: "500" }}>
            Size
          </label>
          <p
            style={{
              fontWeight: "600",
              fontSize: "13px",
              marginTop: "4px",
              lineHeight: "1rem",
            }}
          >
            {quantity}
          </p>
        </div> */}
        <div style={{ flex: "1" }}>
          <label style={{ color: "rgba(152, 152, 152, 1)", fontWeight: "500" }}>
            Total Units
          </label>
          <p
            style={{
              fontWeight: "600",
              fontSize: "13px",
              marginTop: "4px",
              lineHeight: "1rem",
            }}
          >
            {quantity}
          </p>
        </div>
        <div style={{ flex: "1" }}>
          <label style={{ color: "rgba(152, 152, 152, 1)", fontWeight: "500" }}>
            Average Price
          </label>
          <p
            style={{
              fontWeight: "600",
              fontSize: "13px",
              marginTop: "4px",
              lineHeight: "1rem",
            }}
          >
            {currency}
            {(
              inventories.reduce(
                (acc: number, item: any) => acc + +item.price,
                0
              ) / inventories.length
            ).toFixed(1)}
          </p>
        </div>
      </div>
    </>
  );
};

const CollapseHeaderMobile = (record: any) => {
  const {
    activeKey,
    image,
    title,
    keyItem,
    quantity,
    sku,
    inventories,
    currency,
  } = record;
  return (
    <>
      <div style={{ display: "flex", gap: "20px" }}>
        <div
          style={{
            padding: "10px",
            background: "white",
            marginRight: "10px",
            alignContent: "center",
          }}
        >
          {image ? (
            <img src={image} width={70} style={{ objectFit: "contain" }} />
          ) : (
            <div
              style={{ width: 70, height: "auto", display: "inline-block" }}
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            //   justifyContent: "space-between",
            gap: "15px",
            width: "100%",
          }}
        >
          <div>
            <label
              style={{ color: "rgba(152, 152, 152, 1)", fontWeight: "500" }}
            >
              Name
            </label>
            <p
              style={{
                fontWeight: "600",
                fontSize: "13px",
                marginTop: "4px",
                lineHeight: "1rem",
              }}
            >
              {title}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              //   justifyContent: "space-between",
              gap: "30px",
              width: "100%",
            }}
          >
            <div>
              <label
                style={{ color: "rgba(152, 152, 152, 1)", fontWeight: "500" }}
              >
                SKU
              </label>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "13px",
                  marginTop: "4px",
                  lineHeight: "1rem",
                }}
              >
                {sku}
              </p>
            </div>
            {/* <div style={{ flex: "1" }}>
            <label style={{ color: "rgba(152, 152, 152, 1)", fontWeight: "500" }}>
              Size
            </label>
            <p
              style={{
                fontWeight: "600",
                fontSize: "13px",
                marginTop: "4px",
                lineHeight: "1rem",
              }}
            >
              {quantity}
            </p>
          </div> */}
            <div>
              <label
                style={{ color: "rgba(152, 152, 152, 1)", fontWeight: "500" }}
              >
                Total Units
              </label>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "13px",
                  marginTop: "4px",
                  lineHeight: "1rem",
                }}
              >
                {quantity}
              </p>
            </div>
            <div>
              <label
                style={{ color: "rgba(152, 152, 152, 1)", fontWeight: "500" }}
              >
                Average Price
              </label>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "13px",
                  marginTop: "4px",
                  lineHeight: "1rem",
                }}
              >
                {currency}
                {(
                  inventories.reduce(
                    (acc: number, item: any) => acc + +item.price,
                    0
                  ) / inventories.length
                ).toFixed(1)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function ShareInventoryModale() {
  //   const { store } = useAppSelector((state) => state.StoreReducer);
  const { tenantId, id }: any = useParams();
  const { products, updatedProduct, productsLoading, selectedProducts } =
    useAppSelector((state) => state.ProductReducer);
  const {
    inventoriesToReview,
    // paginatedInventories,
    inventoriesLoading,
  }: InventoryState = useAppSelector((state) => state.InventoryReducer);
  const [inventoriesToAdd, setInventoriesToAdd] = useState<any[]>(
    inventoriesToReview ?? []
  );
  const [search, setSearch] = useState("");
  const [editOrder, setEditOrder] = useState<boolean>(false);
  const [groupedData, setGroupedData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tableNestedView, setTableNestedView] = useState(false);
  const [paginatedInventories, setPaginatedInventories] = useState<any>({});
  const [store, setStore] = useState<any>({});
  const dispatch = useAppDispatch();
  const { isMobile } = useAppSelector((state) => state.AppReducer);
  const [selectedInv, setSelectedInv] = useState<any[]>([]);
  const [phone, setPhone] = useState<any>("");
  const [filters, setFilters] = useState<any>({
    status: "Active",
    printed: undefined,
    option1Value: undefined,
    option2Value: undefined,
    option3Value: undefined,
    category: undefined,
    sortOption: "newestUpdated",
    consigner: undefined,
  });
  const history = useHistory();
  //   const handleCancel = async () => {
  //     await dispatch(clearSelectedProduct());
  //     setSelectedInv([]);
  //     setTimeout(() => {
  //       setEditOrder(false);
  //       setIsModalVisible();
  //     }, 1000);
  //   };

  const reloadInventories = async () => {
    const response = await getOpenPaginatedInventoriesFromAPI(id, tenantId);
    setPaginatedInventories(response);
    setStore(response.store);
    setPhone(response?.phone);
  };

  useEffect(() => {
    reloadInventories();
  }, []);

  useEffect(() => {
    if (paginatedInventories?.rows?.length) {
      const groupedData = paginatedInventories?.rows?.reduce((groups, item) => {
        const groupId = `${item.product?.sku}`;

        // Check if the group already exists
        if (!groups[groupId]) {
          groups[groupId] = {
            productId: item.product?.sku,
            key: groupId,
            productTitle: item?.product?.title,
            productImage: item.product?.image,
            productSku: item.product?.sku,
            consigner: item?.consigner,
            quantity: 0,
            inventories: [], // Create an empty inventories array
          };
        }

        // Find the inventory in the inventories array by option1Value
        let existingInventory = groups[groupId].inventories.find(
          (inventoryItem) => inventoryItem.option1Value === item.option1Value
        );

        if (existingInventory) {
          // If an inventory with the same option1Value exists, push the new item into the `itemArray`
          existingInventory.itemArray.push(item);
        } else {
          // If it doesn't exist, create a new inventory item with `itemArray`
          groups[groupId].inventories.push({
            ...item,
            itemArray: [item], // Start the itemArray with the current item
          });
        }

        // Increment the total quantity for the group
        groups[groupId].quantity += item.quantity; // Add the item's quantity
        return groups;
      }, {});

      console.log(groupedData, "=========groupedData========");
      setGroupedData(Object.values(groupedData));
    }
  }, [paginatedInventories]);

  return (
    <>
      <StyledDrawer
        title="Share"
        placement="right"
        closable={false}
        // onClose={() => setIsModalVisible()}
        visible={true}
        width="100%"
        extra={
          <Space>
            <Button
              type="primary"
              onClick={() => history.push("/")}
              style={{
                padding: "8px 10px",
                height: 42,
                borderRadius: "8px",
                background: "black",
                // right: "2%",
                color: "white",
                // position: "absolute",
                fontWeight: "500",
              }}
            >
              Signup to Vault
            </Button>
          </Space>
        }
      >
        <Container>
          <Wrapper>
            {inventoriesLoading ? (
              <>
                <Spin />
              </>
            ) : (
              <>
                <>
                  <h3 style={{ fontSize: "17px" }}>Shared Products</h3>
                  <StyledCollapse
                    bordered={false}
                    accordion
                    expandIconPosition="end"
                    expandIcon={({ isActive }) =>
                      isActive ? (
                        <div
                          style={{
                            padding: "2px 5px",
                            borderRadius: "3px",
                            border: "1px solid var(--Line, #ddd)",
                            position: "absolute",
                            top: isMobile ? "26px" : "",
                          }}
                        >
                          <UpOutlined style={{ width: "10px" }} />
                        </div>
                      ) : (
                        <div
                          style={{
                            padding: "2px 5px",
                            borderRadius: "3px",
                            border: "1px solid var(--Line, #ddd)",
                            position: "absolute",
                            top: isMobile ? "26px" : "",
                          }}
                        >
                          <DownOutlined style={{ width: "10px" }} />
                        </div>
                      )
                    }
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    // onChange={handleCollapseChange}
                  >
                    {groupedData?.map((data, key) => {
                      const { inventories } = data;
                      return (
                        <CollapsePanel
                          key={`${data.key}`}
                          header={
                            isMobile ? (
                              <CollapseHeaderMobile
                                keyItem={`${data?.productId}`}
                                image={data?.productImage}
                                title={data?.productTitle}
                                sku={data?.productSku}
                                inventories={inventories}
                                quantity={inventories?.length ?? 1}
                                currency={getSymbolFromCurrency(store.currency)}
                              />
                            ) : (
                              <CollapseHeader
                                keyItem={`${data?.productId}`}
                                image={data?.productImage}
                                title={data?.productTitle}
                                sku={data?.productSku}
                                inventories={inventories}
                                quantity={inventories?.length ?? 1}
                                currency={getSymbolFromCurrency(store.currency)}
                              />
                            )
                          }
                        >
                          <div
                            style={{
                              display: "flex",
                              margin: "20px 20px 0 20px",
                              color: "rgba(102, 102, 102, 1)",
                              fontWeight: "500",
                            }}
                          >
                            <p>Sizes</p>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "right",
                                width: "100%",
                                gap: "20px",
                                paddingRight: "10px",
                              }}
                            >
                              <p style={{ flex: "0 0 80px" }}>Quantity</p>
                              <p style={{ flex: "0 0 50px" }}>Price</p>
                            </div>
                          </div>
                          <ProductDetailsContainer>
                            {inventories.map((inventory, recordKey) => {
                              let rangeUsed = false;
                              const { storeRequestPriceRange } = inventory;
                              return (
                                // <ProductRow key={inventory.id}>
                                <>
                                  <ProductDetails>
                                    <div>
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "8px",
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontWeight: "600",
                                            fontSize: "13px",
                                            marginTop: "4px",
                                            lineHeight: "1rem",
                                            position: "relative",
                                            top: "5px",
                                          }}
                                        >
                                          {inventory?.option1Value}
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "right",
                                      }}
                                    >
                                      <div style={{ flex: "0 0 50px" }}>
                                        <p
                                          style={{
                                            fontWeight: "600",
                                            fontSize: "13px",
                                            marginTop: "4px",
                                            lineHeight: "1rem",
                                            position: "relative",
                                            top: "5px",
                                          }}
                                        >
                                          {inventory.itemArray.length}{" "}
                                        </p>
                                      </div>
                                      <div style={{ flex: "0 0 50px" }}>
                                        <p
                                          style={{
                                            fontWeight: "600",
                                            fontSize: "13px",
                                            marginTop: "4px",
                                            lineHeight: "1rem",
                                            position: "relative",
                                            top: "5px",
                                          }}
                                        >
                                          {getSymbolFromCurrency(
                                            store.currency
                                          )}
                                          {inventory.price
                                            ? Number(inventory.price).toFixed(1)
                                            : "0"}
                                        </p>
                                      </div>
                                    </div>
                                  </ProductDetails>
                                </>
                                // </ProductRow>
                              );
                            })}
                          </ProductDetailsContainer>
                        </CollapsePanel>
                      );
                    })}
                  </StyledCollapse>
                  <div
                    style={{
                      padding: "15px",
                      background: "rgba(244, 244, 244, 1)",
                      marginTop: "15px",
                      borderRadius: "8px",
                    }}
                  >
                    <p style={{ fontSize: "15px", fontWeight: "500" }}>
                      Contact Information
                    </p>
                    <div>
                      <p
                        style={{
                          color: "rgba(10, 10, 10, 0.6)",
                          fontWeight: "500",
                          margin: "0",
                          marginBottom: "3px",
                        }}
                      >
                        Phone Number
                      </p>
                      <p
                        style={{
                          color: "rgba(10, 10, 10, 1)",
                          fontWeight: "500",
                        }}
                      >
                        {phone}
                      </p>
                    </div>
                  </div>
                </>
              </>
            )}
            {/* </> */}
          </Wrapper>
        </Container>
      </StyledDrawer>
    </>
  );
}
