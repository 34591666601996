import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  Button,
  Input,
  Spin,
  Modal,
  Select,
  message,
  Pagination,
  BackTop,
  Table,
  Image,
  Checkbox,
  Collapse,
  Popconfirm,
  Card,
  Radio,
} from "antd";
import Content from "../constants/Content";
import { Redirect } from "react-router";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  getInventories,
  getInventoryFilterOptions,
  acceptInventory,
  bulkAcceptInventory,
  declineInventory,
  acceptInventoryPrice,
  declineInventoryPrice,
  acceptInventoryWithdrawal,
  declineInventoryWithdrawal,
  bulkPrintInventory,
  exportInventory,
  getPaginatedInventories,
  acceptRequestToSell,
  bulkAcceptRequestToSell,
  storeRequestInventoryPriceChange,
  cancelDropoff,
  updateInventory,
} from "../redux/actions/inventoryActions";
import { printLabel } from "./InventoryItem";
import InventoryFilter, {
  CustomFormItem,
  FormValues as InventoryFormValues,
} from "../components/Common/InventoryFilter";
import InventoryList from "../components/Common/InventoryList";
import { RouteComponentProps, useHistory } from "react-router";
import { Inventory, InventoryState } from "../redux/reducers/InventoryReducer";
import { AppDispatch } from "../redux/store";
import { UserState } from "../redux/reducers/UserReducer";
import { Store, StoreState } from "../redux/reducers/StoreReducer";
import { getSubLocations } from "../redux/actions/subLocationActions";
import InventoryEditTable from "./InventoryEditTable";
import RedesignStyling from "../constants/RedesignStyling";
import Colors from "../constants/Colors";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CalculatorFilled,
  CaretDownOutlined,
  CaretRightOutlined,
  CheckCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  PrinterFilled,
  SearchOutlined,
  SettingOutlined,
  SyncOutlined,
  UnorderedListOutlined,
  EditOutlined,
  CheckOutlined,
  EyeOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import {
  ColumnTypes,
  InventoryTableStyled,
  sizeSorter,
} from "../consignerScreens/ConsignerInventory";
import moment from "moment";
import { ReactComponent as GroupOutlined } from "../assets/images/svg/GroupedTable.svg";
// import { ReactComponent as EditOutlined } from "../assets/images/svg/TripleDot.svg";
import getSymbolFromCurrency from "currency-symbol-map";
import { ButtonGroup, Link } from "@mui/material";
import { AppState } from "../redux/reducers/AppReducer";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import InventoryCardView, {
  StyledButton,
} from "../components/Common/InventoryCardView";
import AddInventoryModal from "./Inventory/AddInventoryModal";
import InvoiceModal from "./Inventory/InvoiceModal";
import CreateOrderModale from "./Inventory/CreateOrder";
import { createOrder } from "../redux/actions/orderActions";
import ShareInventoryModale from "./Inventory/ShareInventory";

const { Option } = Select;

const { Search } = Input;

/**
 * Find Inventory Screen
 * TODO Tests;
 *  - renders correctly search, filters, action btn, inventory btns
 *  - on search, filter
 *  - on inventory action click
 *  - on consigner link click
 *  - renders based on inventory page type
 */

//styles

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${RedesignStyling.PAGE_BG};
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: ${RedesignStyling.PAGE_PADDING_ADMIN};
  #inventoryFilter {
    margin: 0 0 0 15px;
    flex-wrap: nowrap;
    &.ant-form-inline .ant-form-item:last-of-type {
      margin-right: 0;
    }
  }
  @media (max-width: 768px) {
    padding: ${RedesignStyling.PAGE_PADDING_MOBILE};
    #inventoryFilter {
      margin: 0;
    }
    .ant-pagination {
      order: 9;
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    height: 40px;
    color: #292d32;
    border-radius: 4px;
    border: 1px solid var(--Line, #ddd);
    background: var(--White, #fff);
  }
  table {
    thead {
      .ant-checkbox-inner {
        background-color: transparent; /* Customize the check color */
      }
    }
  }
`;

const SearchBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 100%;
  & > span {
    // width: 900px;
    max-width: 100%;
    height: ${RedesignStyling.SEARCH_HEIGHT};
  }
  .ant-input-group.ant-input-wrapper {
    display: flex;
    height: 100%;
    .ant-input-affix-wrapper {
      max-width: 100%; // calc(100% - ${RedesignStyling.SEARCH_BUTTON_WIDTH});
      input {
        height: 30px;
      }
    }
  }
  button.ant-input-search-button {
    padding: 8px 16px;
    background-color: ${Colors.ADMIN_BLUE};
    color: ${Colors.WHITE};
    // border: 0;
    height: 15px; // ${RedesignStyling.SEARCH_HEIGHT};
    width: 15px; // ${RedesignStyling.SEARCH_BUTTON_WIDTH};
  }

  @media (max-width: 768px) {
    margin: 40px 0 8px;
    // order: 2;

    .ant-input-group.ant-input-wrapper .ant-input-affix-wrapper {
      max-width: calc(100% - ${RedesignStyling.SEARCH_BUTTON_WIDTH_MOBILE});
      width: 100%;
    }

    .ant-input-search-with-button .ant-input-group input.ant-input {
      font-size: 16px;
    }

    button.ant-input-search-button {
      width: ${RedesignStyling.SEARCH_BUTTON_WIDTH_MOBILE};
    }
  }
`;

const ActionBar = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    order: 3;
  }
`;

const StatusAndTableToggleView = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px 0;
  span {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  @media (max-width: 768px) {
    order: 4;
    padding: 8px 0;
    flex-direction: column;
    & > span {
      // display: none;
      justify-content: flex-end;
    }
  }
`;

const MobileCustomSpan = styled.div`
  display: none;
  width: 100%;
  padding: 12px 0;
  justify-content: space-between;
  gap: 5px;
  b {
    font-size: 18px;
    line-height: 150%;
  }
  button {
    max-height: 40px;
    height: 40px;
  }
  span {
    display: inline-flex;
    justify-content: end;
    align-items: center;
    gap: 8px;
  }
  @media (max-width: 768px) {
    display: flex;
  }
`;

export const CustomSpan = styled.div`
  display: flex;
  width: 100%;
  // padding: 0 0 16px 0;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  b {
    font-size: 20px;
    line-height: 150%;
  }
  button {
    max-height: 40px;
    height: 40px;
  }
  span {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    .ant-picker-range {
      background: transparent;
      width: 225px;
      border: 0;
      color: ${Colors.CONSIGNER_GREY};
    }
    .ant-picker-input > input {
      color: #9ca3af;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const CustomB = styled.b`
  font-weight: 500;
  font-size: inherit;
  padding: 0 4px;
  color: ${Colors.ADMIN_BLUE};
  &.underlined {
    text-decoration: underline;
  }
  &.green {
    color: #16a34a;
  }
  &.red {
    color: #ef4444;
  }
`;

const StatusSelectorDiv = styled.div`
  @media (max-width: 768px) {
    display: block;
    width: 100%;
    margin: auto;
    white-space: nowrap;
    overflow-x: scroll;
  }
`;

const StatusSelectorButton = styled(Button)`
  // border: 0;
  height: 30px;
  // padding: 16px 8px;
  margin: 0 10px 0 0;
  &.ant-btn-ghost {
    color: var(--input-field, rgba(88, 85, 85, 0.3));
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    background: var(--background-color, #f9fafb);
  }
`;

export const CustomInventoryFilterContainer = styled.span`
  white-space: nowrap;
  // max-width: calc(100% - 210px);
  flex-wrap: nowrap;
  display: inline-flex;
  flex: 3;
  /* -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  .ant-btn-primary {
    border-color: rgba(221, 221, 221, 1);
    color: rgba(41, 45, 50, 1);
  }

  .ant-btn {
    align-self: stretch;
    flex: 0 0 40px;
    height: 40px;
  }
  */ form {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
  @media (min-width: 1430px) {
    form {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;

const ScrollableContainer = styled.div`
  max-width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;

  .content-container {
    position: relative;
    display: flex;
    transition: transform 0.3s ease-in-out;
    white-space: nowrap;
    flex-wrap: nowrap;
  }
`;

const ReviewListingContainer = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100vw;
    order: 5;
    padding-bottom: 24px;
    .ant-collapse {
      .ant-collapse-item {
        .ant-collapse-header {
          // padding: 0;
        }
        .ant-collapse-content {
          .ant-collapse-content-box {
            padding: 0;
          }
        }
      }
    }
  }
`;

const Listing = styled.div`
  padding: 8px 0;
  display: flex;
  align-items: center;
  border: 1px solid var(--stroke-color, #e4e4e7);
  background: #fff;
  .ant-image {
    flex: 0 0 85px;
    img {
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
  label {
    flex: 0 0 35px;
  }
  @media (max-width: 768px) {
    flex-wrap: nowrap;
  }
`;

const ReviewSpan = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: start;
  font-weight: 400;
  position: relative;
  font-size: 11px;
  button {
    border: 0;
    &:not(.ant-btn-dangerous) {
      color: ${Colors.ADMIN_BLUE};
    }
  }
  b {
    font-size: 12px;
    max-width: 60%;
  }
  div {
    color: ${Colors.CONSIGNER_GREY};
    text-transform: uppercase;
  }
`;

const DetailsPanel = styled.div`
  display: flex;
  flex: 5 1 auto;
  padding: 4px 16px;
  flex-direction: column;
`;

const SearchDiv = styled.span`
  border-radius: 10px !important;
`;

const StyledPagination = styled(Pagination)`
  width: 100%;
  text-align: right;
  .ant-pagination-item-active a {
    color: white;
  }
  .ant-pagination-item-active {
    background: black !important;
    border-color: black !important;
  }
  .ant-pagination-item {
    background: rgba(244, 244, 244, 1);
    border: none;
    border-radius: 5px;
  }
  .ant-pagination-item-link {
    border: none;
    background: rgba(244, 244, 244, 1);
    color: rgba(0, 0, 0, 0.85);
    border-radius: 5px;
  }
  .ant-select-selector {
    align-items: center;
  }
`;

export const HorizontalScrollableContainer = ({ children }: any) => {
  const containerRef = useRef<any>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [maxScroll, setMaxScroll] = useState(0);
  const { isMobile } = useAppSelector((state) => state.AppReducer);

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const contentWidth = containerRef.current.scrollWidth;
      const newMaxScroll = contentWidth - containerWidth;
      setMaxScroll(newMaxScroll);
    }
  }, []);

  const handleScrollLeft = () => {
    if (scrollPosition <= 0) return;
    setScrollPosition(scrollPosition - 100); // Adjust the scroll amount as needed
  };

  const handleScrollRight = () => {
    setScrollPosition(scrollPosition + 100); // Adjust the scroll amount as needed
  };

  return (
    <div
      style={{
        display: "flex",
        gap: 10,
        maxWidth: isMobile ? "100%" : "calc(100% - 20px)",
      }}
    >
      <Button
        style={{ flex: "0 0 auto", width: 30, padding: 8 }}
        type="primary"
        className="scroll-button"
        onClick={handleScrollLeft}
        icon={<ArrowLeftOutlined />}
        disabled={scrollPosition <= 0}
      />
      <ScrollableContainer ref={containerRef}>
        <div
          className="content-container"
          style={{ transform: `translateX(-${scrollPosition}px)` }}
        >
          {children}
        </div>
      </ScrollableContainer>
      <Button
        style={{ flex: "0 0 auto", width: 30, padding: 8 }}
        type="primary"
        className="scroll-button"
        onClick={handleScrollRight}
        icon={<ArrowRightOutlined />}
        disabled={scrollPosition >= maxScroll}
      />
    </div>
  );
};

interface StatusSelectorProps {
  filters: any;
  setFilters: any;
  inventoryFilterOptions: any;
}

const StatusSelector = (props: StatusSelectorProps) => {
  const { filters, setFilters, inventoryFilterOptions } = props;
  const { statuses } = inventoryFilterOptions;
  const consignerStatuses = statuses.filter(
    (status) => status.value !== "PendingSale"
  );
  consignerStatuses.unshift({
    label: "All",
    value: "",
  });
  const [status, setStatus] = useState(filters.status);

  const handleSetStatus = (statValue: string) => {
    if (!status.includes(statValue)) {
      setStatus([...status, statValue]);
    } else {
      setStatus(status.filter((stat) => stat !== statValue));
    }
  };

  return (
    <StatusSelectorDiv>
      {consignerStatuses.map((stat, key) => (
        <StatusSelectorButton
          key={key}
          type={
            (filters.status.includes(stat.value) && stat.value !== "") ||
            (stat.label === "All" && filters.status === "")
              ? "primary"
              : "ghost"
          }
          onClick={() => setFilters({ ...filters, status: stat.value })}
        >
          {stat.label === "Pending" ? "Drop off pending" : stat.label}
        </StatusSelectorButton>
      ))}
    </StatusSelectorDiv>
  );
};

const CustomStatusSelectorContainer = styled.div`
  white-space: nowrap;
  overflow-x: scroll;
  flex-wrap: nowrap;

  @media (min-width: 1540px) {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export enum InventoryPageTypes {
  Find,
  Incoming,
  Requested,
  ReadyToPrint,
  Withdraw,
  PriceChange,
  RequestWithdrawal,
  Buying,
  Consignment,
}

interface Props {
  type: InventoryPageTypes;
}

interface SwitchableInventoryListProps {
  onAccept: (inventory: Inventory) => void;
  onDecline?: (inventory: Inventory) => void;
  declineButtonText?: string;
  acceptButtonText: string;
  acceptLoading?: boolean;
  declineLoading?: boolean;
}

const goToInventoryPage = (
  history: RouteComponentProps["history"],
  inventory: Inventory
) => {
  history.push(`/inventories/${inventory.id}`);
};

const getDefaultStatus = (inventoryPageType: InventoryPageTypes) => {
  switch (inventoryPageType) {
    case InventoryPageTypes.Find:
      return "";
    case InventoryPageTypes.Requested:
      return "Requested";
    case InventoryPageTypes.Incoming:
      return "Pending";
    case InventoryPageTypes.Withdraw:
      return "Withdrawn";
    case InventoryPageTypes.PriceChange:
      return "RequestPriceChange";
    case InventoryPageTypes.RequestWithdrawal:
      return "RequestWithdrawal";
    case InventoryPageTypes.ReadyToPrint:
      return "Active";
    case InventoryPageTypes.Buying:
      return "Buying";
    case InventoryPageTypes.Consignment:
      return "Consignment";
    default:
      return "";
  }
};

const acceptRequestedInventory = async (
  search: string,
  filters: InventoryFormValues,
  inventory: Inventory,
  page: number,
  pageSize: number,
  dispatch: AppDispatch,
  setShowRequestedInventoryModal: (show: boolean) => void
) => {
  if (inventory) {
    //accept
    await dispatch(await acceptRequestToSell(inventory));
    await dispatch(getPaginatedInventories(search, filters, page, pageSize));
    setShowRequestedInventoryModal(false);
  } else {
    message.error("No selected inventory");
  }
};

const acceptPendingInventory = async (
  search: string,
  filters: InventoryFormValues,
  inventory: Inventory,
  page: number,
  pageSize: number,
  dispatch: AppDispatch,
  setShowAcceptInventoryModal: (show: boolean) => void
) => {
  if (inventory) {
    //accept
    await dispatch(await acceptInventory(inventory));
    await dispatch(getPaginatedInventories(search, filters, page, pageSize));
    setShowAcceptInventoryModal(false);
  } else {
    message.error("No selected inventory");
  }
};

const bulkAcceptPendingInventory = async (
  search: string,
  filters: InventoryFormValues,
  selectedInventories: Inventory[],
  subLocation: string,
  dispatch: AppDispatch,
  setBulkAcceptModal: (show: boolean) => void
) => {
  if (selectedInventories) {
    //accept
    message.warning("Accepting inventories, this may take some time");
    await dispatch(
      await bulkAcceptInventory(selectedInventories, "", subLocation)
    );
    setBulkAcceptModal(false);
  } else {
    message.error("No selected inventory");
  }
};

const bulkAcceptRequestednventory = async (
  search: string,
  filters: InventoryFormValues,
  selectedInventories: Inventory[],
  subLocation: string,
  page: number,
  pageSize: number,
  dispatch: AppDispatch,
  setBulkAcceptRequestedModal: (show: boolean) => void
) => {
  if (selectedInventories) {
    //accept
    message.warning("Accepting requested inventories, this may take some time");
    await dispatch(
      await bulkAcceptRequestToSell(selectedInventories, "", subLocation)
    );
    await dispatch(getPaginatedInventories(search, filters, page, pageSize));
    setBulkAcceptRequestedModal(false);
  } else {
    message.error("No selected inventory");
  }
};

const declinePendingInventory = async (
  search: string,
  filters: InventoryFormValues,
  inventory: Inventory,
  currentPage: number,
  pageSize: number,
  dispatch: AppDispatch
) => {
  //accept
  await dispatch(await declineInventory(inventory));
  await dispatch(
    getPaginatedInventories(search, filters, currentPage, pageSize)
  );
};

const handleCancel = async (
  search: string,
  filters: InventoryFormValues,
  inventory: Inventory,
  dispatch: AppDispatch,
  page,
  pageSize
) => {
  await dispatch(await cancelDropoff([inventory.id], inventory.consignerId));
  dispatch(getPaginatedInventories(search, filters, page, pageSize));
};

const handleStoreRequestInventoryPriceChange = async (
  search: string,
  filters: InventoryFormValues,
  selectedInventory: Inventory,
  currentPage: number,
  pageSize: number,
  dispatch: AppDispatch,
  setShowRequestPriceChangeModal: any,
  reloadInventories?: any
) => {
  if (selectedInventory && selectedInventory.requestedPrice > 0) {
    //accept
    await dispatch(
      storeRequestInventoryPriceChange(selectedInventory, filters)
    );
    setShowRequestPriceChangeModal(false);
    await reloadInventories();
  } else {
    message.error("You must set the value for the price change");
  }
};

const addActionsAndButtonsMobile = (type: InventoryPageTypes, props: any) => {
  const {
    setSelectedInventory,
    setShowAcceptInventoryModal,
    setShowRequestedInventoryModal,
    setShowRequestPriceChangeModal,
    search,
    filters,
    page,
    pageSize,
    dispatch,
    store,
    handleSelect,
    handleSelectAll,
    selectedRowKeys,
    inventoryList,
  } = props;
  switch (type) {
    case InventoryPageTypes.Find:
      return (record) => (
        <Link target="_blank" href={`/inventories/${record.id}`}>
          <Button type="default" icon={<EditOutlined />}></Button>
        </Link>
      );
    case InventoryPageTypes.Incoming:
      return (record) => (
        <ButtonGroup style={{ display: "flex", justifyContent: "end" }}>
          <Button
            type="ghost"
            onClick={() => {
              setShowAcceptInventoryModal(true);
              setSelectedInventory(record);
            }}
            icon={<CheckCircleOutlined />}
          />
          <Button
            type="ghost"
            danger
            onClick={() =>
              declinePendingInventory(
                search,
                filters,
                record,
                page,
                pageSize,
                dispatch
              )
            }
            icon={<DeleteOutlined />}
          />
        </ButtonGroup>
      );
    case InventoryPageTypes.Requested:
      return (record) => (
        <ButtonGroup style={{ display: "flex", justifyContent: "end" }}>
          <Button
            type="ghost"
            onClick={() => {
              setShowRequestedInventoryModal(true);
              setSelectedInventory(record);
            }}
            icon={<CheckCircleOutlined />}
          />
          <Button
            type="ghost"
            danger
            onClick={() =>
              declinePendingInventory(
                search,
                filters,
                record,
                page,
                pageSize,
                dispatch
              )
            }
            icon={<DeleteOutlined />}
          />
          <Button
            type="ghost"
            onClick={() => {
              setShowRequestPriceChangeModal(true);
              setSelectedInventory(record);
            }}
            icon={<CalculatorFilled />}
          />
        </ButtonGroup>
      );
    case InventoryPageTypes.PriceChange:
      return (record) => (
        <ButtonGroup style={{ display: "flex", justifyContent: "end" }}>
          <Button
            type="ghost"
            onClick={() => {
              dispatch(acceptInventoryPrice(record)),
                dispatch(
                  getPaginatedInventories(search, filters, page, pageSize)
                );
            }}
            icon={<CheckCircleOutlined />}
          />
          <Button
            type="ghost"
            danger
            onClick={() => {
              dispatch(declineInventoryPrice(record)),
                dispatch(
                  getPaginatedInventories(search, filters, page, pageSize)
                );
            }}
            icon={<DeleteOutlined />}
          />
        </ButtonGroup>
      );
    case InventoryPageTypes.RequestWithdrawal:
      return (record) => (
        <ButtonGroup style={{ display: "flex", justifyContent: "end" }}>
          <Button
            type="ghost"
            onClick={() => {
              dispatch(acceptInventoryWithdrawal(record)),
                dispatch(
                  getPaginatedInventories(search, filters, page, pageSize)
                );
            }}
            icon={<CheckCircleOutlined />}
          />
          <Button
            type="ghost"
            danger
            onClick={() => {
              dispatch(declineInventoryWithdrawal(record)),
                dispatch(
                  getPaginatedInventories(search, filters, page, pageSize)
                );
            }}
            icon={<DeleteOutlined />}
          />
        </ButtonGroup>
      );
    case InventoryPageTypes.ReadyToPrint:
      return (record) => (
        <Button
          type="default"
          onClick={async () => {
            //print single item
            await printLabel([record], store as Store);
            await dispatch(bulkPrintInventory([record])),
              await dispatch(
                getPaginatedInventories(search, filters, page, pageSize)
              );
          }}
          icon={<PrinterFilled />}
        />
      );

    case InventoryPageTypes.Consignment:
    case InventoryPageTypes.Buying:
      return (record) => (
        <ButtonGroup
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <Button
            type="primary"
            onClick={() => {
              // setShowAcceptInventoryModal(true);
              // setSelectedInventory(record);
            }}
            href={`/inventories/${record.id}`}
            target="_blank"
          >
            Edit
          </Button>
          <Popconfirm
            onConfirm={() =>
              handleCancel(search, filters, record, dispatch, page, pageSize)
            }
            title={"Cancel Request"}
          >
            <Button type="primary" danger>
              Delete
            </Button>
          </Popconfirm>
        </ButtonGroup>
      );
  }
};

const visibleColumns: ColumnTypes[number][] = [
  {
    title: "Product",
    key: "productImage",
    dataIndex: "product.image",
    render: (_: any, record: any) => (
      <Image
        src={record.product.image}
        alt=""
        style={{ width: 100, height: "auto" }}
      ></Image>
    ),
  },
  {
    title: "Consigner",
    dataIndex: "consigner",
    render: (_: any, record: any) => {
      const { consigner } = record;
      return `${consigner?.firstName} ${consigner?.lastName}`;
    },
  },
  {
    title: "Name",
    key: "productName",
    dataIndex: "product.title",
    render: (_: any, record: any) => (
      <div style={{ display: "inline-block" }}>
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: 220,
          }}
        >
          <div>{record.product.title}</div>
          <div>{record.code}</div>
        </span>
      </div>
    ),
  },
  {
    title: "SKU",
    key: "sku",
    dataIndex: "product.sku",
    render: (_: any, record: any) => {
      const len = record.product.sku.length;
      if (len > 20) {
        const firstPart = record.product.sku.slice(0, 20);
        const secondPart = record.product.sku.slice(20);
        return (
          <>
            {firstPart}
            <br />
            {secondPart}
          </>
        );
      } else {
        return record.product.sku;
      }
    },
  },
  {
    title: "Option 3",
    key: "option3Value",
    dataIndex: "option3Value",
    render: (_: any, record: any) => record?.option3Value ?? null,
  },
  {
    title: "Size",
    key: "option1Value",
    dataIndex: "option1Value",
    render: (_: any, record: any) => record?.option1Value ?? null,
    sorter: sizeSorter,
    defaultSortOrder: "ascend",
  },
  {
    title: "Condition",
    key: "option2Value",
    dataIndex: "option2Value",
    render: (_: any, record: any) => record?.option2Value ?? null,
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (_: any, record: any) => (
      <CustomB className="underlined green">
        {record?.status === "Pending" ? "Drop off pending" : record.status}
      </CustomB>
    ),
  },
  {
    title: "Price",
    key: "price",
    dataIndex: "price",
    render: (_: any, record: any) =>
      `${getSymbolFromCurrency(record.currency)}${record.price}`,
  },
  {
    title: "Payout",
    key: "total",
    dataIndex: "total",
    render: (_: any, record: any) =>
      `${getSymbolFromCurrency(record.currency)}${Number(
        record.payoutAmount
      ).toFixed(2)}`,
  },
];

const addActionsAndButtons = (type: InventoryPageTypes, props: any) => {
  const {
    setSelectedInventory,
    setShowAcceptInventoryModal,
    setShowRequestedInventoryModal,
    setShowRequestPriceChangeModal,
    search,
    filters,
    page,
    pageSize,
    dispatch,
    store,
    handleSelect,
    handleSelectAll,
    selectedRowKeys,
    inventoryList,
    nestedView,
    isInvoice,
    setIsInvoice,
    handleInvoiceRecord,
  } = props;
  const updateCols = [...visibleColumns];

  switch (type) {
    case InventoryPageTypes.Find:
      //add checkboxes at the start of the column
      updateCols.unshift({
        title: () => (
          <Checkbox
            checked={selectedRowKeys.length === inventoryList.length}
            onChange={handleSelectAll}
            style={{ padding: "0 10px" }}
          />
        ),
        dataIndex: "id",
        render: (id) => (
          <>
            <Checkbox
              checked={selectedRowKeys.includes(id)}
              onChange={(e) => handleSelect(e.target.checked, id)}
              style={{ padding: "0 10px" }}
            />
          </>
        ),
      });

      // add buttons at the end
      updateCols.push({
        title: "",
        dataIndex: "operation",
        render: (_: any, record: any) => (
          // <Link target="_blank" href={`/inventories/${record.id}`}>
          // </Link>
          <Button
            type="default"
            icon={<EyeOutlined />}
            onClick={() => {
              setIsInvoice(true);
              handleInvoiceRecord(record);
            }}
            style={{
              background: "rgba(244, 244, 244, 1)",
              borderRadius: "5px",
            }}
          ></Button>
        ),
      });

      // add acceptedOn column
      updateCols.splice(8, 0, {
        title: "Days Active",
        key: "acceptedOn",
        dataIndex: "acceptedOn",
        render: (_: any, record: any) => {
          const acceptedOnDate = moment(record?.acceptedOn ?? new Date());
          let current = moment();
          const daysSinceAccept = current.diff(acceptedOnDate, "days");
          return daysSinceAccept + " Days";
        },
      });
      break;
    case InventoryPageTypes.Incoming:
      // remove status field for Requested
      updateCols.splice(7, 1);

      //add checkboxes at the start of the column
      updateCols.unshift({
        title: () => (
          <Checkbox
            checked={selectedRowKeys.length === inventoryList.length}
            onChange={handleSelectAll}
            style={{ padding: "0 10px" }}
          />
        ),
        dataIndex: "id",
        render: (id) => (
          <>
            <Checkbox
              checked={selectedRowKeys.includes(id)}
              onChange={(e) => handleSelect(e.target.checked, id)}
              style={{ padding: "0 10px" }}
            />
          </>
        ),
      });

      // add buttons at the end
      updateCols.push({
        title: "",
        dataIndex: "operation",
        render: (_: any, record: any) =>
          nestedView === true ? (
            <div
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <div
                style={{
                  background: "white",
                  padding: "5px",
                  cursor: "pointer",
                  border: "1px solid #DDDDDD",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <CheckOutlined
                  onClick={() => {
                    setShowAcceptInventoryModal(true);
                    setSelectedInventory(record);
                  }}
                />
              </div>
              <div
                style={{
                  background: "white",
                  padding: "5px",
                  cursor: "pointer",
                  border: "1px solid #DDDDDD",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <CloseOutlined
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    declinePendingInventory(
                      search,
                      filters,
                      record,
                      page,
                      pageSize,
                      dispatch
                    )
                  }
                />
              </div>
            </div>
          ) : (
            <ButtonGroup style={{ display: "flex", justifyContent: "center" }}>
              <StyledButton
                type="primary"
                onClick={() => {
                  setShowAcceptInventoryModal(true);
                  setSelectedInventory(record);
                }}
                icon={<CheckCircleOutlined />}
              >
                {Content.INCOMING_INVENTORY_ACCEPT_BUTTON_TEXT}
              </StyledButton>
              <StyledButton
                type="primary"
                danger
                onClick={() =>
                  declinePendingInventory(
                    search,
                    filters,
                    record,
                    page,
                    pageSize,
                    dispatch
                  )
                }
                icon={<CloseOutlined />}
              >
                {Content.INCOMING_INVENTORY_DECLINE_BUTTON_TEXT}
              </StyledButton>
            </ButtonGroup>
          ),
      });

      break;
    case InventoryPageTypes.Requested:
      // remove status field for Requested
      updateCols.splice(7, 1);

      // add checkboxes at the start of each column
      updateCols.unshift({
        title: () => (
          <Checkbox
            checked={selectedRowKeys.length === inventoryList.length}
            onChange={handleSelectAll}
            style={{ padding: "0 10px" }}
          />
        ),
        dataIndex: "id",
        render: (id) => (
          <>
            <Checkbox
              checked={selectedRowKeys.includes(id)}
              onChange={(e) => handleSelect(e.target.checked, id)}
              style={{ padding: "0 10px" }}
            />
          </>
        ),
      });

      // add buttons at the end
      updateCols.push({
        title: "",
        dataIndex: "operation",
        render: (_: any, record: any) => (
          <ButtonGroup style={{ display: "flex", justifyContent: "center" }}>
            <StyledButton
              type="primary"
              onClick={() => {
                setShowRequestedInventoryModal(true);
                setSelectedInventory(record);
              }}
              icon={<CheckCircleOutlined />}
            >
              Accept
            </StyledButton>
            <StyledButton
              type="primary"
              danger
              onClick={() =>
                declinePendingInventory(
                  search,
                  filters,
                  record,
                  page,
                  pageSize,
                  dispatch
                )
              }
              icon={<CloseOutlined />}
            >
              {Content.REQUEST_SELL_INVENTORY_DECLINE_BUTTON_TEXT}
            </StyledButton>
            <StyledButton
              type="primary"
              onClick={() => {
                setShowRequestPriceChangeModal(true);
                setSelectedInventory(record);
              }}
              icon={<SyncOutlined />}
            >
              Price Change
            </StyledButton>
          </ButtonGroup>
        ),
      });
      break;
    case InventoryPageTypes.PriceChange:
      // add buttons at the end
      updateCols.push({
        title: "",
        dataIndex: "operation",
        render: (_: any, record: any) => (
          <ButtonGroup style={{ display: "flex", justifyContent: "center" }}>
            <StyledButton
              type="primary"
              onClick={() => {
                dispatch(acceptInventoryPrice(record)),
                  dispatch(
                    getPaginatedInventories(search, filters, page, pageSize)
                  );
              }}
              icon={<CheckCircleOutlined />}
            >
              {Content.PRICE_CHANGE_INVENTORY_ACCEPT_BUTTON_TEXT}
            </StyledButton>
            <StyledButton
              type="primary"
              danger
              onClick={() => {
                dispatch(declineInventoryPrice(record)),
                  dispatch(
                    getPaginatedInventories(search, filters, page, pageSize)
                  );
              }}
              icon={<CloseOutlined />}
            >
              {Content.PRICE_CHANGE_INVENTORY_DECLINE_BUTTON_TEXT}
            </StyledButton>
          </ButtonGroup>
        ),
      });
      break;
    case InventoryPageTypes.RequestWithdrawal:
      // add buttons at the end
      updateCols.push({
        title: "",
        dataIndex: "operation",
        render: (_: any, record: any) => (
          <ButtonGroup style={{ display: "flex", justifyContent: "center" }}>
            <StyledButton
              type="primary"
              onClick={() => {
                dispatch(acceptInventoryWithdrawal(record)),
                  dispatch(
                    getPaginatedInventories(search, filters, page, pageSize)
                  );
              }}
              icon={<CheckCircleOutlined />}
            >
              {Content.INVENTORY_WITHDRAWAL_REQUEST_ACCEPT_BUTTON_TEXT}
            </StyledButton>
            <StyledButton
              type="primary"
              danger
              onClick={() => {
                dispatch(declineInventoryWithdrawal(record)),
                  dispatch(
                    getPaginatedInventories(search, filters, page, pageSize)
                  );
              }}
              icon={<CloseOutlined />}
            >
              {Content.INVENTORY_WITHDRAWAL_REQUEST_DECLINE_BUTTON_TEXT}
            </StyledButton>
          </ButtonGroup>
        ),
      });
      break;
    case InventoryPageTypes.ReadyToPrint:
      // add checkboxes at the start of each column
      updateCols.unshift({
        title: () => (
          <Checkbox
            checked={selectedRowKeys.length === inventoryList.length}
            onChange={handleSelectAll}
            style={{ padding: "0 10px" }}
          />
        ),
        dataIndex: "id",
        render: (id) => (
          <>
            <Checkbox
              checked={selectedRowKeys.includes(id)}
              onChange={(e) => handleSelect(e.target.checked, id)}
              style={{ padding: "0 10px" }}
            />
          </>
        ),
      });

      // add buttons at the end
      updateCols.push({
        title: "",
        dataIndex: "operation",
        render: (_: any, record: any) => (
          <StyledButton
            type="primary"
            onClick={async () => {
              //print single item
              await printLabel([record], store as Store);
              await dispatch(bulkPrintInventory([record])),
                await dispatch(
                  getPaginatedInventories(search, filters, page, pageSize)
                );
            }}
            icon={<CheckCircleOutlined />}
          >
            {Content.READY_TO_PRINT_INVENTORY_ACCEPT_BUTTON_TEXT}
          </StyledButton>
        ),
      });
      break;

    case InventoryPageTypes.Consignment:
    case InventoryPageTypes.Buying:
      // add checkboxes at the start of each column
      updateCols.push({
        title: "",
        dataIndex: "operation",
        render: (_: any, record: any) => (
          <ButtonGroup style={{ display: "flex", justifyContent: "center" }}>
            <StyledButton
              type="primary"
              onClick={() => {
                // setShowAcceptInventoryModal(true);
                // setSelectedInventory(record);
              }}
              href={`/inventories/${record.id}`}
              target="_blank"
              icon={<EditOutlined />}
            >
              Edit
            </StyledButton>
            <Popconfirm
              onConfirm={() =>
                handleCancel(search, filters, record, dispatch, page, pageSize)
              }
              title={"Cancel Request"}
            >
              <StyledButton type="primary" danger icon={<CloseOutlined />}>
                Delete
              </StyledButton>
            </Popconfirm>
          </ButtonGroup>
        ),
      });
      break;
  }
  return updateCols;
};

const CollapsibleReviewListingView = (props: any) => {
  const { inventoryList, handleDelete, store } = props;
  const [activeKey, setActiveKey] = useState("");

  const CollapseHeader = (record: any) => {
    const { activeKey, image, title, keyItem, quantity } = record;
    const expandIcon =
      activeKey === keyItem ? (
        <CaretDownOutlined style={{ flex: "0 0 14px" }} />
      ) : (
        <CaretRightOutlined style={{ flex: "0 0 14px" }} />
      );
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <img width={52.5} src={image} />
        <span
          style={{
            flex: 4,
            padding: "4px 0 4px 16px",
            fontWeight: 600,
            fontSize: 12,
          }}
        >
          {title}
        </span>
        <span
          style={{
            flex: 2,
            padding: "4px 8px 4px 0",
            fontWeight: 600,
            fontSize: 12,
          }}
        >
          {quantity} items
        </span>
        {expandIcon}
      </div>
    );
  };

  const handleCollapseChange = (key) => {
    setActiveKey(key);
  };
  // return <Spin />

  return (
    <ReviewListingContainer>
      <Collapse
        accordion
        expandIcon={() => null}
        activeKey={activeKey}
        onChange={handleCollapseChange}
      >
        {inventoryList?.map((inventory, key) => {
          1;
          const { inventories } = inventory;
          return (
            <CollapsePanel
              key={`${inventory.productId}`}
              header={
                <CollapseHeader
                  keyItem={`${inventory.productId}`}
                  activeKey={activeKey}
                  image={inventory.productImage}
                  title={inventory.productTitle}
                  quantity={inventories?.length ?? 1}
                />
              }
            >
              {inventories?.map((record, recordKey) => (
                <Listing key={recordKey}>
                  <Image
                    src={record?.product?.image ?? record.productImage}
                  ></Image>
                  <DetailsPanel>
                    <ReviewSpan>
                      <b>{record?.product?.title ?? record.productName}</b>
                      <b>
                        {getSymbolFromCurrency(store.currency)} {record.price}
                      </b>
                    </ReviewSpan>
                    <ReviewSpan>
                      <div>
                        {record.code} |{record?.product?.sku ?? record.sku} |{" "}
                        {record.option1Value} | {record.option2Value}
                      </div>
                      <Link
                        target="_blank"
                        href={`/productItem/${record?.product.id}`}
                      >
                        <Button type="ghost" icon={<EditOutlined />}></Button>
                      </Link>
                    </ReviewSpan>
                  </DetailsPanel>
                </Listing>
              ))}
            </CollapsePanel>
          );
        })}
      </Collapse>
    </ReviewListingContainer>
  );
};

const ReviewListingView = (props: any) => {
  const { inventoryList, store, nestedView, groupedData, functionCols } = props;
  const [splitInventory, setSplitInventory] = useState<any[]>([]);

  useEffect(() => {
    if (nestedView) return;
    if (inventoryList) {
      setSplitInventory(
        inventoryList?.map((inventory) => ({
          ...inventory,
          currency: store.currency,
        }))
      );
      return;
    }
    const inventoryArray: any[] = [];
    inventoryList?.forEach((inventory: any) => {
      for (let i = 0; i < inventory.quantity; i++) {
        inventoryArray.push({
          ...inventory,
          key: inventory.id,
          currency: store.currency,
        });
      }
    });
    setSplitInventory(inventoryArray);
  }, [inventoryList, nestedView]);

  return (
    <>
      {nestedView ? (
        <CollapsibleReviewListingView
          inventoryList={groupedData} //  add currency
          store={store}
          handleDelete={null}
        />
      ) : (
        <ReviewListingContainer>
          {splitInventory?.map((record, key) => (
            <Listing key={key}>
              <Image
                src={record?.product?.image ?? record.productImage}
              ></Image>
              <DetailsPanel>
                <ReviewSpan>
                  <b>{record?.product?.title ?? record.productName}</b>
                  <b>
                    {getSymbolFromCurrency(store.currency)} {record.price}
                  </b>
                </ReviewSpan>
                <ReviewSpan>
                  <div>
                    {record.code} |{record?.product?.sku ?? record.sku} |{" "}
                    {record.option1Value} | {record.option2Value}
                  </div>
                  {functionCols && (
                    <ButtonGroup>{functionCols(record)}</ButtonGroup>
                  )}
                </ReviewSpan>
              </DetailsPanel>
            </Listing>
          ))}
        </ReviewListingContainer>
      )}
    </>
  );
};

const ExpandedRowRender = (record, inventories, expandedCols) => {
  if (!record || !inventories) return <Spin />;
  const expandedDataSrc = inventories.filter(
    (inventory) => inventory.productId === record.productId
  );
  return (
    <Table
      dataSource={expandedDataSrc}
      style={{ borderLeft: `4px solid ${Colors.ADMIN_BLUE}` }}
      columns={expandedCols as ColumnTypes}
      rowKey="id"
      pagination={{
        pageSize: 10,
        // responsive: true,
        hideOnSinglePage: true,
      }}
      showHeader={true}
    />
  );
};

export const InventoryTable = (props: any): JSX.Element => {
  const {
    inventoryList,
    inventoryLoading,
    nestedView,
    type,
    search,
    filters,
    dispatch,
    page,
    pageSize,
    setSelectedInventory,
    setShowAcceptInventoryModal,
    setShowRequestedInventoryModal,
    setShowRequestPriceChangeModal,
    handleSelect,
    handleSelectAll,
    selectedRowKeys,
    setBulkAcceptRequestedModal,
    setBulkAcceptModal,
    setSelectedRowKeys,
    ordersView,
    handleViewClickProp,
    reloadInventories,
    handleStatusChange,
  } = props;
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [groupedData, setGroupedData] = useState<any[]>([]);
  const [groupedDataOrder, setGroupedDataOrder] = useState<any[]>([]);
  const [columnSrc, setColumnSrc] = useState<any[]>([]);
  const { isMobile }: AppState = useAppSelector((state) => state.AppReducer);
  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);
  const [priceMap, setPriceMap] = useState<any[]>([]);
  const [isInvoice, setIsInvoice] = useState(false);
  const [invoiceRecord, setInvoiceRecord] = useState({});

  useEffect(() => {
    if (inventoryList && selectedRowKeys) {
      setSelectedInventory((prev) =>
        inventoryList.filter((inventory) =>
          selectedRowKeys.includes(inventory.id)
        )
      );
    }
  }, [selectedRowKeys, inventoryList]);
  useEffect(() => {
    console.log("orderList", inventoryList);
  }, [inventoryList]);
  useEffect(() => {
    console.log("columnSrc", columnSrc);
  }, [columnSrc, setColumnSrc]);

  const handleInvoiceRecord = (record: any) => {
    setInvoiceRecord(record);
    // Handle the invoice record here
  };
  const handleViewClick = (inventory) => {
    console.log("=====1=====", inventory);

    handleViewClickProp(inventory);
  };
  const groupColumns: ColumnTypes[number][] = [
    {
      title: "Product",
      dataIndex: "productImage",
      key: "product.image",
      render: (image) => (
        <Image
          src={image}
          alt="Product Photo"
          style={{ width: 100, height: "auto" }}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "productTitle",
      key: "product.title",
    },
    {
      title: "SKU",
      dataIndex: "productSku",
      key: "product.sku",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
  ];
  if (
    [InventoryPageTypes.Incoming, InventoryPageTypes.Requested].includes(type)
  ) {
    groupColumns.unshift({
      title: "Consigner",
      dataIndex: "consigner",
      key: "consigner",
      render: (consigner: any, record: any) =>
        `${consigner.firstName} ${consigner.lastName}`,
    });
    groupColumns.splice(4, 1, {
      title: "Units",
      dataIndex: "quantity",
      key: "quantity",
      render: (value) => `${value} Units`,
    });
    groupColumns.splice(5, 0, {
      title: "",
      dataIndex: "inventories",
      key: "inventories",
      render: (data: Inventory[]) => (
        <Button
          type="primary"
          onClick={() => {
            setSelectedRowKeys(
              data.map((inventory: Inventory) => inventory.id)
            );
            if (type === InventoryPageTypes.Incoming) setBulkAcceptModal(true);
            else setBulkAcceptRequestedModal(true);
          }}
        >
          Accept All
        </Button>
      ),
    });
  }

  useEffect(() => {
    if (!inventoryList) return;

    setColumnSrc(
      addActionsAndButtons(type, {
        search,
        filters,
        page,
        pageSize,
        dispatch,
        setShowAcceptInventoryModal,
        setShowRequestedInventoryModal,
        setShowRequestPriceChangeModal,
        setSelectedInventory,
        store,
        handleSelect,
        handleSelectAll,
        selectedRowKeys,
        inventoryList,
        nestedView,
        setIsInvoice,
        handleInvoiceRecord,
      })
    );
    if (!nestedView) {
      setDataSource(
        inventoryList.map((inventory) => ({
          ...inventory,
          key: inventory.id,
          currency: store.currency,
        }))
      ); // assign key for table
      return;
    }
    const groupedData = inventoryList.reduce((groups, item) => {
      const groupId =
        props.type === InventoryPageTypes.Find
          ? `${item.product?.sku}`
          : `${item.consigner.id}`;
      // const groupId = `${item.productId}${item.sku}`;
      if (!groups[groupId]) {
        groups[groupId] = {
          productId: item.productId,
          key: groupId,
          productTitle:
            props.type === InventoryPageTypes.Find
              ? item?.product?.title
              : `${item.consigner?.firstName} ${item.consigner?.lastName}`,
          productImage: item.product?.image,
          productSku: item.product?.sku,
          consigner: item?.consigner,
          quantity: 0,
          inventories: [],
        };
      }
      groups[groupId].quantity++;
      groups[groupId].inventories.push(item);
      return groups;
    }, {});
    console.log("####inventoryList#####", inventoryList);

    const groupedDataOrder = inventoryList.reduce((groups, item) => {
      const groupId =
        props.type === InventoryPageTypes.Find
          ? `${item?.orderId}`
          : `${item?.orderId}`;
      // const groupId = `${item.productId}${item.sku}`;
      if (!groups[groupId]) {
        groups[groupId] = {
          productId: item.orderId,
          key: groupId,
          productTitle:
            props.type === InventoryPageTypes.Find
              ? item?.product?.title
              : `${item.consigner?.firstName} ${item.consigner?.lastName}`,
          productImage: item.product?.image,
          productSku: item.product?.sku,
          consigner: item?.consigner,
          quantity: 0,
          inventories: [],
        };
      }
      groups[groupId].quantity++;
      groups[groupId].inventories.push(item);
      return groups;
    }, {});
    setDataSource(Object.values(groupedData));
    setGroupedData(Object.values(groupedData));
    setGroupedDataOrder(Object.values(groupedDataOrder));
    // setColumnSrc(groupColumns);
  }, [
    inventoryList,
    nestedView,
    selectedRowKeys,
    handleSelect,
    handleSelectAll,
  ]);

  return (
    <InventoryCardView
      nestedView={nestedView}
      groupedData={groupedData}
      groupedDataOrder={groupedDataOrder}
      inventories={dataSource}
      store={store}
      loading={inventoryLoading}
      columnSrc={columnSrc}
      priceMap={priceMap}
      setPriceMap={setPriceMap}
      status={props.type}
      setSelectedRowKeys={setSelectedRowKeys}
      setShowAcceptInventoryModal={setBulkAcceptModal}
      setShowRequestedInventoryModal={setBulkAcceptRequestedModal}
      isInvoice={isInvoice}
      invoiceRecord={invoiceRecord}
      ordersView={ordersView}
      setIsInvoiceModal={() => setIsInvoice(false)}
      handleViewClickProp={handleViewClick}
      handleStatusChange={handleStatusChange}
    />
  );
};
const CustomModal = styled(Modal)`
  @media (max-width: 768px) {
    height: 100%;
    top: 0;
    .ant-modal-content {
      height: 100%;
      padding: 0;
      display: flex;
      .ant-modal-body {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 0;
        div.header {
          height: 60px;
          padding: 16px;
          display: flex;
          border-bottom: 1px solid var(--Line, #ddd);
          background: #f9f9f9;
          justify-content: space-between;
          align-items: center;
          font-size: 16px;
        }

        .footer {
          padding: 16px;
          display: flex;
          justify-content: flex-end;
          .ant-btn {
            border-radius: 8px;
            background: #b2c7eb;
            height: 70px;
            width: 194px;
            font-size: 18px;
          }
        }
        .ant-card {
          flex: 1;
          display: flex;
          flex-direction: column;

          .ant-card-body {
            padding: 16px;
            flex: 1;
            display: flex;
            justify-content: space-evenly;
            flex-direction: column;
          }
        }
      }
    }
    #inventoryFilter {
      display: flex;
      flex-direction: column;
      gap: 16px;
      div {
        height: 66px;
        display: flex;
        align-items: center;
        flex: 1;
        align-self: stretch;
        border-radius: 4px;
      }
      .ant-form-item {
        align-self: stretch;
        display: flex;
        width: 100%;
        align-items: center;
        .ant-form-item-row {
          flex: 1;
          display: flex;
          align-self: stretch;
          height: 66px;
          .ant-form-item-control-input {
            align-self: stretch;
            height: 66px;
            display: flex;
            border-radius: 8px;
            .ant-form-item-control-input-content {
              flex: 1;
              align-self: stretch;
              background: var(--White, #fff);
              border-radius: 8px;
              height: 66px;
              .ant-select {
                height: 66px;
                .ant-select-selector {
                  height: 66px;
                  input {
                    height: 66px;
                  }
                  .ant-select-selection-placeholder,
                  .ant-select-selection-item {
                    line-height: 60px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const SearchBoxWrapper = styled.div`
  position: relative;
  flex: 1;
  .ant-input-affix-wrapper {
    border-radius: 4px !important;
    border: 1px solid #d9d9d9;
    transition: all 0.3s;
    padding-left: 32px; // Adjust padding to make room for the icon
    height: 40px;

    &:hover {
      border-color: #40a9ff;
    }

    &:focus,
    &:active {
      border-color: #40a9ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  }

  .ant-input {
    height: 40px;
    border-radius: 4px;
    &:focus,
    &:active {
      box-shadow: none;
    }
  }

  .ant-input-group-addon {
    position: absolute;
    left: 15px !important;
    top: 50%;
    transform: translateY(-50%);
    color: #d9d9d9;
    font-size: 16px;
    z-index: 2;
    button {
      border: none;
    }
  }

  .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #d9d9d9;
    font-size: 16px;
    z-index: 2;
  }

  @media (max-width: 768px) {
    .ant-input-group-addon {
      left: 25px !important;
    }
  }
`;

const SearchInput = styled(Input.Search)`
  width: 100%;
  height: 32px; // Adjust the height as needed to match your screenshot

  .ant-input {
    height: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;

  .filter-button {
    background: rgba(244, 244, 244, 1);
    color: rgba(152, 152, 152, 1);
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;

    &.checked {
      background: white;
      color: black;
      border: 1px solid rgba(215, 215, 215, 1);
    }
  }
`;

export const SrchBx = ({
  value,
  onChange,
  placeholder,
}: {
  value: string;
  onChange: any;
  placeholder: string;
}) => {
  const [searchValue, setSearchValue] = useState(value);
  return (
    <SearchBoxWrapper>
      <SearchInput
        onSearch={() => onChange(searchValue)}
        onChange={(e) => setSearchValue(e.target.value)}
        value={searchValue}
        data-testid="search"
        placeholder={placeholder}
        allowClear
        size="small"
      />
      {/* <SearchOutlined className="search-icon" /> */}
    </SearchBoxWrapper>
  );
};

const FindInventory = (props: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {
    paginatedInventories,
    inventoriesLoading,
    inventoryFilterOptions,
    inventoryFilterOptionsLoading,
    bulkPrintInventoryLoading,
    acceptRequestedInventoryLoading,
    acceptInventoryLoading,
    declineInventoryLoading,
    acceptInventoryPriceLoading,
    declineInventoryPriceLoading,
    acceptInventoryWithdrawalLoading,
    declineInventoryWithdrawalLoading,
    exportInventoryLoading,
    requestInventoryPriceChangeLoading,
  }: InventoryState = useAppSelector((state) => state.InventoryReducer);
  const [search, setSearch] = useState("");
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [selectedSubLocation, setSelectedSubLocation] = useState<any>(null);
  const [paymentType, setPaymentType] = useState<any>(null);
  const [filteredSubLocations, setFilteredSubLocations] = useState<any>([]);
  const [selectedInventories, setSelectedInventories] = useState<any>([]);
  const [bulkAcceptModal, setBulkAcceptModal] = useState(false);
  const [bulkAcceptRequestedModal, setBulkAcceptRequestedModal] =
    useState(false);
  const [editInventory, setEditInventory] = useState(false);
  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);
  const { store } = useAppSelector((state) => state.StoreReducer);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tableNestedView, setTableNestedView] = useState(false);
  const [showRequestPriceChangeModal, setShowRequestPriceChangeModal] =
    useState(false);
  const [showAcceptInventoryModal, setShowAcceptInventoryModal] =
    useState(false);
  const [showRequestedInventoryModal, setShowRequestedInventoryModal] =
    useState(false);
  const [selectedInventory, setSelectedInventory] = useState<Inventory | null>(
    []
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [addInventoryModal, setAddInventoryModal] = useState(false);
  const [isCreateOrder, setIsCreateOrder] = useState(false);
  const [isShareInventory, setIsShareInventory] = useState(false);
  useEffect(() => {
    console.log("paginatedInventories>>>", paginatedInventories.rows);
  }, [paginatedInventories]);

  const getPageTitle = (type: InventoryPageTypes): string => {
    switch (type) {
      case InventoryPageTypes.Find:
        return "Find Inventory";
        break;

      case InventoryPageTypes.Buying:
        return "Buying";
        break;

      case InventoryPageTypes.Consignment:
        return "Consignment";
        break;

      case InventoryPageTypes.Incoming:
        return "Incoming";
        break;

      case InventoryPageTypes.PriceChange:
        return "Inventory Price Change";
        break;

      case InventoryPageTypes.Requested:
        return "Request";
        break;

      case InventoryPageTypes.ReadyToPrint:
        return "Ready To Print";
        break;

      case InventoryPageTypes.RequestWithdrawal:
        return "Withdrawal Request";
        break;

      default:
        return "";
    }
  };

  const handleSelect = (checked: boolean, rowKey: React.Key) => {
    setSelectedRowKeys((prevValue) => {
      if (checked) {
        return [...prevValue, rowKey];
      } else {
        return prevValue.filter((key) => key !== rowKey);
      }
    });
  };

  const handleSelectAll = (checked = true) => {
    // const { checked } = event.target;
    const newSelectedRowKeys = checked
      ? paginatedInventories.rows.map((record) => record.id)
      : [];

    setSelectedRowKeys(newSelectedRowKeys);
  };

  useEffect(() => {
    if (!selectedRowKeys || selectedRowKeys.length === 0)
      setSelectedInventories([]);
    setSelectedInventories(
      paginatedInventories.rows?.filter((inventory) =>
        selectedRowKeys.includes(inventory.id)
      )
    );
  }, [selectedRowKeys]);

  let defaultStatus = getDefaultStatus(props.type);
  const [filters, setFilters] = useState<any>({
    status:
      props.type === InventoryPageTypes.Find
        ? "Active"
        : getDefaultStatus(props.type),
    printed: defaultStatus == "Active" ? "false" : undefined,
    option1Value: undefined,
    option2Value: undefined,
    option3Value: undefined,
    category: undefined,
    sortOption: "newestUpdated",
    consigner:
      dbUser && dbUser.id && process.env.REACT_APP_TYPE === "consigner"
        ? dbUser.id
        : undefined,
  });

  useEffect(() => {
    dispatch(getInventoryFilterOptions());
  }, []);

  useEffect(() => {
    //refresh inventory list on filter or search change
    reloadInventories();
  }, [search, filters, tableNestedView, currentPage, pageSize]);

  const reloadInventories = async () => {
    setSelectedRowKeys([]);
    await dispatch(
      getPaginatedInventories(
        search,
        filters,
        currentPage,
        pageSize,
        props.type === InventoryPageTypes.Find ? tableNestedView : false,
        tableNestedView
      )
    );
  };

  const handleStatusChange = async (inventory: any, status: any) => {
    if (status === "Sold") {
      const payload = {
        ordersData: [inventory],
        isSeller: false,
      };
      await dispatch(createOrder(payload));
    } else {
      await dispatch(
        updateInventory(inventory.id, {
          ...inventory,
          status: status === "Pending" ? "Drop off pending" : status,
        })
      );
    }
    message.success("Status changed successfully");
    reloadInventories();
  };

  const { subLocations, subLocationsLoading, updatedSubLocationLoading } =
    useAppSelector((state) => state.SubLocationReducer);

  useEffect(() => {
    dispatch(getSubLocations());
  }, []);

  useEffect(() => {
    if (selectedRowKeys && (bulkAcceptModal || bulkAcceptRequestedModal)) {
      setSelectedInventories(
        paginatedInventories.rows.filter((inventory) =>
          selectedRowKeys.includes(inventory.id)
        )
      );
    }
  }, [bulkAcceptModal, bulkAcceptRequestedModal]);

  useEffect(() => {
    let data;
    if (selectedLocation) {
      data = subLocations.filter(
        (item: any) => item.locationName == selectedLocation
      );
      setFilteredSubLocations(data);
    }
  }, [selectedLocation]);

  useEffect(() => {
    setBulkAcceptModal(false);
    setBulkAcceptRequestedModal(false);
    setSelectedInventories([]);
    setSelectedRowKeys([]);
  }, [acceptInventoryLoading, acceptRequestedInventoryLoading]);
  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Inventory")
  ) {
    return <Redirect to="/" />;
  }
  if (!inventoryFilterOptions) return <Spin />;

  //should print label + send print: "true" to backend + getInventories to get updated list
  const bulkPrint = async (selectedInventories: Inventory[]) => {
    //msg updating inventories...
    message.warning(
      "Updating inventories, this may take some time, don't refresh!"
    );

    await printLabel(selectedInventories || paginatedInventories.rows, store!);
    await dispatch(bulkPrintInventory(selectedInventories));
    await dispatch(
      getPaginatedInventories(
        search,
        filters,
        currentPage,
        pageSize,
        props.type === InventoryPageTypes.Find ? tableNestedView : false,
        tableNestedView
      )
    );
    setSelectedRowKeys([]);
  };

  const handleCreateOrder = async () => {
    setIsCreateOrder(true);
  };

  const handleShareInventory = async () => {
    history.push(`sharedInventory/${store.tenantId}/${store.id}`);
  };

  const handleCopyClick = () => {
    const text = `${process.env.REACT_APP_APP_URL}/sharedInventory/${store.tenantId}/${store.id}`;
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success("Text copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy text:", err);
      });
  };

  const handleAddProduct = async () => {
    history.push("/addinventory");
  };

  const updateSelectedInventoryPrice = (price: string) => {
    if (!selectedInventory) return;
    setSelectedInventory({
      ...selectedInventory,
      requestedPrice: Number(price),
    });
  };

  const buttonFunction = () => {
    defaultStatus == "Active"
      ? bulkPrint(selectedInventories)
      : defaultStatus == ""
      ? bulkPrint(selectedInventories)
      : defaultStatus == "Pending"
      ? setBulkAcceptModal(true)
      : defaultStatus == "Requested"
      ? setBulkAcceptRequestedModal(true)
      : "";
  };

  const searchProducts = (value: string) => {
    setSearch(value);
  };
  /**
   * Export inventories to csv
   * Maps through Inventories and creates a csv file
   * @returns {void}
   */

  const exportInventoryToCsv = () => {
    const columnTitles = [
      "Inventory ID",
      // "Product Image",
      "Product Title",
      "SKU",
      "Option1Value",
      "Option2Value",
      "Option3Value",
      "Status",
      "Date",
      "Price",
      "Total Payout",
    ];

    paginatedInventories.rows.sort((a, b) => a.productId - b.productId);
    const csvData = paginatedInventories.rows.map((inventory) => [
      `"${inventory.code}"`,
      // `"${inventory.product.image}"`,
      `"${inventory.product.title}"`,
      `"${inventory.product.sku}"`,
      `"${inventory.option1Value}"`,
      `"${inventory.option2Value}"`,
      inventory.option3Value && typeof inventory.option3Value !== "undefined"
        ? `"${inventory.option3Value}"`
        : "",
      `"${inventory.status}"`,
      `"${inventory.acceptedOn}"`,
      `"${inventory.price}"`,
      `"${inventory.payoutAmount}"`,
    ]);

    csvData.unshift(columnTitles);
    const csvContent =
      // "data:text/csv;charset=utf-8," +
      csvData.map((row) => row.join(",")).join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "inventory.csv");

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    message.success("Inventory exported successfully", 3);
  };
  const showModal = () => {
    setIsCreateOrder(false);
  };
  const showShareInventory = () => {
    setIsShareInventory(false);
  };
  const showProductModal = () => {
    setAddInventoryModal(false);
  };

  return (
    <>
      <CreateOrderModale
        isModalVisible={isCreateOrder}
        setIsModalVisible={showModal}
      />
      {/* <ShareInventoryModale
        isModalVisible={isShareInventory}
        setIsModalVisible={showShareInventory}
      /> */}
      <AddInventoryModal
        isModalVisible={addInventoryModal}
        setIsModalVisible={showProductModal}
      />
      <PageContainer className="AdminInventoryPage">
        <CustomModal
          visible={showFilterModal}
          closable
          onCancel={() => setShowFilterModal(false)}
          width={"100%"}
          footer={null}
          closeIcon
        >
          <div className="header">
            <b>Filters</b>
            <Button
              onClick={() => setShowFilterModal(false)}
              icon={<CloseOutlined />}
              type="ghost"
            />
          </div>
          <Card bordered={false}>
            <InventoryFilter
              onFilter={(values) => setFilters(values)}
              formOptions={inventoryFilterOptions}
              showConsignerFilter
              showStatusFilter={props.type === InventoryPageTypes.Find}
              currentFilters={filters}
              subLocations={subLocations}
              option1Values={inventoryFilterOptions.option1Values}
              option2Values={inventoryFilterOptions.option2Values}
              option3Values={inventoryFilterOptions.option3Values}
            />
          </Card>
          <span className="footer">
            <Button type="primary" onClick={() => setShowFilterModal(false)}>
              Apply
            </Button>
          </span>
        </CustomModal>

        <MobileCustomSpan className="filt">
          <b>{getPageTitle(props.type)}</b>
          <span>
            <StyledButton
              type="default"
              style={{ width: "auto", flex: 0, padding: 0, paddingLeft: 8 }}
            >
              Show
              <Select
                style={{ flex: 0 }}
                bordered={false}
                value={pageSize}
                onChange={(size) => {
                  setPageSize(size);
                  /* dispatch(
                  getPaginatedInventories(
                    search,
                    filters,
                    currentPage,
                    size,
                    props.type === InventoryPageTypes.Find
                      ? tableNestedView
                      : false,
                    tableNestedView
                  )
                ); */
                }}
                placeholder="Show"
              >
                {[10, 20, 50, 100, 250, 500].map((sz) => (
                  <Option key={sz} value={sz}>
                    {sz}
                  </Option>
                ))}
              </Select>
            </StyledButton>
          </span>
        </MobileCustomSpan>

        <MobileCustomSpan>
          <StyledButton
            type="ghost"
            style={{
              border: "1px solid rgba(10, 10, 10, 1)",
              color: "black",
            }}
            onClick={() => handleCreateOrder()}
          >
            +Create Order
          </StyledButton>
          <StyledButton
            type="ghost"
            onClick={() => handleAddProduct()}
            style={{ background: "black", color: "white" }}
          >
            + Add Product
          </StyledButton>
        </MobileCustomSpan>

        <MobileCustomSpan>
          <SrchBx
            value={search}
            onChange={(e) => setSearch(e)}
            placeholder={Content.PRODUCT_SCREEN_SEARCH_PLACEHOLDER}
          />
          <StyledButton
            icon={<SettingOutlined />}
            onClick={() => setShowFilterModal(true)}
            type="ghost"
            style={{ position: "relative", top: "-4px" }}
          />
        </MobileCustomSpan>

        <MobileCustomSpan>
          {defaultStatus !== "Pending" && (
            <StyledButton
              type="ghost"
              onClick={buttonFunction}
              hidden={defaultStatus == "Withdrawn" ? true : false}
              disabled={selectedRowKeys.length < 1}
            >
              {defaultStatus == "Active"
                ? "Print page items"
                : defaultStatus == "Pending" || defaultStatus === "Requested"
                ? "Bulk Accept"
                : defaultStatus == ""
                ? "Re-print items"
                : ""}
            </StyledButton>
          )}
        </MobileCustomSpan>

        <CustomSpan>
          <span style={{ justifyContent: "flex-end", gap: 8 }}>
            <SearchDiv>
              <SrchBx
                value={search}
                onChange={(e) => setSearch(e)}
                placeholder={Content.PRODUCT_SCREEN_SEARCH_PLACEHOLDER}
              />
            </SearchDiv>
            {/* <StyledButton
              type="default"
              style={{ width: "auto", flex: 0, padding: 0, paddingLeft: 8 }}
            >
              Show
              <Select
                style={{ flex: 0 }}
                bordered={false}
                value={pageSize}
                onChange={(size) => {
                  setPageSize(size);
                }}
                placeholder="Show"
              >
                {[10, 20, 50, 100, 250, 500].map((sz) => (
                  <Option key={sz} value={sz}>
                    {sz}
                  </Option>
                ))}
              </Select>
            </StyledButton> */}
            <CustomFormItem name="status">
              {/* <Select
                showSearch
                mode="multiple"
                allowClear
                placeholder="Status"
                data-testid="statuses"
                value={filters?.status}
                onChange={(status) => setFilters({ ...filters, status })}
                style={{ borderRadius: "4px" }}
              >
                {inventoryFilterOptions.statuses &&
                  inventoryFilterOptions.statuses.map((statuses) => (
                    <Select.Option key={statuses.label} value={statuses.value}>
                      {statuses.label}
                    </Select.Option>
                  ))}
              </Select> */}
              <ButtonContainer>
                <button
                  className={`filter-button ${
                    filters.status === "Active" ? "checked" : ""
                  }`}
                  onClick={() => setFilters({ ...filters, status: "Active" })}
                >
                  Active
                </button>
                <button
                  className={`filter-button ${
                    filters.status === "Drop off pending" ? "checked" : ""
                  }`}
                  onClick={() =>
                    setFilters({ ...filters, status: "Drop off pending" })
                  }
                >
                  Incoming
                </button>
                <button
                  className={`filter-button ${
                    filters.status === "Sold" ? "checked" : ""
                  }`}
                  onClick={() => setFilters({ ...filters, status: "Sold" })}
                >
                  Sold
                </button>
              </ButtonContainer>
            </CustomFormItem>
          </span>
          <span style={{ justifyContent: "flex-end", gap: 8 }}>
            <StyledButton
              type="ghost"
              onClick={() => handleSelectAll(selectedRowKeys.length < 1)}
              hidden={
                defaultStatus == "Active"
                  ? false
                  : defaultStatus == "Requested"
                  ? false
                  : defaultStatus == "Pending"
                  ? false
                  : defaultStatus == ""
                  ? false
                  : true
              }
            >
              <Checkbox checked={selectedRowKeys.length > 0} />
              {selectedRowKeys.length > 0 ? "Deselect Page" : "Select All"}
            </StyledButton>

            <StyledButton
              type="ghost"
              onClick={() => {
                if (editInventory && selectedRowKeys.length > 0) {
                  setEditInventory(!editInventory), setSelectedRowKeys([]);
                } else {
                  setEditInventory(!editInventory);
                }
              }}
              hidden={props.type !== InventoryPageTypes.Find}
              disabled={!editInventory && selectedRowKeys.length < 1}
            >
              {editInventory && selectedRowKeys.length > 0
                ? "Close Editor"
                : "Bulk Edit"}
            </StyledButton>
            {/* {defaultStatus !== "Pending" && (
              <StyledButton
                type="ghost"
                onClick={buttonFunction}
                hidden={defaultStatus == "Withdrawn" ? true : false}
                disabled={selectedRowKeys.length < 1}
              >
                {defaultStatus == "Active"
                  ? "Print page items"
                  : defaultStatus == "Pending" || defaultStatus === "Requested"
                  ? "Bulk Accept"
                  : defaultStatus == ""
                  ? "Re-print items"
                  : ""}
              </StyledButton>
            )} */}
            <StyledButton
              type="ghost"
              onClick={() => dispatch(exportInventory(search, filters))}
            >
              {Content.FIND_INVENTORY_ACTION_BUTTON_TWO}
            </StyledButton>
            <StyledButton
              type="ghost"
              style={{
                border: "1px solid rgba(10, 10, 10, 1)",
                color: "black",
              }}
              onClick={() => handleCreateOrder()}
            >
              +Create Order
            </StyledButton>
            <StyledButton
              type="ghost"
              onClick={() => handleAddProduct()}
              style={{ background: "black", color: "white" }}
            >
              + Add Product
            </StyledButton>
            <StyledButton
              type="ghost"
              style={{
                border: "1px solid rgba(10, 10, 10, 1)",
                color: "black",
              }}
              onClick={() => handleCopyClick()}
            >
              <ShareAltOutlined />
            </StyledButton>
            <CustomInventoryFilterContainer
              style={{ flex: "0 0 auto", marginLeft: 16, gap: 8 }}
            >
              <Button
                icon={<UnorderedListOutlined />}
                type={tableNestedView ? "default" : "primary"}
                style={{
                  background: tableNestedView
                    ? "white"
                    : "rgba(221, 221, 221, 1)",
                }}
                onClick={() => setTableNestedView(false)}
              ></Button>
              <Button
                icon={<GroupOutlined />}
                type={tableNestedView ? "primary" : "default"}
                style={{
                  background: tableNestedView
                    ? "rgba(221, 221, 221, 1)"
                    : "white",
                }}
                onClick={() => setTableNestedView(true)}
              ></Button>
            </CustomInventoryFilterContainer>
          </span>
        </CustomSpan>

        {editInventory && selectedInventories?.length > 0 ? (
          <InventoryEditTable
            tableData={selectedInventories}
            inventoryStatuses={inventoryFilterOptions.statuses}
            locations={inventoryFilterOptions.locations}
            subLocations={subLocations}
            safeInventoryStatusMode={store?.safeInventoryStatusMode}
          />
        ) : (
          <>
            <InventoryTable
              inventoryList={paginatedInventories.rows}
              inventoryLoading={
                inventoriesLoading ||
                acceptInventoryPriceLoading ||
                declineInventoryPriceLoading ||
                acceptInventoryWithdrawalLoading ||
                declineInventoryWithdrawalLoading ||
                acceptInventoryLoading ||
                declineInventoryLoading ||
                bulkPrintInventoryLoading ||
                exportInventoryLoading
              }
              type={props.type}
              nestedView={tableNestedView}
              filters={filters}
              search={search}
              dispatch={dispatch}
              page={currentPage}
              pageSize={pageSize}
              setShowAcceptInventoryModal={setShowAcceptInventoryModal}
              setSelectedInventory={setSelectedInventory}
              setSelectedRowKeys={setSelectedRowKeys}
              setShowRequestedInventoryModal={setShowRequestedInventoryModal}
              setShowRequestPriceChangeModal={setShowRequestPriceChangeModal}
              handleSelect={handleSelect}
              handleSelectAll={handleSelectAll}
              selectedRowKeys={selectedRowKeys}
              setBulkAcceptModal={setBulkAcceptModal}
              reloadInventories={reloadInventories}
              setBulkAcceptRequestedModal={setBulkAcceptRequestedModal}
              handleStatusChange={handleStatusChange}
            />
            <StyledPagination
              current={currentPage}
              total={paginatedInventories.count}
              pageSize={pageSize}
              onChange={(page, pageSize) => {
                // setTableNestedView(false);
                setCurrentPage(page);
                setPageSize(pageSize);
                /* dispatch(
                getPaginatedInventories(
                  search,
                  filters,
                  page,
                  pageSize,
                  
                  props.type === InventoryPageTypes.Find
                    ? tableNestedView
                    : false,
                  tableNestedView
                )
              ); */
              }}
              pageSizeOptions={[10, 20, 50, 100, 250, 500]}
              // showTotal={(total, range) =>
              //   `${range[0]}-${range[1]} of ${total} items`
              // }
              defaultPageSize={10}
              showSizeChanger={true} // allows user to change number of items per page
              style={{
                paddingTop: 50,
                paddingBottom: 28,
              }}
            />
          </>
        )}
        <Modal
          title="Accept Inventory"
          visible={showAcceptInventoryModal}
          okButtonProps={{ loading: acceptInventoryLoading }}
          onOk={() => {
            selectedInventory
              ? acceptPendingInventory(
                  search,
                  filters,
                  {
                    ...selectedInventory,
                    subLocation: selectedSubLocation,
                    paymentType,
                  },
                  currentPage,
                  pageSize,
                  dispatch,
                  setShowAcceptInventoryModal
                )
              : bulkAcceptModal === true
              ? bulkAcceptPendingInventory(
                  search,
                  filters,
                  selectedInventories,
                  selectedSubLocation,
                  dispatch,
                  setBulkAcceptModal
                )
              : setShowAcceptInventoryModal(false);
            reloadInventories();
          }}
          onCancel={() => setShowAcceptInventoryModal(false)}
        >
          <Select
            allowClear
            placeholder="Select sublocation"
            showSearch
            style={{ width: 200 }}
            value={selectedSubLocation}
            onChange={(value: string) => setSelectedSubLocation(value)}
          >
            {filteredSubLocations &&
              filteredSubLocations.length > 0 &&
              filteredSubLocations.map((sublocation: any) => (
                <Select.Option key={sublocation.name} value={sublocation.name}>
                  {sublocation.name}
                </Select.Option>
              ))}
          </Select>
        </Modal>
        <Modal
          title="Bulk Accept Inventory"
          visible={bulkAcceptModal}
          okButtonProps={{ loading: acceptInventoryLoading }}
          onOk={() => {
            bulkAcceptPendingInventory(
              search,
              filters,
              selectedInventories,
              selectedSubLocation,
              dispatch,
              setBulkAcceptModal
            );
            reloadInventories();
          }}
          onCancel={() => setBulkAcceptModal(false)}
        >
          <Select
            allowClear
            placeholder="Select sublocation"
            showSearch
            style={{ width: 200 }}
            value={selectedSubLocation}
            onChange={(value: string) => setSelectedSubLocation(value)}
          >
            {filteredSubLocations &&
              filteredSubLocations.length > 0 &&
              filteredSubLocations.map((sublocation: any) => (
                <Select.Option key={sublocation.name} value={sublocation.name}>
                  {sublocation.name}
                </Select.Option>
              ))}
          </Select>
          {Number(selectedInventory?.buyingId) ? (
            <Select
              placeholder="Select payment type"
              style={{ width: 200 }}
              value={paymentType}
              onChange={(value: string) => setPaymentType(value)}
            >
              <Select.Option key="cash" value="cash">
                Cash
              </Select.Option>
              <Select.Option key="auto" value="auto">
                Checkbooks
              </Select.Option>
            </Select>
          ) : (
            ""
          )}
        </Modal>
        <Modal
          title="Accept Requested"
          visible={showRequestedInventoryModal}
          okButtonProps={{ loading: acceptRequestedInventoryLoading }}
          onOk={() =>
            acceptRequestedInventory(
              search,
              filters,
              {
                ...selectedInventory,
                subLocation: selectedSubLocation,
              },
              currentPage,
              pageSize,
              dispatch,
              setShowRequestedInventoryModal
            )
          }
          onCancel={() => setShowRequestedInventoryModal(false)}
        >
          <Select
            allowClear
            placeholder="Select sublocation"
            showSearch
            style={{ width: 200 }}
            value={selectedSubLocation}
            onChange={(value: string) => setSelectedSubLocation(value)}
          >
            {filteredSubLocations &&
              filteredSubLocations.length > 0 &&
              filteredSubLocations.map((sublocation: any) => (
                <Select.Option key={sublocation.name} value={sublocation.name}>
                  {sublocation.name}
                </Select.Option>
              ))}
          </Select>
        </Modal>
        <Modal
          title="Bulk Accept Requested"
          visible={bulkAcceptRequestedModal}
          okButtonProps={{ loading: acceptRequestedInventoryLoading }}
          onOk={() => {
            bulkAcceptRequestednventory(
              search,
              filters,
              selectedInventories,
              selectedSubLocation,
              currentPage,
              pageSize,
              dispatch,
              setBulkAcceptRequestedModal
            );
            reloadInventories();
          }}
          onCancel={() => setBulkAcceptRequestedModal(false)}
        >
          <Select
            allowClear
            placeholder="Select sublocation"
            showSearch
            style={{ width: 200 }}
            value={selectedSubLocation}
            onChange={(value: string) => setSelectedSubLocation(value)}
          >
            {filteredSubLocations &&
              filteredSubLocations.length > 0 &&
              filteredSubLocations.map((sublocation: any) => (
                <Select.Option key={sublocation.name} value={sublocation.name}>
                  {sublocation.name}
                </Select.Option>
              ))}
          </Select>
        </Modal>
        <Modal
          title="Request Price Change"
          visible={showRequestPriceChangeModal}
          okButtonProps={{ loading: requestInventoryPriceChangeLoading }}
          onOk={() =>
            selectedInventory
              ? handleStoreRequestInventoryPriceChange(
                  search,
                  filters,
                  selectedInventory,
                  currentPage,
                  pageSize,
                  dispatch,
                  setShowRequestPriceChangeModal,
                  reloadInventories
                )
              : setShowRequestPriceChangeModal(false)
          }
          onCancel={() => setShowRequestPriceChangeModal(false)}
        >
          <Input
            type="number"
            value={
              selectedInventory?.requestedPrice ?? selectedInventory?.price
            }
            onChange={(ev) => updateSelectedInventoryPrice(ev.target.value)}
          ></Input>
        </Modal>
      </PageContainer>
    </>
  );
};

export default FindInventory;
