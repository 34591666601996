import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SearchDiv } from "../Inventory/inventoryStyle";
import { SrchBx } from "../Inventory";
import Content from "../../constants/Content";
import { useAppSelector } from "../../redux/hooks";
import Orders from "./Orders";
import Buying from "./Buying";
import { UserState } from "../../redux/reducers/UserReducer";
import { getOrdersPayoutInventoriesFromAPI } from "../../redux/actions/inventoryActions";
import { Inventory } from "../../redux/reducers/InventoryReducer";
import { Col, Row } from "antd";

interface Order {
  id: number;
  name: string;
  orderDate: string;
  customer: string | null;
  shopifyId: string | null;
  buyerName: string;
  sellerName: string | null;
  sellerEmail: string | null;
  sellerPhone: string | null;
  shippingCost: string;
  shippingMethod: string;
  paymentDetails: string | null;
  total: string;
  consignerId: string | null;
  storeId: number;
  createdAt: string;
  updatedAt: string;
  lineItems: LineItem[];
}

interface LineItem {
  id: number;
  code: string;
  location: string | null;
  packingSlip: string;
  requestedPrice: string | null;
  subLocation: string | null;
  status: string;
  shopifyId: string | null;
  isWithdrawnEarly: boolean | null;
  acceptedOn: string | null;
  soldOn: string | null;
  withdrawalFee: string | null;
  negativeBalance: string | null;
  ffData: string | null;
  option1Value: string;
  option2Value: string;
  option3Value: string | null;
  payoutAmount: string;
  price: string;
  printed: string;
  depreciatedId: string | null;
  chequeId: any[];
  notes: string | null;
  discount: string | null;
  lastUpdatedBy: {
    id: number;
    name: string;
  };
  cost: string;
  shopifyLineItemId: string | null;
  payoutFee: string;
  subtotalPayout: string;
  storeRequestPriceRange: any[];
  buyingId: string | null;
  taxable: boolean;
  Seller: string;
  shippingCost: string;
  quantity: string;
  consignerId: number;
  orderId: number;
  payoutId: string | null;
  productId: number;
  productTemplateId: number;
  storeId: number;
  invoiceId: number;
  createdAt: string;
  updatedAt: string;
  consigner: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string | null;
    address: string | null;
    idNumber: string | null;
    accountName: string | null;
    accountNumber: string | null;
    bank: string | null;
    bankType: string | null;
    branchCode: string | null;
    fbUserId: string;
    isStoreAccount: boolean;
    negativeBalance: string | null;
    ffData: string | null;
    payoutData: string | null;
    payoutType: string | null;
    accountType: string | null;
    isActive: boolean;
    depreciatedId: string | null;
    acceptedTOS: string | null;
    phoneZelle: string | null;
    emailNotifications: {
      onPayout: boolean;
      onProductSold: boolean;
      onInventoryAccept: boolean;
      onPriceAcceptOrDecline: boolean;
      onWithdrawAcceptOrDecline: boolean;
    };
    bankData: {
      bank: string;
      email: string;
      bankType: string;
      swiftCode: string;
      ibanNumber: string;
      accountName: string;
      routingType: string;
      accountNumber: string;
      routingNumber: string;
    };
    chargeTax: boolean;
    consignerTypeId: number;
    storeId: number;
    createdAt: string;
    updatedAt: string;
  };
  product: {
    id: number;
    title: string;
    price: string | null;
    image: string;
    sku: string;
    brand: string;
    category: string;
    lastSale: string | null;
    currentLowest: string | null;
    stockXId: string;
    stockXHandle: string;
    color: string;
    desc: string;
    gender: string;
    shopifyId: string | null;
    ffData: string | null;
    depreciatedId: string | null;
    handle: string | null;
    GTIN: any[];
    productTemplateId: number;
    storeId: number;
    createdAt: string;
    updatedAt: string;
  };
}

interface ViewOrderModaleProps {
  isModaleVisible: boolean;
  setIsModaleVisible: () => void;
  orderDetails: Order | null;
}

interface TabProps {
  active?: boolean;
}

const MainDiv = styled.div`
  background-color: #f0f0f0;
  height: 100vh;
  padding: 0.5em;
`;

const HeadDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LeftDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const TabsContainer = styled.div`
  display: flex;
  justify-content: start;
  border-bottom: 1px solid #ccc;
  margin-top: 10px;
  gap: 20px;
`;

const Tab = styled.div<TabProps>`
  padding: 10px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  color: ${(props) => (props.active ? "black" : "#000")};
  border-bottom: ${(props) => (props.active ? "2px solid black" : "none")};
`;

const Card = styled.div`
  width: 300px;
  height: 100px;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0);
  position: relative;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CardTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  margin-left: 5px;
`;

const CardPrice = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-size: 20px;
  margin-left: 5px;
`;

const DropdownContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const Dropdown = styled.select`
  padding: 5px;
  border-radius: 4px;
  border: none;
`;

// Main component
export default function Index() {
  const [activeTab, setActiveTab] = useState("Orders");
  const [search, setSearch] = useState("");
  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);
  const [inventories, setInventories] = useState<Inventory[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState<any>({
    status: "",
    option1Value: undefined,
    option2Value: undefined,
    option3Value: undefined,
    category: undefined,
    consigner:
      dbUser && dbUser.id && process.env.REACT_APP_TYPE === "consigner"
        ? dbUser.id
        : undefined,
  });

  // Function to fetch data from API
  const fetchOrdersData = async () => {
    setLoading(true);
    try {
      const data = await getOrdersPayoutInventoriesFromAPI(
        search,
        filters,
        currentPage,
        pageSize
      );
      setInventories(data.inventories);
      setTotal(data.total);

      const total = data.inventories.reduce((sum, inventory) => {
        return sum + parseFloat(inventory.price);
      }, 0);

      setTotalPrice(total);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrdersData();
  }, [search, filters, currentPage, pageSize]);

  const order = inventories.forEach((inventory, index) => {});

  useEffect(() => {}, []);

  const handleInputChange = (value: string) => {
    setSearch(value); // Update the search state with the input value
  };

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <MainDiv>
        <HeadDiv>
          <LeftDiv>
            <SearchDiv style={{ width: "400px" }}>
              <SrchBx
                value={search}
                onChange={handleInputChange}
                placeholder={Content.PRODUCT_SCREEN_SEARCH_PLACEHOLDER}
              />
            </SearchDiv>
            <TabsContainer>
              <Tab
                active={activeTab === "Orders"}
                onClick={() => handleTabClick("Orders")}
              >
                Orders
              </Tab>
              <Tab
                active={activeTab === "Buying"}
                onClick={() => handleTabClick("Buying")}
              >
                Buying
              </Tab>
            </TabsContainer>
          </LeftDiv>
          <Card>
            <DropdownContainer>
              <Dropdown>
                <option value="january">January</option>
                <option value="february">February</option>
                <option value="march">March</option>
                <option value="april">April</option>
              </Dropdown>
            </DropdownContainer>
            <CardTitle>TOTAL PRICE</CardTitle>
            <CardPrice>${totalPrice.toFixed(2)}</CardPrice>
          </Card>
        </HeadDiv>

        <div>
          {activeTab === "Orders" ? (
            <Orders loading={loading} order={inventories} search={search} />
          ) : activeTab === "Buying" ? (
            <Buying loading={loading} order={inventories} search={search} />
          ) : null}
        </div>
      </MainDiv>
    </>
  );
}
