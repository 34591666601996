import React, { useState } from "react";
import { CustomModal } from "./inventoryStyle";
import { Button, Row, Col } from "antd";
import dummyImage from "../../assets/images/Image.png";
import AddImagesIcon from "../../assets/images/svg/AddImagesIcon.svg";
interface AddInventoryModalProp {
  isModalVisible: boolean;
  setIsModalVisible: () => void;
}
export default function AddImages({
  isModalVisible,
  setIsModalVisible,
}: AddInventoryModalProp) {
  const handleCancel = () => {
    setIsModalVisible();
  };
  const [images, setImages] = useState<string[]>([]);

  const handleFileChange = (event) => {
    if (images.length >= 5) {
      alert("You can't add more than 5 images");
    } else {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImages([...images, reader.result as string]);
        };
        reader.readAsDataURL(file);
      }
    }
  };
  const handleAddImages = () => {};
  return (
    <>
      <CustomModal
        title="Product Images"
        visible={isModalVisible}
        onCancel={() => handleCancel()}
        width={800}
        footer={[
          // eslint-disable-next-line react/jsx-key
          <div style={{ display: "flex" }}>
            <Button
              key="back"
              type="primary"
              style={{
                padding: 8,
                width: 90,
                height: 52,
                borderRadius: "8px",
                border: "1px solid #DDDDDD",
                color: "black",
                background: "#F4F4F4",
              }}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              style={{
                padding: "8px 10px",
                height: 52,
                borderRadius: "8px",
                background: "black",
                right: "2%",
                position: "absolute",
              }}
              onClick={() => handleAddImages()}
            >
              Save Images
            </Button>
          </div>,
        ]}
      >
        <div>
          <p style={{ color: "gray" }}>
            You can upload multiple images for your product
          </p>
        </div>
        <div style={{ marginBottom: "0px" }}>
          <Row gutter={20}>
            {images.length > 0 && (
              <Col span={12}>
                <img
                  src={images[0] ?? ""}
                  alt="Image 1"
                  style={{ width: "100%", height: "auto" }}
                />
              </Col>
            )}
            <Col span={12}>
              {images.length > 1 && (
                <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                  <Row gutter={20} style={{ display: "inline-flex" }}>
                    {images.slice(1).map((image, index) => (
                      <Col
                        span={12}
                        key={index + 1}
                        style={{ flex: "0 0 auto" }}
                      >
                        <img
                          src={image}
                          alt={`Image ${index + 2}`}
                          style={{ width: "100%", height: "auto" }}
                        />
                      </Col>
                    ))}
                  </Row>
                </div>
              )}

              <Row
                gutter={10}
                style={{
                  marginTop: "20px",
                }}
              >
                <Col span={24}>
                  <label htmlFor="file-input">
                    <img
                      src={AddImagesIcon}
                      alt="Add Product"
                      style={{
                        width: "100%",
                        height: "136px",
                        cursor: "pointer",
                      }}
                    />
                  </label>
                  <input
                    id="file-input"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </CustomModal>
    </>
  );
}
