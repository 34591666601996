import { DraftOrder, Order } from "../reducers/OrderReducer";
import { AppDispatch } from "../store";
import {
  CREATE_ORDER,
  EXPORT_ORDERS,
  GET_ORDER,
  GET_ORDERS,
  GET_ORDERS_INVENTORY,
  RESYNC_ALL_ORDERS,
  RESYNC_ORDER,
} from "./types";
import { FormValues as OrderFilterValues } from "../../components/Common/OrderFilter";
import { api } from "../../api";

const getOrdersLoading = () => ({
  type: GET_ORDERS,
  orders: [],
  ordersLoading: true,
});

const getOrdersError = () => ({
  type: GET_ORDERS,
  orders: [],
  ordersLoading: false,
});

const getOrdersSuccess = (orders: Order[]) => ({
  type: GET_ORDERS,
  orders,
  ordersLoading: false,
});

const getOrderLoading = () => ({
  type: GET_ORDER,
  order: [],
  orderLoading: true,
});

const getOrderError = () => ({
  type: GET_ORDER,
  order: [],
  orderLoading: false,
});

const getOrderSuccess = (order: Order) => ({
  type: GET_ORDER,
  order,
  orderLoading: false,
});

const exportOrdersLoading = () => ({
  type: EXPORT_ORDERS,
  exportedOrders: [],
  exportOrderLoading: true,
});

const exportOrdersError = () => ({
  type: EXPORT_ORDERS,
  exportedOrders: [],
  exportOrderLoading: false,
});

const exportOrdersSuccess = () => ({
  type: EXPORT_ORDERS,
});

const resyncAllOrdersLoading = () => ({
  type: RESYNC_ALL_ORDERS,
  orders: [],
  ordersLoading: true,
});

const resyncAllOrdersError = () => ({
  type: RESYNC_ALL_ORDERS,
  orders: [],
  ordersLoading: false,
});

const resyncAllOrdersSuccess = (orders: Order[]) => ({
  type: RESYNC_ALL_ORDERS,
  orders,
  ordersLoading: false,
});

const resyncOrderLoading = () => ({
  type: RESYNC_ORDER,
  resyncingOrderLoading: true,
});
const createOrderLoading = () => ({
  type: CREATE_ORDER,
  createOrderLoading: true,
});

const resyncOrderError = () => ({
  type: RESYNC_ORDER,
  resyncingOrderLoading: false,
});
const createOrderError = () => ({
  type: CREATE_ORDER,
  OrderLoading: false,
});

const resyncOrderSuccess = () => ({
  type: RESYNC_ORDER,
  resyncingOrderLoading: false,
});

const createOrderSuccess = () => ({
  type: CREATE_ORDER,
  OrderLoading: false,
});

//api
const resyncAllOrdersFromAPI = async (
  search: string,
  filters: OrderFilterValues
) => {
  const { data, error } = (await api.provide("post", "/api/orders/resync", {
    search,
    consigner: filters.consigner,
  })) as { data: { orders: Order[] }; error: any };
  if (error) throw error;
  return (data?.orders || []) as any;
};

const resyncOrderFromAPI = async (orderId: string) => {
  const { data, error } = (await api.provide("get", "/api/orders/resync/:id", {
    id: orderId,
  })) as { data: { order: Order }; error: any };
  if (error) throw error;
  return (data?.order || []) as any;
};

const getOrdersFromAPI = async (search: string, filters: OrderFilterValues) => {
  const { consigner, status, orderDateRange, isSeller } = filters;
  const params: any = {
    search,
    consigner,
    status,
    dateRangeStart: orderDateRange[0],
    dateRangeEnd: orderDateRange[1],
    isSeller,
  };
  const { data, error } = await api.provide("get", "/api/orders", params);
  if (error) throw error;
  return (data?.orders || []) as any;
};

const createOrdersFromAPI = async (ordersData: any) => {
  const { data, error } = (await api.provide(
    "post",
    "/api/orders/createOrder",
    ordersData
  )) as { data: { orders: any }; error: any };
  if (error) throw error;
  return (data || []) as any;
};

const getOrderFromAPI = async (id: string) => {
  const { data, error } = await api.provide("get", "/api/orders/:id", { id });
  if (error) throw error;
  return data;
};

const exportOrderFromAPI = async (orders: Order[]): Promise<void> => {
  const { data, error } = await api.provide("post", "/api/orders/export", {
    orders,
  });
  if (error) throw error;
  const pom = document.createElement("a");
  const blob = new Blob([data.result], { type: "text/csv;charset=utf-8;" });
  pom.href = URL.createObjectURL(blob);
  pom.setAttribute("download", "ordersExport.csv");
  pom.click();
};

// export const createDraftOrderFromAPI = async (order: DraftOrder) => {
//   const { data, error } = await api.provide(
//     "post",
//     "/api/orders/createDraftOrder",
//     { draftOrder: order }
//   );
//   if (error) throw error;
//   return data;
// };
export const createDraftOrderFromAPI = (order: DraftOrder) => {
  return async (dispatch: AppDispatch) => {
    try {
      const { data, error } = await api.provide(
        "post",
        "/api/orders/createDraftOrder",
        { draftOrder: order }
      );
      if (error) throw error;

      // Dispatch success action if needed
      // dispatch({ type: 'CREATE_DRAFT_ORDER_SUCCESS', payload: data });

      return data;
    } catch (error) {
      // Dispatch failure action if needed
      // dispatch({ type: 'CREATE_DRAFT_ORDER_FAILURE', payload: error });

      throw error;
    }
  };
};

//actions
export const getOrders = (search: string, filters: OrderFilterValues) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getOrdersLoading());
    try {
      dispatch(getOrdersSuccess(await getOrdersFromAPI(search, filters)));
    } catch (e) {
      dispatch(getOrdersError());
    }
  };
};

export const getOrder = (id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getOrderLoading());
    try {
      const response = await getOrderFromAPI(id);
      dispatch(getOrderSuccess(response));
      return response; // Return the response
    } catch (e) {
      dispatch(getOrderError());
      throw e; // Re-throw the error to handle it where you are dispatching
    }
  };
};

export const exportOrders = (orders: Order[]) => {
  return async (dispatch: AppDispatch) => {
    dispatch(exportOrdersLoading());
    try {
      await exportOrderFromAPI(orders);
      dispatch(exportOrdersSuccess());
    } catch (e) {
      dispatch(exportOrdersError());
    }
  };
};

export const resyncAllOrders = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(resyncAllOrdersLoading());
    try {
      dispatch(
        resyncAllOrdersSuccess(await resyncAllOrdersFromAPI("", {} as any))
      );
    } catch (e) {
      dispatch(resyncAllOrdersError());
    }
  };
};

export const resyncOrder = (orderId: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(resyncOrderLoading());
    try {
      await resyncOrderFromAPI(orderId);
      dispatch(resyncOrderSuccess());
    } catch (e) {
      dispatch(resyncOrderError());
    }
  };
};

export const createOrder = (ordersData: any) => {
  return async (dispatch: AppDispatch) => {
    dispatch(createOrderLoading());
    try {
      const response = await createOrdersFromAPI(ordersData);
      dispatch(createOrderSuccess()); // Pass the response data to the success action
      console.log("create order response", response);
      return response; // Return the response data
    } catch (e) {
      dispatch(createOrderError());
      throw e; // Re-throw the error to handle it in the caller
    }
  };
};
