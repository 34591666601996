import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Redirect, useLocation } from "react-router-dom";
import firebase from "firebase";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import {
  createStore,
  createStripePaymentFromAPI,
  getStore,
  getStoreFromEmail,
} from "../redux/actions/storeActions";
import { StoreState } from "../redux/reducers/StoreReducer";
import AuthService from "../services/auth";
import { message, Button } from "antd";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import Cookies from "js-cookie";
import logo from "../assets/images/VAULT.svg";
import Fonts from "../constants/Fonts";
import Colors from "../constants/Colors";
import RedesignStyling from "../constants/RedesignStyling";
import StoreSignupForm from "../components/Auth/StoreSignupForm";
import { loadStripe } from "@stripe/stripe-js";

/**
 * Store Signup Screen
 * renders a screen where store can signup
 * TODO Tests:
 *  -
 * @returns
 */

//styles
const Container = styled.div`
  font-family: ${Fonts.CONSIGNER};
  background: ${Colors.CONSIGNER_BG};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // height: 100vh;
  padding: ${RedesignStyling.PAGE_PADDING};
  @media (max-width: 768px) {
    padding: ${RedesignStyling.PAGE_PADDING_MOBILE};
  }
`;
const SignupContainer = styled.div`
  width: 472px;
  max-width: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`;

const TOSContainer = styled.div`
  color: #2e2e2e;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  a {
    text-decoration: underline;
    color: rgba(104, 95, 249, 1);
  }
  button {
  }
`;

const TopBarGeneric = styled.div`
  height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${Colors.WHITE};
  position: fixed;
  top: 0;
`;
const Logo = styled.img`
  height: 40px;
  width: auto;
  padding: 10px;
  margin: 10px;
`;
const Header = styled.div`
  color: var(--heading-color, #2e2e2e);
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 62px;
  letter-spacing: -2px;
  width: 100%;
`;
const Subheader = styled.div`
  color: ${Colors.CONSIGNER_GREY};
  text-align: center;
  font-family: ${Fonts.CONSIGNER};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  width: 100%;
`;

const validateEmail = (email: String) => {
  var re =
    /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
  return re.test(String(email));
};

const StoreSignup = () => {
  const dispatch = useAppDispatch();
  const { createdSuccess, createStoreLoading, store }: StoreState =
    useAppSelector((state) => state.StoreReducer);
  const { isLoggedIn, dbUser } = useAppSelector((state) => state.UserReducer);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [catalogue, setCatalogue] = useState<string>();
  const [sizing, setSizing] = useState<string>();
  const [payoutOptions, setPayoutOptions] = useState<string>("");
  const [currency, setCurrency] = useState<string>();
  const [showTOSModal, setShowTOSModal] = useState(false);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const auth = AuthService();

  useEffect(() => {
    const domain = Cookies.get("domain");
    if (domain) {
      Cookies.remove("domain");
    }
  }, []);

  // useEffect(() => {
  //   if (store && store?.domain) {
  //     message.success("Loading...");
  //     window.open(
  //       `${process.env.REACT_APP_APP_URL}/login?domain=${store.handle}.fulltrace.co&shop=${store.shop?.shop}`,
  //       "_self"
  //     );
  //   }
  // }, [store]);

  const handleSignup = async () => {
    if (
      step === 2 &&
      (sizing || currency !== "Sneakers") &&
      catalogue &&
      currency
      // payoutOptions
    ) {
      console.log(email, password, "=========email===========");
      await signupStore(
        `${firstName} ${lastName}`,
        email,
        sizing ?? "",
        catalogue,
        currency,
        password,
        payoutOptions,
        phone
      );
    } else {
      setStep(2);
    }
  };

  const signupStore = async (
    name: string,
    email: string,
    sizing: string,
    payoutOptions: string,
    catalogue: string,
    currency: string,
    password: string,
    phone: string
  ) => {
    setLoading(true);
    if (
      loading === false
      // email !== "" &&
      // validateEmail(email) &&
      // name !== "" &&
      // (sizing || catalogue !== "Sneakers") &&
      // catalogue &&
      // currency &&
      // password !== ""
    ) {
      message.loading({
        content: "Please wait while we log you in.",
        duration: 0,
        key: 1,
      });
      dispatch(
        createStore(
          name,
          email,
          payoutOptions,
          sizing,
          catalogue,
          currency,
          password,
          phone
        )
      );
    } else {
      message.error("Please enter a valid email, name and details");
      setLoading(false);
    }
  };

  const login = async (email: string, password: string) => {
    await auth.login(email, password);
  };

  const loginCreatePayment = async () => {
    await login(email, password);
    window.location.replace(process.env.REACT_APP_STRIPE_PAYMENT_URL || "");
  };

  useEffect(() => {
    console.log(store, createdSuccess, isLoggedIn, dbUser);
    if (createdSuccess && store && store.id && (!isLoggedIn || !dbUser)) {
      loginCreatePayment();
    }
    if (createdSuccess && !store) {
      dispatch(getStoreFromEmail(email));
    }

    if (isLoggedIn && dbUser) {
      message.destroy(1);
    }
    // if (createdSuccess) {
    //   loginCreatePayment();
    // }
  }, [createdSuccess]);

  // useEffect(() => {
  //   if (createdSuccess && isLoggedIn) {
  //     console.log(createdSuccess, isLoggedIn);
  //     makePaymemt();
  //   }
  //   // makePaymemt();
  // }, [createdSuccess, isLoggedIn, store]);

  return (
    <>
      <div style={{ background: "#F9FAFB" }}>
        <Logo data-testid="logo" src={logo} />
      </div>
      <Container>
        <SignupContainer>
          <FormContainer>
            <Header>Create Free Account</Header>
            <StoreSignupForm
              showPasswordInput={true}
              email={email}
              password={password}
              confirm={confirm}
              firstName={firstName}
              lastName={lastName}
              phone={phone}
              catalogue={catalogue}
              sizing={sizing}
              // payoutOptions={payoutOptions}
              currency={currency}
              step={step}
              setEmail={setEmail}
              setPassword={setPassword}
              setConfirm={setConfirm}
              setFirstName={setFirstName}
              setLastName={setLastName}
              setPhone={setPhone}
              setCatalogue={setCatalogue}
              setSizing={setSizing}
              setCurrency={setCurrency}
            />
            <TOSContainer>
              I agree with the{" "}
              <a onClick={() => setShowTOSModal(true)}>Terms & Conditions</a> of
              Vault
            </TOSContainer>
            <Button
              style={{
                display: "flex",
                width: "80%",
                height: 50,
                // padding: 16,
                justifyContent: "center",
                color: "white",
                alignItems: "center",
                background: "black",
                marginTop: 10,
                marginBottom: 10,
                textAlign: "center",
                fontSize: 16,
                fontWeight: 500,
                borderRadius: 50,
              }}
              type="primary"
              disabled={confirm !== password || password === ""}
              onClick={handleSignup}
            >
              Create Account
            </Button>
            <TOSContainer>
              Already have an account?
              <Button
                style={{
                  height: 50,
                  fontSize: 14,
                  fontWeight: 600,
                  color: "black",
                  padding: "4px",
                  textDecoration: "underline",
                }}
                type="text"
                href="/login"
              >
                Sign In
              </Button>
            </TOSContainer>
          </FormContainer>
        </SignupContainer>
        <Dialog
          open={/* store?.termsOfService && */ showTOSModal}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            Accept Terms of Service
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
              {/* store.termsOfService */}I accept to anything.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowTOSModal(false)}>OK</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
};

export default StoreSignup;
