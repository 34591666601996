import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  clearSelectedProduct,
  getProducts,
  removeSelectedProduct,
  updateSelectedProduct,
} from "../../redux/actions/productActions";
import ProductList from "./ProductList";
import { InventoryState } from "../../redux/reducers/InventoryReducer";
import Content from "../../constants/Content";
import {
  Button,
  Card,
  Checkbox,
  Form,
  Image,
  Input,
  message,
  Popconfirm,
  Radio,
  Select,
  Tag,
} from "antd";
import { SearchDiv } from "./inventoryStyle";
import { SearchBox } from "../Orders";
import {
  CheckCircleFilled,
  CloseCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import Item from "antd/lib/list/Item";
import InventoryAddTable from "./InventoryAddTable";
import { StoreState } from "../../redux/reducers/StoreReducer";
import getSymbolFromCurrency from "currency-symbol-map";
import { useForm } from "antd/lib/form/Form";
import {
  createInventories,
  createInventoriesFromAPI,
} from "../../redux/actions/inventoryActions";
import { createOrder } from "../../redux/actions/orderActions";
import { useHistory } from "react-router-dom";
import { AppState } from "../../redux/reducers/AppReducer";
import AddCustomProduct from "./AddCustomProduct";
import { createInvoice } from "../../redux/actions/invoiceActions";
import { useDispatch } from "react-redux";
const { Search } = Input;

const ProductItemCard = styled(Card)`
  display: flex;
  height: auto;
  width: 100%;
  cursor: pointer;
  border-radius: 10px;
  .ant-card-body > div:nth-child(2) {
    flex-grow: 2;
  }
  .ant-card-body {
    display: flex;
    gap: 15px;
    padding: 10px;
    justify-content: space-between;
    .inv-image {
      border: 1px solid rgba(221, 221, 221, 0.4);
      border-radius: 10px;
    }
    .ant-image {
      max-height: 100%;
      object-fit: contain;
      img {
        max-height: 100%;
      }
    }
    div {
      flex: 1;
      flex-direction: column;
      display: flex;
      justify-content: center;
    }
    label {
      color: rgba(152, 152, 152, 1);
    }
  }
`;

const Container = styled.div`
  display: flex;
  // padding: 20px;
  gap: 20px;
  .catalogue {
    flex: 0 0 30%; /* Flex-grow: 0, Flex-shrink: 0, and 30% width */
    padding: 20px;
    border-right: 1px solid rgba(221, 221, 221, 1);
  }
  .inventory {
    flex: 0 0 70%;
    padding: 20px;
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
  }
  .ant-input-group-addon {
    display: none;
  }
  @media (max-width: 768px) {
    .catalogue {
      flex: 0 0 100%;
    }
  }
`;

const TemplateSelector = styled.div`
  .ant-radio-group {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    .ant-radio-button-wrapper {
      background: rgba(244, 244, 244, 1);
      border-radius: 7px;
      align-content: center;
      border-left-width: thin;
      height: 37px;
    }
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    width: 0;
  }
  .ant-radio-button-wrapper-checked {
    border-color: black;
    color: black;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-color: black;
    color: black;
  }
  .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
    border-right-color: unset;
  }
`;

const SizeSelector = styled.div`
  .sizes {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .ant-list-item {
    border: 1px solid rgba(221, 221, 221, 1);
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  gap: 15px;
  padding: 20px 0;
  .ant-checkbox-wrapper {
    padding: 10px;
    background: rgb(249, 249, 249);
    border: 1px solid rgb(221, 221, 221);
    border-radius: 8px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgba(10, 10, 10, 1);
    border-color: rgba(10, 10, 10, 1);
    border-radius: 4px;
  }
`;

const SellerDetails = styled.div`
  display: flex;
  gap: 15px;
  border-bottom: 2px solid rgba(221, 221, 221, 0.6);
  border-top: 2px solid rgba(221, 221, 221, 0.6);
  padding-top: 12px;
  .ant-input-affix-wrapper {
    padding: 8px;
    border-radius: 8px;
    .ant-input-prefix {
      color: rgba(152, 152, 152, 1);
    }
  }
  label {
    font-weight: 500;
    margin-bottom: 6px;
  }
  .ant-input {
    padding: 8px;
    border-radius: 8px;
  }
  .ant-form-item .ant-row {
    display: block;
  }
`;

const FooterButton = styled.div`
  background: rgb(255, 255, 255);
  padding: 15px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 8px;
  button {
    background: rgb(244, 244, 244);
    height: 40px;
    border-radius: 7px;
    padding: 4px 20px;
    color: rgb(41, 45, 50);
    font-weight: 500;
  }
  .ant-btn-primary {
    background: black;
    color: white;
  }
`;

const Inventory = (props: any) => {
  const {
    selectedProducts,
    productTemplates,
    form,
    setTotalInventories,
    handleAddProduct,
    isMobile,
    inventoryList,
    setInventoryList,
  } = props;
  const [selectedTemplate, setSelectedTemplate] = useState<any>({});
  const [option1Value, setOption1Value] = useState("");
  const [createdInventory, setCreatedInventory] = useState();
  const [lowestProductPrices, setLowestProductPrices] = useState<any[]>([]);
  const [currentInventory, setCurrentInventory] = useState<any>({});
  const [addedInventories, setAddedInventories] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedProducts.length > 0) {
      setCurrentInventory(selectedProducts[0]); // Start with the first product
      setCount(0); // Reset count to 0
    }
    console.log(
      selectedProducts,
      "================selectedProducts================="
    );
    setInventoryList(selectedProducts);
  }, [selectedProducts]);

  // Update the current inventory when `addedInventories` changes
  useEffect(() => {
    // If the count is less than the total products available
    const total: any = inventoryList.flatMap((inv) => inv?.invArray);
    console.log(total, "=========total=================");
    setTotalInventories(total); // Add to total inventories
    if (count < selectedProducts.length) {
      setCount((prevCount) => prevCount + 1); // Increment the count
      setCurrentInventory(selectedProducts[count + 1]); // Set next product as current
    } else {
      setCurrentInventory({});
    }
  }, [addedInventories]);

  useEffect(() => {
    if (currentInventory?.productTemplateId) {
      setSelectedTemplate(currentInventory.productTemplateId);
    }
  }, [currentInventory]);

  const handleDelete = (key: React.Key) => {
    // const newData = selectedProducts.filter((item: any) => item.id !== key);
    dispatch(removeSelectedProduct(key, false));
    // setInventoryList(newData);
  };

  const handleTemplateChange = (e) => {
    if (!currentInventory.productTemplateId) {
      setSelectedTemplate(e.target.value);
    }
  };

  return (
    <>
      <div
        style={{
          margin: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        {inventoryList.map((inv) => (
          <>
            <ProductItemCard
              key={inv}
              bordered
              onClick={() => setCurrentInventory(inv)}
              style={{
                border:
                  currentInventory?.id === inv?.id
                    ? "1px solid black"
                    : "1px solid rgba(221, 221, 221, 0.6)",
                background:
                  currentInventory?.id === inv?.id
                    ? "white"
                    : "rgba(249, 249, 249, 1)",
              }}
            >
              <div
                className="inv-image"
                style={{ flex: "0 0 100px", padding: 5 }}
              >
                <Image src={inv?.image} />
              </div>
              <div>
                <label>Model</label>
                <b>{inv?.title || "---"}</b>
              </div>
              <div>
                <label>SKU</label>
                <b>{inv?.sku || "---"}&nbsp;</b>
              </div>
              {!isMobile && (
                <>
                  {" "}
                  <div>
                    <label>Quantity</label>
                    <b>
                      {inv?.invArray?.reduce(
                        (sum: number, item: any) => sum + item.quantity,
                        0
                      )}
                    </b>
                  </div>
                  <div>
                    <label>Total Price</label>
                    <b>
                      {inv?.invArray?.reduce(
                        (sum: number, item: any) => sum + item.price,
                        0
                      )}
                    </b>
                  </div>
                  <div>
                    <label>Total Cost</label>
                    <b>
                      {inv?.invArray?.reduce(
                        (sum: number, item: any) => sum + item.cost,
                        0
                      )}
                    </b>
                  </div>
                </>
              )}
              <div style={{ flex: 0 }}>
                <Button
                  type="text"
                  danger
                  icon={<CloseCircleOutlined />}
                  onClick={() => handleDelete(inv.id)}
                />
              </div>
            </ProductItemCard>
            {currentInventory?.id === inv?.id && (
              <>
                {!currentInventory?.productTemplateId && (
                  <>
                    <label style={{ fontWeight: 500 }}>Select Template</label>
                    <TemplateSelector>
                      <Radio.Group
                        onChange={(e: any) => handleTemplateChange(e)}
                        defaultValue={currentInventory?.productTemplateId}
                      >
                        {productTemplates.map((temp) => (
                          <Radio.Button value={temp.id} key={temp.id}>
                            {temp?.id === selectedTemplate && (
                              <CheckCircleFilled
                                style={{ paddingRight: "5px" }}
                              />
                            )}
                            {temp.title}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    </TemplateSelector>
                  </>
                )}
                <SizeSelector>
                  <h3>Select Size</h3>
                  <div className="sizes">
                    {productTemplates
                      .find((tem) => tem.id === selectedTemplate)
                      ?.option1Values?.map((size) => (
                        <Item key={size} onClick={() => setOption1Value(size)}>
                          {size}
                        </Item>
                      ))}
                  </div>
                </SizeSelector>
                <InventoryAddTable
                  inventoryList={inventoryList}
                  setInventoryList={setInventoryList}
                  option1Value={option1Value}
                  setOption1Value={setOption1Value}
                  productTemplate={productTemplates.find(
                    (tem) => tem.id === selectedTemplate
                  )}
                  setCreatedInventory={setCreatedInventory}
                  id={""}
                  lowestPrices={lowestProductPrices}
                  currentInventory={currentInventory}
                  setAddedInventories={setAddedInventories}
                  selectedProducts={selectedProducts}
                />
              </>
            )}
          </>
        ))}
      </div>
      {selectedProducts.length > 0 && (
        <>
          <Form form={form} onFinish={handleAddProduct}>
            <SellerDetails>
              <Form.Item name="sellerName" label="Seller Name">
                <Input placeholder="Seller name" />
              </Form.Item>
              <Form.Item name="shippingCost" label="Shipping Cost">
                <Input prefix={getSymbolFromCurrency(store.currency)} />
              </Form.Item>
              <Form.Item name="tax" label="Tax">
                <Input prefix={"%"} />
              </Form.Item>
            </SellerDetails>
            <CheckboxContainer>
              {/* <Form.Item name="invoice" valuePropName="checked">
                <Checkbox defaultChecked={false}>Share Invoice</Checkbox>
              </Form.Item> */}
              <Form.Item name="status" valuePropName="checked">
                <Checkbox defaultChecked={false}>Drop off pending</Checkbox>
              </Form.Item>
            </CheckboxContainer>
          </Form>
        </>
      )}
    </>
  );
};

const AddInventoryCatalogue = () => {
  const [search, setSearch] = useState("");
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const history = useHistory();
  const { isMobile }: AppState = useAppSelector((state) => state.AppReducer);
  const [mobileStep, setMobileStep] = useState(1);
  const [inventoryList, setInventoryList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [customModal, setCustomModal] = useState<boolean>(false);
  const { products, updatedProduct, productsLoading, selectedProducts } =
    useAppSelector((state) => state.ProductReducer);
  const {
    inventoriesToReview,
    inventoryFilterOptions,
    inventoryFilterOptionsLoading,
  }: InventoryState = useAppSelector((state) => state.InventoryReducer);
  const [inventoriesToAdd, setInventoriesToAdd] = useState<any[]>(
    inventoriesToReview ?? []
  );
  const [totalInventories, setTotalInventories] = useState<any[]>([]);

  const {
    productTemplate,
    productTemplates,
    productTemplateLoading,
    productTemplatesLoading,
  } = useAppSelector((state) => state.ProductTemplateReducer);

  const addToInventories = (items: any[]) => {
    setInventoriesToAdd([...inventoriesToAdd, ...items]);
  };

  useEffect(() => {
    dispatch(getProducts(search));
    //get products here any time search updates
  }, [search]);

  const searchProducts = (value: string) => {
    setSearch(value);
  };

  function generateTrackingId(): string {
    const timestamp = Date.now().toString(); // Current timestamp in milliseconds
    const randomString = Math.random().toString(36).substring(2, 10); // Random string
    return `#INV-${timestamp}-${randomString}`;
  }

  const addProductsAndOrder = async () => {
    if (selectedProducts) {
      setLoading(true);
      const values = form.getFieldsValue();
      let inventories = totalInventories;
      if (inventories.length) {
        inventories = inventories.map((inv) => ({
          ...inv,
          Seller: values.sellerName,
          shippingCost: values.shippingCost,
        }));
        if (values.status) {
          inventories = inventories.map((inv) => ({
            ...inv,
            status: "Drop off pending",
          }));
        }
        if (values.tax) {
          inventories = inventories.map((inv) => ({
            ...inv,
            cost: inv.cost + (inv.cost * Number(values.tax)) / 100,
          }));
        }
        // if (values.invoice) {
        const trackingCode = generateTrackingId();
        const { invoice } = await dispatch(
          createInvoice({
            trackingCode,
            invoiceDate: new Date().toISOString(),
            isSeller: true,
          })
        );
        inventories = inventories.map((inv) => ({
          ...inv,
          sellerInvoiceId: invoice.id,
        }));
        // }
        const createdInventories = await createInventoriesFromAPI(inventories);
        if (createdInventories) {
          const payload = {
            ordersData: createdInventories.inventory,
            sellerName: values.sellerName,
            shippingCost: values.shippingCost,
            isSeller: true,
          };
          await dispatch(createOrder(payload));
          setLoading(false);
          handleCancel();
        } else {
          setLoading(false);
          message.error("Error in creating inventories");
        }
      } else {
        setLoading(false);
        // If validation fails, display an error message
        message.error("Please enter inventory values and click Next");
      }
    }
  };

  const handleCustomModal = () => {
    setCustomModal(true);
  };

  const handleAddProduct = () => {
    if (selectedProducts) {
      if (isMobile) {
        if (mobileStep === 1) {
          setMobileStep(2);
        } else if (mobileStep === 2) {
          addProductsAndOrder();
        }
      } else {
        addProductsAndOrder();
      }
    } else {
      setLoading(false);
      message.error("Please select at any product");
    }
  };

  const handleCancel = async () => {
    await dispatch(clearSelectedProduct());
    setInventoryList([]);
    history.push("/");
  };

  return (
    <div>
      {customModal && (
        <AddCustomProduct
          setIsCustomModal={() => setCustomModal(false)}
          setIsSucces={() => setCustomModal(false)}
          isProductModal={customModal}
        />
      )}
      <Container>
        <div className="catalogue">
          <h3>Catalogue</h3>
          {/* <SearchDiv style={{ flexGrow: 1, marginRight: "10px" }}>
            <SearchBox
              //   value={}
              //   onChange={(e) => setSearch(e)}
              placeholder={"Search Products"}
            />
          </SearchDiv> */}
          <div style={{ display: "flex", gap: "10px" }}>
            <Search
              data-testid="search"
              placeholder={Content.PRODUCT_SCREEN_SEARCH_PLACEHOLDER}
              allowClear
              // enterButton="Search"
              size="large"
              onSearch={searchProducts}
              prefix={<SearchOutlined />}
            />
            <Button
              key="submit"
              type="primary"
              style={{
                padding: "8px 10px",
                height: 41,
                border: "1px solid #DDDDDD",
                color: "black",
                borderRadius: "5px",
                background: "#F4F4F4",
              }}
              onClick={() => handleCustomModal()}
            >
              + Custom Product
            </Button>
          </div>
          {!isMobile && (
            <ProductList
              selectedProducts={selectedProducts}
              data-testid="productList"
              products={products}
              loading={productsLoading}
              productTemplates={productTemplates}
              updatedProduct={updatedProduct!}
              dispatch={dispatch}
              setInventoriesToAdd={addToInventories}
            />
          )}
          {isMobile && mobileStep === 1 && (
            <ProductList
              selectedProducts={selectedProducts}
              data-testid="productList"
              products={products}
              loading={productsLoading}
              productTemplates={productTemplates}
              updatedProduct={updatedProduct!}
              dispatch={dispatch}
              setInventoriesToAdd={addToInventories}
            />
          )}
          {isMobile && mobileStep === 2 && (
            <Inventory
              selectedProducts={selectedProducts}
              productTemplates={productTemplates}
              form={form}
              setTotalInventories={setTotalInventories}
              handleAddProduct={handleAddProduct}
              isMobile={isMobile}
            />
          )}
        </div>
        {!isMobile && (
          <div className="inventory">
            <h3>Inventory</h3>
            <Inventory
              selectedProducts={selectedProducts}
              productTemplates={productTemplates}
              form={form}
              setTotalInventories={setTotalInventories}
              handleAddProduct={handleAddProduct}
              isMobile={isMobile}
              inventoryList={inventoryList}
              setInventoryList={setInventoryList}
            />
          </div>
        )}
      </Container>
      <FooterButton>
        <Button onClick={handleCancel}>Cancel</Button>
        <Popconfirm
          title="Sure to add products?"
          onConfirm={() => handleAddProduct()}
        >
          <Button type="primary" loading={loading}>
            + Add Products
          </Button>
        </Popconfirm>
      </FooterButton>
    </div>
  );
};

export default AddInventoryCatalogue;
