import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  Radio,
  Button,
  Image,
  Select,
  Input,
  Card,
  Spin,
  Modal,
  List,
  Form,
  InputNumber,
  message,
} from "antd";
import icon2 from "../assets/images/Splash2.png";
import { Store } from "../redux/reducers/StoreReducer";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  ProductTemplate,
  ProductTemplateState,
} from "../redux/reducers/ProductTemplateReducer";
import {
  getProductTemplate,
  getProductTemplates,
} from "../redux/actions/productTemplateActions";
import { Redirect } from "react-router-dom";
import { Inventory } from "../redux/reducers/InventoryReducer";
import {
  getInStoreInventoryDetailsFromAPI,
  getInventories,
  getInventoriesFromAPI,
  getInventory,
} from "../redux/actions/inventoryActions";
import Content from "../constants/Content";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  CaretLeftFilled,
  CloseOutlined,
  ScanOutlined,
} from "@ant-design/icons";
import { DraftOrder } from "../redux/reducers/OrderReducer";
import { createDraftOrderFromAPI } from "../redux/actions/orderActions";
import { UserState } from "../redux/reducers/UserReducer";
import { ButtonGroup } from "@mui/material";
import { QrReader } from "react-qr-reader";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Inter;
  // & > div {
  //   flex: 1;
  // }

  &.redesign {
    padding: 0;
  }

  @media (max-width: 768px) and (orientation: portrait) {
    padding: 24px 14px;
  }
`;

const Topper = styled.span`
  display: flex;
  width: 100%;
  height: 74px;
  padding: 8px 32px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-bottom: 0.5px solid var(--Line, #ddd);
  background: var(--White, #fff);
  font-family: Inter;

  b {
    color: var(--Black, #000);
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    flex: 0 0 auto;
  }

  button {
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: black;
    height: 50px;
    flex: 0;
    color: var(--Light---Black, #292d32);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    opacity: 0;
  }

  @media (max-width: 768px) and (orientation: portrait) {
    justify-content: center;
    button {
      display: none;
    }
    b {
      color: var(--Black, #000);
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
`;

const Header = styled.div`
  font-size: 24px; /* Adjust the font size as needed */
  color: #333; /* Example text color */
  margin-bottom: 43px; /* Adjust the margin as needed */
  text-align: center;
  // min-height: 434px;
  min-height: 291px;
  flex: 0 0 auto;
  // border-radius: 8px;
  background: #0b0937; // linear-gradient(89deg, #0b0937 0%, rgba(13, 9, 68, 0) 130.14%);
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 45px;
  gap: 16px;
  .ant-image {
    flex: 0 0 135px;
    background-color: #fff;
    max-height: 135px;
    height: 135px;
    justify-content: center;
    display: inline-flex;
    align-items: center;
  }
  & > span {
    flex: 1;
    border-radius: 8px;
    // max-height: 135px;
    // height: 135px;
    display: block;
    // padding: 16px;
    text-align: left;
    b {
      color: var(--White, #fff);
      font-family: Inter;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 96px */
    }
    p {
      color: var(--White, #fff);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
      margin: 0;
      text-align: left;
      overflow-y: scroll;

      // Hide scrollbar for Chrome, Safari and Opera
      &::-webkit-scrollbar {
        display: none;
      }

      // Hide scrollbar for IE, Edge, and Firefox
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }

  &.need-help {
    flex: 0 0 auto;
    min-height: 151px;
  }

  @media (min-width: 1024px) or (orientation: landscape) {
    min-height: 291px;
    b {
      font-size: 64px;
    }
  }

  @media (max-width: 932px) and (orientation: landscape) {
    min-height: 151px;
    // display: none;
  }
`;

const CategorySelector = styled(Radio.Group)`
  flex: 1;
  margin-bottom: 24px; /* Space between categories and size selector */
  gap: 14px;
  display: flex;
  flex-wrap: wrap;

  .ant-radio-button-wrapper {
    margin-right: 8px; /* Spacing between buttons */
    border: 1px solid var(--Line, #ddd);
    height: 70px;
    flex-shrink: 0;
    min-width: 145px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &:not(:first-child)::before {
      display: none;
    }

    /* Custom colors based on your design */
    border-radius: 8px;
    border: 1px solid var(--Line, #ddd);

    /* Active and hover styles */
    &.ant-radio-button-wrapper-checked,
    &:hover {
      border-radius: 8px;
      border: 2px solid black;
      background: #fbfcff;
    }
  }

  @media (max-width: 768px) and (orientation: portrait) {
    .ant-radio-button-wrapper {
      min-width: 100px;
    }
  }
`;

const SizeSelector = styled(Radio.Group)`
  flex: 1;
  margin-bottom: 20px; /* Space between size selector and search button */
  max-height: 250px;
  overflow-y: scroll;
  gap: 14px;
  display: flex;
  flex-wrap: wrap;

  // Hide scrollbar for Chrome, Safari and Opera
  &::-webkit-scrollbar {
    display: none;
  }

  // Hide scrollbar for IE, Edge, and Firefox
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  .ant-radio-button-wrapper {
    margin-right: 8px; /* Spacing between buttons */
    border: 1px solid var(--Line, #ddd);
    height: 70px;
    flex-shrink: 0;
    min-width: 145px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    /* Custom colors based on your design */
    border-radius: 8px;
    border: 1px solid var(--Line, #ddd);

    /* Active and hover styles */
    &.ant-radio-button-wrapper-checked,
    &:hover {
      border-radius: 8px;
      border: 2px solid black;
      background: #fbfcff;
    }

    &:not(:first-child)::before {
      display: none;
    }
  }

  @media (max-width: 768px) and (orientation: portrait) {
    .ant-radio-button-wrapper {
      min-width: 100px;
    }
  }
`;

const ScanButton = styled(Button)`
  display: flex;
  // width: 100%;
  height: 68px;
  padding: 11.5px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--Grey---Button-Fill, #f4f4f4);
  color: #292d32;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const SearchButton = styled(Button)`
  display: flex;
  // width: 100%;
  height: 68px;
  padding: 11.5px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border: none;
  border-radius: 8px;
  background: black;
  color: var(--Black, #000);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  /* Hover style */
  &:hover {
    background-color: #40a9ff; /* Lighter blue on hover */
    border-color: #40a9ff;
  }

  @media (min-width: 1024px) or (orientation: landscape) {
    display: flex;
    width: 253px;
    height: 68px;
    padding: 11.5px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    place-self: end;
  }
`;

const Title = styled.p`
  color: var(--Light---Black, #292d32);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
`;

const ProductHeader = styled.b`
  color: #2e2e2e;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const StoreButton = styled(Button)`
  border: none;
  color: #000;
  &.ant-btn-primary {
    &:focus,
    &:hover {
      color: #000;
    }
  }
`;

const FilterSpan = styled.span`
  margin: 20px 0;
  display: flex;
  gap: 16px;
  .ant-select {
    flex: 1;
    height: 52px;
    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 52px;
      font-size: 16px;
      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        line-height: 52px;
      }
    }
  }
  .ant-input {
    font-size: 16px;
  }
  @media (max-width: 768px) and (orientation: portrait) {
    flex-wrap: wrap;
  }
`;

const CustomList = styled(List)`
  @media (min-width: 1024px) or (orientation: landscape) {
    .ant-row {
      // gap: 15px;
    }
  }
`;

const InventoryItem = styled(Card)`
  margin: 16px 0;
  .ant-card-body {
    display: flex;
    padding: 0;
    .ant-image {
      flex: 2;
      border-radius: 8px 0px 0px 8px;
      border: 1px solid var(--Line, #ddd);
      width: 140px;
      img {
        object-fit: contain;
        max-height: 90px;
        margin: auto;
        display: block;
      }
    }
    & > div {
      flex: 5;
      padding: 16px 24px;
      display: flex;
      justify-content: space-between;
      align-items: start;
      flex-direction: column;
      b {
        color: var(--Black, #000);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        justify-content: space-between;
        width: 100%;
        label {
          display: none;
          color: var(--Black, #000);
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
      p {
        color: var(--Text---Grey, #989898);
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      label {
        color: var(--Text---Grey, #989898);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 21px */
      }
    }
    & > b {
      flex: 0 0 45px;
      text-align: right;
      padding: 16px 24px;
      color: var(--Black, #000);
      text-align: right;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  @media (min-width: 1024px) {
    margin: 0;
    border-radius: 8px;
    border: 0.5px solid var(--Line, #ddd);
    .ant-card-body {
      flex-direction: column;
      .ant-image {
        flex: 1;
        width: 100%;
        border: 0;
        img {
          max-height: 100%;
        }
      }
      & > div {
        flex: 1;
      }
      & > b {
        text-align: left;
      }
    }
  }

  @media (max-width: 768px) and (orientation: portrait) {
    margin: 0;
    border-radius: 8px;
    border: 0.5px solid var(--Line, #ddd);
    .ant-card-body {
      flex-direction: column;
      .ant-image {
        flex: 1;
        width: 100%;
        border: 0;
        img {
          max-height: 100%;
        }
      }
      & > div {
        flex: 1;
        label {
          display: inline;
        }
      }
      & > b {
        display: none;
      }
    }
  }
`;

const SearchBoxWrapper = styled.div`
  position relative;
  display: flex;
  flex: 4;
  .ant-input-affix-wrapper {
    height: 52px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    transition: all 0.3s;
    padding-left: 32px; // Adjust padding to make room for the icon
    &:hover {
      border-color: #40a9ff;
    }

    &:focus,
    &:active {
      border-color: #40a9ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  }

  .ant-input {
    font-size: 16px;
    &:focus,
    &:active {
      box-shadow: none;
    }
  }

  .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #333;
    z-index: 2;
    font-size: 16px;
    height: 52px;
  }

  @media (max-width: 768px) and (orientation: portrait) {
    flex: 0 0 100%;
    button.ant-btn.ant-btn-ghost {
      display: none;
    }
  }
`;

const SearchInput = styled(Input.Search)`
  width: 100%;
  height: 52px;

  .ant-input {
    height: 100%;
  }
  &.ant-input-search-large .ant-input-search-button {
    height: 52px;
  }
`;

const NeedHelp = styled.div`
  max-width: 430px;
  display: block;
  flex: 1;
  b {
    color: var(--Black, #000);
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  p {
    color: var(--Text---Grey, #989898);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 32px */
  }
`;

const Sku = styled.b`
  color: var(--Black, #000);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
  display: block;
`;

const ModalCard = styled(Card)`
  .ant-card-body {
    padding: 0;
    div {
      &.confirm {
        justify-content: space-between;
        margin-bottom: 32px;
        & > span {
          display: flex;
          flex: 1;
          flex-direction: column;
          b {
            color: #1f8244;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
            margin-bottom: 8px;
          }
        }
        button.ant-btn-ghost {
          margin-right: 0;
        }
      }

      display: flex;
      color: var(--Black, #000);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      align-items: center;

      button.ant-btn-ghost {
        display: flex;
        width: 40px;
        height: 40px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid var(--Line, #ddd);
        background: var(--White, #fff);
        margin-right: 11px;
      }

      &:not(:first-of-type) {
        align-items: center;
        justify-content: space-between;
        .ant-image {
          flex: 1;
          height: 100%;
        }
        span {
          padding: 0 16px;
          flex: 1;
          justify-content: space-between;
          hr {
            margin: 16px 0;
          }
          b {
            font-size: 16px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &.price {
              font-weight: 600;
              font-size: 18px;
              margin-top: 24px;
            }
            color: var(--Black, #000);
            font-family: Inter;
            font-style: normal;
            line-height: normal;

            a {
              color: #255cba;
              text-align: right;
              font-family: Inter;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%; /* 18px */
              text-decoration-line: underline;
            }
          }
          p {
            color: var(--Text---Grey, #989898);
            font-family: Inter;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin: 5px 0;
          }
          button.ant-btn-primary {
            width: 100%;
            margin-top: 90px;
            display: flex;
            height: 56px;
            padding: 11.5px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex-shrink: 0;
            border-radius: 8px;
            background: black;
          }
        }
      }
    }
  }

  @media (max-width: 768px) and (orientation: portrait) {
    .ant-card-body {
      div {
        &:not(:first-of-type) {
          flex-direction: column;
          span {
            width: 100%;
          }
        }
      }
    }
  }
`;

const CustomModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
    background: var(--White, #fff);
  }
  @media (max-width: 768px) and (orientation: portrait) {
    top: 0;
  }
`;

const SimilarProducts = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0 0;

  b {
    width: 100%;
    text-align: left;
    color: var(--Black, #000);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 16px 0;
  }

  .ant-spin-container {
    gap: 16px;
  }
  .ant-card {
    padding-top: 16px;
    border-radius: 8px;
    border: 0.5px solid var(--Line, #ddd);
    .ant-card-body {
      display: flex;
      flex-direction: column;
    }
  }
  // @media (min-width: 1024px) or (orientation: landscape) {
  .ant-row > div {
    padding: 8px;
  }
  // }

  @media (max-width: 768px) and (orientation: portrait) {
    .ant-card {
      padding: 0;
      .ant-card-body {
        .ant-image {
          width: 60%;
        }
      }
    }
  }
`;

const FormItem = styled(Form.Item)`
  &.ant-form-item {
    & > .ant-form-item-row {
      flex: 1;
      width: 100%;
      flex-direction: column;
    }
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start !important;
    .ant-col.ant-form-item-control {
      width: 100%;
      flex: 1;
      .ant-form-item-control-input {
        flex: 1;
        width: 100%;
      }
    }
    .ant-form-item-control-input {
      display: flex;
      height: 47px;
      align-items: center;
      flex-shrink: 0;
      border-radius: 8px;
      border: 1px solid var(--Line, #ddd);
      background: var(--White, #fff);
      input {
        padding: 15px 28px 15px 17px;
        width: 100%;
        color: var(--Black, #000);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
      }
    }
    .ant-btn {
      display: flex;
      width: 100%;
      height: 56px;
      padding: 11.5px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex-shrink: 0;
      border-radius: 8px;
      background: black;
    }
  }
`;

const SizeSelect = styled(Select)`
  .ant-select-selector {
    padding: 0 !important;
    .ant-select-selection-search,
    .ant-select-selection-item {
      padding: 0 !important;
      color: var(--Black, #000);
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 18px */
      display: block;
    }
  }
`;

const SearchBoxCustom = ({
  placeholder,
  onSearch,
}: {
  placeholder: string;
  onSearch: any;
}) => {
  const { store }: Store = useAppSelector((state) => state.StoreReducer);
  return (
    <SearchBoxWrapper>
      <SearchInput
        data-testid="search"
        placeholder={placeholder}
        allowClear
        size="large"
        onSearch={(data) => onSearch(data)}
        style={{ flex: 1 }}
      />
    </SearchBoxWrapper>
  );
};

interface WelcomeProps {
  category: string;
  categories: string[];
  size: string;
  sizes: string[];
  handleCategoryChange: any;
  handleSizeChange: any;
  handleSearch: any;
  setInventoryId?: any;
}

interface ProductsPageProps {
  category: string;
  categories: string[];
  setCategory: any;
  size: string;
  sizes: string[];
  setSize: any;
  productTemplates: any[];
  inventoryId?: string;
  setInventoryId?: any;
}

const ProductsPage = ({
  category,
  categories,
  setCategory,
  size,
  sizes,
  setSize,
  productTemplates,
  inventoryId,
  setInventoryId,
}: ProductsPageProps) => {
  const { inventories, inventoriesLoading, inventory }: Inventory =
    useAppSelector((state) => state.InventoryReducer);
  const { productTemplate, productTemplateLoading }: ProductTemplateState =
    useAppSelector((state) => state.ProductTemplateReducer);
  const [search, setSearch] = useState("");
  const [brand, setBrand] = useState("");
  const [brands, setBrands] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const { store }: Store = useAppSelector((state) => state.StoreReducer);
  const [selectedInventory, setSelectedInventory] = useState<Inventory>();
  const [showModal, setShowModal] = useState(false);
  const [showReserveModal, setShowReserveModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [inStock, setInStock] = useState(0);
  const [relatedInventories, setRelatedInventories] = useState<Inventory[]>();
  const [customerName, setCustomerName] = useState("");
  const [customerNumber, setCustomerNumber] = useState("");
  const [customerNumberCode, setCustomerNumberCode] = useState("1");
  const [customerEmail, setCustomerEmail] = useState("");
  const [inventoryItems, setInventoryItems] = useState<Inventory[]>([]);
  const [lastBasePrice, setLastBasePrice] = useState(0);
  const [lastSold, setLastSold] = useState(0);
  const sizeFilterRef = useRef<any>(null);
  const { isMobile } = useAppSelector((state) => state.AppReducer);

  const searchProducts = (value: string) => {
    setSize("");
    setBrand("");
    setCategory("");
    setSearch(value);
  };

  const handleSelectInventory = (inventory: Inventory) => {
    const actualPrice =
      inventory.status === "Active"
        ? Number(inventory.price)
        : Number(inventory.price) - Number(store.upPrice);
    // we have already added the upPrice to the inventory items that are not in stock
    setLastBasePrice(actualPrice);
    setSelectedInventory(inventory);
    setShowModal(true);
  };

  const handleChangeSize = async (size: string) => {
    const matchingInventories = await getInventoriesFromAPI(search, {
      category: "",
      option1Value: size,
      brand: "",
      status: "",
      printed: "",
      option2Value: "",
      option3Value: "",
      consigner: "",
      productId: selectedInventory.productId,
      sortOption: "newestUpdated",
    });
    if (
      matchingInventories.filter(
        (mi) =>
          /* Number(store.upPrice) +  */ Number(mi.price) === lastBasePrice
      ).length < 1
    ) {
      setSelectedInventory({
        ...selectedInventory,
        option1Value: size,
        price: (lastSold ?? lastBasePrice) + Number(store.upPrice),
      });
    } else {
      setSelectedInventory(matchingInventories[0]);
    }
  };

  const createDraftOrder = async () => {
    if (customerName === "" || !customerNumber || !customerNumberCode) {
      message.warning("Please input a valid customer name and number");
      return;
    }
    setShowReserveModal(false);
    message.loading({ content: "Processing...", duration: 0, key: 1 });
    const names = customerName.split(" ");
    const last_name = names.pop();
    const first_name = names.join(" ");

    const draftOrder: DraftOrder = {
      customer: {
        first_name,
        last_name: last_name ?? "",
        phone: `+${customerNumberCode}${customerNumber}`,
        email: customerEmail ?? "",
      },
      line_items: [
        {
          quantity: 1,
          variant_id: selectedInventory?.product?.shopifyId,
          title: selectedInventory?.product?.title,
          price: String((lastSold ?? lastBasePrice) + Number(store?.upPrice)),
          option: {
            option1: selectedInventory?.option1Value,
          },
        },
      ],
      note: `${selectedInventory.option1Value} / ${selectedInventory.option2Value} / ${selectedInventory.price}`,
    };
    try {
      const order = await createDraftOrderFromAPI(draftOrder);
      message.destroy(1);
      setShowConfirmModal(true);
    } catch (e) {
      message.destroy(1);
      console.error(e);
      message.error("Create order failed. Please try again later");
      setShowModal(true);
    }
  };

  const filterUniqueProductIds = (dataArray: Inventory[]) => {
    const brandSet = new Set<string>(brands);
    const brandsList: string[] = [...brands];
    const productIds = new Set();
    const filteredArray = dataArray
      .sort((a, b) => a.status.localeCompare(b.status))
      .filter((item) => {
        if (!brandSet.has(item.product.brand)) {
          brandSet.add(item.product.brand);
          brandsList.push(item.product.brand);
        }
        if (!productIds.has(item.productId)) {
          productIds.add(item.productId);
          return true;
        }
        return false;
      });
    setBrands(brandsList);
    return filteredArray;
  };

  useEffect(() => {
    const uniqueData = filterUniqueProductIds(inventories);
    setInventoryItems(uniqueData);
  }, [inventories]);

  useEffect(() => {
    if (inventoryId) return;
    dispatch(
      getInventories(search, {
        category,
        option1Value: size,
        brand,
        status: search ? "" : "Active",
        printed: "",
        option2Value: "",
        option3Value: "",
        consigner: "",
        sortOption: "newestUpdated",
      })
    );
  }, [category, size, search, brand, inventoryId]);

  const fetchInstoreDetails = async (inventory) => {
    const data = await getInStoreInventoryDetailsFromAPI(selectedInventory);
    const { inStock, relatedInventories, lastSoldPrice }: any = data;
    setLastSold(lastSoldPrice > 0 ? lastSoldPrice : null);
    setInStock(inStock);
    setRelatedInventories(relatedInventories);
  };

  const handlePhoneCodeChange = (num: string) => {
    setCustomerNumberCode(num.replace(/[^0-9]/g, ""));
  };

  const handlePhoneNumberChange = (num: string) => {
    setCustomerNumber(num.replace(/[^0-9]/g, ""));
  };

  useEffect(() => {
    if (!selectedInventory) {
      setInStock(0);
      setRelatedInventories([]);
      setLastBasePrice(0);
      return;
    }
    setShowModal(true);
    fetchInstoreDetails(selectedInventory);
    dispatch(getProductTemplate(selectedInventory?.product?.productTemplateId));
  }, [selectedInventory]);

  useEffect(() => {
    if (inventoryId) {
      dispatch(getInventory(inventoryId));
    }
  }, [inventoryId]);

  useEffect(() => {
    if (inventory) {
      setLastBasePrice(Number(inventory?.price));
      setSelectedInventory(inventory);
      setSearch(inventory?.product?.title);
      setSize(inventory?.option1Value);
      setBrand(inventory?.product.brand);
      setInventoryId(null);
    }
  }, [inventory]);

  return (
    <Container>
      {/* Confirm Modal */}
      <CustomModal
        visible={showConfirmModal}
        onCancel={() => {
          setShowConfirmModal(false);
        }}
        footer={null}
        width={425}
        closable={true}
        closeIcon={<></>}
      >
        <ModalCard bordered={false}>
          <div className="confirm">
            <span>
              <b>CONFIRMED!</b>
              Please Complete at Register
            </span>
            <StoreButton
              type="ghost"
              icon={<CloseOutlined />}
              onClick={() => {
                setShowReserveModal(false);
                setShowModal(true);
              }}
            />
          </div>

          <div>
            <Image preview={false} src={selectedInventory?.product?.image} />
            <span style={{ flex: 2 }}>
              <b>{selectedInventory?.product?.title}</b>
              <p>{selectedInventory?.product?.brand}</p>
              <Sku className="price">
                {getSymbolFromCurrency(store.currency)}
                {selectedInventory?.price}
              </Sku>
              <hr />
              <p>Size</p>
              <Sku>{selectedInventory?.option1Value}</Sku>
            </span>
          </div>
        </ModalCard>
      </CustomModal>

      {/* Reserve Modal */}
      <CustomModal
        visible={showReserveModal}
        onCancel={() => {
          setShowReserveModal(false);
          setSelectedInventory(null);
        }}
        footer={null}
        width={425}
        closable={true}
        closeIcon={<></>}
      >
        <ModalCard bordered={false}>
          <div>
            <StoreButton
              type="ghost"
              icon={<CaretLeftFilled />}
              onClick={() => {
                setShowReserveModal(false);
                setShowModal(true);
              }}
            />
            Back to Product Details
          </div>
          <div>
            <Image preview={false} src={selectedInventory?.product?.image} />
            <span style={{ flex: 2 }}>
              <b>{selectedInventory?.product?.title}</b>
              <p>{selectedInventory?.product?.brand}</p>
              <Sku className="price">
                {getSymbolFromCurrency(store.currency)}
                {inStock
                  ? selectedInventory?.price
                  : (lastSold ?? lastBasePrice) + Number(store?.upPrice)}
              </Sku>
              <hr />
              <p>Size</p>
              <Sku>{selectedInventory?.option1Value}</Sku>
            </span>
          </div>
          <Form style={{ padding: "24px 0 0" }}>
            <b>Reserve Mine</b>
            <p>
              Kindly complete this form and once we replenish our stock of this
              product, we&apos;ll get back in touch with you.
            </p>
            <FormItem label="Name">
              <Input
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
                required
              />
            </FormItem>
            {/* <FormItem label="Email">
              <Input
                value={customerEmail}
                onChange={(e) => setCustomerEmail(e.target.value)}
                required
              />
            </FormItem> */}
            <FormItem label="Phone Number">
              <Input
                style={{ flex: "0 0 90px", height: 54 }}
                min={0}
                required
                value={customerNumberCode}
                onChange={(e) => handlePhoneCodeChange(e.target.value)}
              />
              <Input
                value={customerNumber}
                type="tel"
                onChange={(e) => handlePhoneNumberChange(e.target.value)}
                required
                minLength={10}
                maxLength={10}
                style={{ flex: 1 }}
              />
            </FormItem>
            <FormItem style={{ marginBottom: 0 }}>
              <StoreButton
                type="primary"
                onClick={() => {
                  createDraftOrder();
                }}
              >
                Next
              </StoreButton>
            </FormItem>
          </Form>
        </ModalCard>
      </CustomModal>
      {/* End of Reserve Modal */}

      {/* Product Modal */}
      <CustomModal
        visible={showModal}
        onCancel={() => {
          setShowModal(false);
          setSelectedInventory(null);
        }}
        footer={null}
        width={isMobile ? "100%" : "90%"}
        style={{ maxWidth: isMobile ? "100%" : 770 }}
        closable={true}
        closeIcon={<></>}
      >
        <ModalCard bordered={false}>
          <div>
            <StoreButton
              type="ghost"
              icon={<CaretLeftFilled />}
              onClick={() => setShowModal(false)}
            />
            View more shoes
          </div>
          <div>
            <Image preview={false} src={selectedInventory?.product?.image} />
            <span>
              <b>{selectedInventory?.product?.title}</b>
              <p>{selectedInventory?.product?.brand}</p>
              <Sku className="price">
                {getSymbolFromCurrency(store.currency)}
                {inStock
                  ? selectedInventory?.price
                  : (lastSold ?? lastBasePrice) + Number(store?.upPrice)}{" "}
                {/* order of priority for assigned price is last sold price, followed by last available price. Store upPrice will be added to it. */}
              </Sku>
              <hr />
              <p>Size</p>
              {productTemplate && (
                <SizeSelect
                  style={{ padding: 0 }}
                  bordered={false}
                  defaultValue={selectedInventory?.option1Value}
                  onChange={(e: any) => handleChangeSize(e)}
                >
                  {productTemplate?.option1Values?.map((sz) => (
                    <Select.Option key={sz} value={sz}>
                      {sz}
                    </Select.Option>
                  ))}
                </SizeSelect>
              )}
              <hr />
              {inStock > 0 && (
                <StoreButton
                  type="primary"
                  onClick={() => {
                    setShowModal(false);
                    setShowConfirmModal(true);
                  }}
                >
                  In stock - Start Checkout
                </StoreButton>
              )}
              {inStock <= 0 && (
                <StoreButton
                  type="primary"
                  onClick={() => {
                    setShowModal(false);
                    -setShowReserveModal(true);
                  }}
                >
                  Out of Stock - Reserve from Warehouse
                </StoreButton>
              )}
            </span>
          </div>
          {relatedInventories && relatedInventories.length > 0 && (
            <SimilarProducts>
              <b>Similar Products</b>
              <CustomList
                grid={{
                  gutter: 16,
                  xs: 1,
                  sm: 3,
                  md: 3,
                  lg: 3,
                  xl: 3,
                  xxl: 4,
                }}
                bordered={false}
                size="small"
                dataSource={relatedInventories}
                renderItem={(relatedInventory: Inventory) => (
                  <Card
                    bordered={false}
                    key={relatedInventory.id}
                    onClick={() => handleSelectInventory(relatedInventory)}
                  >
                    <Image
                      preview={false}
                      src={relatedInventory?.product?.image}
                    />
                    <span>
                      <b>{relatedInventory?.product?.title}</b>
                      <p>{relatedInventory?.product?.brand}</p>
                      <Sku className="price">
                        {getSymbolFromCurrency(store.currency)}
                        {relatedInventory?.price}
                      </Sku>
                    </span>
                  </Card>
                )}
              />
            </SimilarProducts>
          )}
        </ModalCard>
      </CustomModal>
      {/* End of Product Modal */}

      <ProductHeader>Inventory</ProductHeader>
      <FilterSpan>
        <SearchBoxCustom
          placeholder={Content.PRODUCT_SCREEN_SEARCH_PLACEHOLDER}
          onSearch={searchProducts}
        />
        <Select
          allowClear
          placeholder="Filter By Category"
          style={{ flex: 1 }}
          value={category}
          onChange={setCategory}
        >
          <Select.Option key="all" value="">
            All Categories
          </Select.Option>
          {categories?.map((cat, idx) => (
            <Select.Option key={idx} value={cat}>
              {cat}
            </Select.Option>
          ))}
        </Select>
        <Select
          allowClear
          placeholder="Filter By Brand"
          style={{ flex: 1 }}
          value={brand}
          onChange={setBrand}
        >
          <Select.Option key="all" value="">
            All Brands
          </Select.Option>
          {brands?.map((br, idx) => (
            <Select.Option key={idx} value={br}>
              {br}
            </Select.Option>
          ))}
        </Select>
        <Select
          placeholder="Filter By Size"
          ref={sizeFilterRef}
          value={size}
          onChange={setSize}
        >
          {sizes?.map((sz, idx) => (
            <Select.Option key={idx} value={sz}>
              {sz}
            </Select.Option>
          ))}
        </Select>
      </FilterSpan>
      {inventoriesLoading ? (
        <Spin />
      ) : inventoryItems?.length < 1 ? (
        <div>
          <h2>No products available.</h2>
        </div>
      ) : (
        <CustomList
          grid={{
            xs: 1,
            sm: 1,
            md: 1,
            lg: 4,
            xl: 4,
            xxl: 4,
            gutter: 15,
          }}
          bordered={false}
          size="small"
          dataSource={inventoryItems.map((item) => ({
            ...item,
            price:
              item.status === "Active"
                ? item.price
                : Number(item.price) + Number(store.upPrice),
          }))}
          renderItem={(inventory: Inventory) => (
            <InventoryItem
              key={inventory?.id}
              style={{ margin: 7.5 }}
              onClick={() => handleSelectInventory(inventory)}
            >
              <Image preview={false} src={inventory?.product?.image} />
              <div>
                <b>
                  {inventory?.product?.title}{" "}
                  <label>
                    {getSymbolFromCurrency(store?.currency)}
                    {inventory?.price}
                  </label>
                </b>
                <p>{inventory?.product?.brand}</p>
                <label>Style</label>
                <Sku>{inventory?.product?.sku}</Sku>
              </div>
              <b>
                {getSymbolFromCurrency(store?.currency)}
                {inventory?.price}
              </b>
            </InventoryItem>
          )}
        />
      )}
    </Container>
  );
};

const Welcome = ({
  category,
  categories,
  size,
  sizes,
  handleCategoryChange,
  handleSizeChange,
  handleSearch,
  setInventoryId,
}: WelcomeProps) => {
  const { store }: Store = useAppSelector((state) => state.StoreReducer);
  const [needHelp, setNeedHelp] = useState(true);
  const [error, setError] = useState("");
  const [hasCamera, setHasCamera] = useState(false);
  const [scanResult, setScanResult] = useState("");
  const [isScanning, setIsScanning] = useState(false);

  useEffect(() => {
    if (scanResult) {
      const inventoryId = scanResult.split("/");
      setInventoryId(inventoryId.slice(-1) ?? "");
    }
    return;
  }, [scanResult]);

  const handleScan = (data) => {
    if (data) {
      setScanResult(data);
      setIsScanning(false);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const startScanning = () => {
    setIsScanning(true);
  };

  useEffect(() => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices: MediaDeviceInfo[]) => {
        const hasCamera = devices.some(
          (device) => device.kind === "videoinput"
        );
        setHasCamera(hasCamera);
      })
      .catch((err: any) => {
        setError("Error accessing media devices.");
      });
  }, []);

  return (
    <Container className="redesign">
      <Header className={needHelp ? "need-help" : ""}>
        {/* <Image src={store?.logo ?? icon2} /> */}
        <span>
          {needHelp ? (
            <b>Need help finding a shoe?</b>
          ) : (
            <b>Welcome to the store</b>
          )}
        </span>
      </Header>
      <Container>
        {isScanning ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <QrReader
              onResult={(data, error) => {
                if (!!data) {
                  setScanResult(data.getText());
                  setIsScanning(false);
                }
                if (!!error) {
                  setError(error.message);
                  console.error(error);
                }
              }}
              scanDelay={300}
              constraints={{
                facingMode: { exact: "environment" },
              }}
            />
          </div>
        ) : !needHelp ? (
          <NeedHelp>
            <b>Need help finding a shoe?</b>
            <p>
              Let us know your size, Inform us of your size, and we will display
              the available options.
            </p>
          </NeedHelp>
        ) : (
          <div style={{ flex: 1, alignItems: "space-between" }}>
            <CategorySelector onChange={handleCategoryChange} value={category}>
              {categories?.map((category, idx) => (
                <Radio.Button
                  key={idx}
                  value={category}
                  style={{ textTransform: "capitalize" }}
                >
                  {category}
                </Radio.Button>
              ))}
            </CategorySelector>
            {sizes.length > 0 && (
              <span>
                <Title>Select Size</Title>
                <label
                  style={{
                    color: "#989898",
                    fontSize: 16,
                    fontWeight: 500,
                    margin: "8px auto 10px",
                    display: "block",
                  }}
                >
                  Please choose one
                </label>
              </span>
            )}
            <SizeSelector
              onChange={handleSizeChange}
              value={size}
              disabled={category === ""}
            >
              {sizes
                ?.filter((size) => size !== "")
                .map((sz, idx) => (
                  <Radio.Button key={idx} value={sz}>
                    {sz}
                  </Radio.Button>
                ))}
            </SizeSelector>
          </div>
        )}
        <ButtonGroup
          style={{
            justifyContent: "space-between",
            alignItems: "flex-end",
            gap: 16,
          }}
        >
          <ScanButton
            style={{ flex: 1, display: "none" }}
            icon={<ScanOutlined />}
            type="text"
            onClick={() => {
              setIsScanning(!isScanning);
            }}
          >
            {isScanning ? "STOP SCANNING" : "SCAN SHOE"}
          </ScanButton>
          {needHelp ? (
            <SearchButton
              type="primary"
              disabled={category === "" || size === ""}
              onClick={handleSearch}
              style={{ flex: 4 }}
            >
              VIEW RESULTS
            </SearchButton>
          ) : (
            <SearchButton
              type="primary"
              onClick={() => setNeedHelp(true)}
              style={{ flex: 4 }}
            >
              LET&apos;S GO
            </SearchButton>
          )}
        </ButtonGroup>
      </Container>
    </Container>
  );
};

const InStore: React.FC = () => {
  // State for category and size
  const [category, setCategory] = useState<string>("");
  const [size, setSize] = useState<string>("");
  const { store }: Store = useAppSelector((state) => state.StoreReducer);
  const { productTemplates, productTemplatesLoading }: ProductTemplate =
    useAppSelector((state) => state.ProductTemplateReducer);
  const [categories, setCategories] = useState<any[]>([]);
  const [sizes, setSizes] = useState<any[]>([]);
  const [search, setSearch] = useState(false);
  const { isMobile } = useAppSelector((state) => state.AppReducer);
  const dispatch = useAppDispatch();
  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);
  const ACTIVITY_TIMER_CT = 120000;
  const [inventoryId, setInventoryId] = useState("");

  if (!dbUser) {
    return <Redirect to="/" />;
  }

  let activityTimer;

  const resetTimer = () => {
    clearTimeout(activityTimer);
    activityTimer = setTimeout(() => {
      window.location.reload();
    }, ACTIVITY_TIMER_CT);
  };

  useEffect(() => {
    if (inventoryId) setSearch(true);
  }, [inventoryId]);

  useEffect(() => {
    dispatch(getProductTemplates());
    // Set up event listeners
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("mousedown", resetTimer);
    window.addEventListener("keypress", resetTimer);

    // Set initial timer
    resetTimer();

    // Clean up
    return () => {
      clearTimeout(activityTimer);
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("mousedown", resetTimer);
      window.removeEventListener("keypress", resetTimer);
    };
  }, []);

  useEffect(() => {
    const cat: string[] = [];
    productTemplates.forEach((template) => {
      if (!cat.includes(template?.category?.toLowerCase())) {
        cat.push(template?.category?.toLowerCase());
      }
    });
    setCategories(cat);
  }, [productTemplates]);

  const removeDuplicates = (arr: string[]): string[] => {
    const uniqueSet = new Set<string>();
    return arr.filter((element) => {
      const lowerCaseElement = element?.toLowerCase();
      if (!uniqueSet.has(lowerCaseElement)) {
        uniqueSet.add(lowerCaseElement);
        return true;
      }
      return false;
    });
  };

  useEffect(() => {
    const sizers = productTemplates.filter(
      (productTemplate) => productTemplate.category?.toLowerCase() === category
    );
    let sizeString = "";
    sizers.forEach((size) => {
      // if (["sizes", "size"].includes(size?.option1?.toLowerCase()))
      if (size.option1) {
        sizeString += size.option1Values + ",";
      } else if (
        size.option2 &&
        ["sizes", "size"].includes(size?.option2?.toLowerCase())
      ) {
        sizeString += size.option2Values + ",";
      }
    });
    setSizes(removeDuplicates(sizeString.split(",").filter((sz) => sz !== "")));
  }, [category]);

  const handleCategoryChange = (e: any) => {
    setCategory(e.target.value);
  };

  const handleSizeChange = (e: any) => {
    setSize(e.target.value);
  };

  const handleSearch = () => {
    // Implement the search functionality here
    console.log(`Searching for ${category} size ${size}`);
    setSearch(true);
  };

  return (
    <section
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      <Topper>
        {/* <StoreButton type="primary" style={{ opacity: 0 }}>
          Start Again
        </StoreButton> */}
        <b>{store?.name ?? "Hampton"}</b>
        <StoreButton
          type="primary"
          onClick={() => {
            setCategory("");
            setSize("");
            setSearch(false);
          }}
        >
          Start Again
        </StoreButton>
      </Topper>
      {!search && (
        <Welcome
          category={category}
          categories={categories}
          size={size}
          sizes={sizes}
          handleCategoryChange={handleCategoryChange}
          handleSizeChange={handleSizeChange}
          handleSearch={handleSearch}
          setInventoryId={setInventoryId}
        />
      )}
      {search && (
        <ProductsPage
          category={category}
          categories={categories}
          setCategory={setCategory}
          size={size}
          sizes={sizes}
          productTemplates={productTemplates}
          setSize={setSize}
          inventoryId={inventoryId}
          setInventoryId={setInventoryId}
        />
      )}
    </section>
  );
};

export default InStore;
