import React, { useEffect, useState } from "react";
import { getOrders } from "../../redux/actions/orderActions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { StoreState } from "../../redux/reducers/StoreReducer";
import LogsCardView from "./LogsCardView";
import { Button, Select } from "antd";
import { InventoryState } from "../../redux/reducers/InventoryReducer";
import { getInventoryFilterOptions } from "../../redux/actions/inventoryActions";
import styled from "styled-components";

const Container = styled.div`
  margin: 20px;
  .ant-select-selector {
    padding: 0 14px;
  }
  .ant-select-multiple .ant-select-selection-item {
    background: white;
    border: transparent;
  }

  .filter-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  @media (max-width: 768px) {
    .filter-header {
      flex-direction: column;
      gap: 10px;
      margin-bottom: 15px;
    }
  }
`;

const Logs = () => {
  const dispatch = useAppDispatch();
  const [filters, setFilters] = useState({
    consigner: "",
    orderDateRange: "",
    status: "Sold",
    isSeller: true,
  });
  const [isInvoice, setIsInvoice] = useState(false);
  const [invoiceRecord, setInvoiceRecord] = useState({});
  const [isBuyer, setIsBuyer] = useState(false);
  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);

  const { orders, ordersLoading } = useAppSelector(
    (state) => state.OrderReducer
  );
  const { inventoryFilterOptions }: InventoryState = useAppSelector(
    (state) => state.InventoryReducer
  );
  useEffect(() => {
    dispatch(getOrders("", filters));
    dispatch(getInventoryFilterOptions());
  }, [filters]);

  const handleInvoiceRecord = (record: any) => {
    setIsInvoice(true);
    setInvoiceRecord(record);
    // Handle the invoice record here
  };
  return (
    <Container>
      <div className="filter-header">
        <div>
          <Button
            type={filters.isSeller ? "default" : "primary"}
            style={{
              background: filters.isSeller ? "white" : "rgba(221, 221, 221, 1)",
              color: filters.isSeller ? "black" : "grey",
              borderColor: "transparent",
              borderRadius: "5px",
            }}
            onClick={() => setFilters({ ...filters, isSeller: true })}
          >
            Purchases
          </Button>
          <Button
            type={filters.isSeller ? "primary" : "default"}
            style={{
              background: filters.isSeller ? "rgba(221, 221, 221, 1)" : "white",
              color: filters.isSeller ? "grey" : "black",
              borderColor: "transparent",
              borderRadius: "5px",
            }}
            onClick={() => setFilters({ ...filters, isSeller: false })}
          >
            Sales
          </Button>
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <Select
            showSearch
            placeholder="Status"
            data-testid="statuses"
            value={"Status: " + filters?.status}
            onChange={(status) => setFilters({ ...filters, status })}
            style={{ borderRadius: "4px" }}
          >
            {inventoryFilterOptions.statuses &&
              inventoryFilterOptions.statuses.map((statuses) => (
                <Select.Option key={statuses.label} value={statuses.value}>
                  {statuses.label}
                </Select.Option>
              ))}
          </Select>
          <Button
            style={{
              background: "black",
              color: "white",
              height: "36",
              padding: "4px 16px",
              borderRadius: "5px",
            }}
          >
            Export CSV
          </Button>
        </div>
      </div>
      <LogsCardView
        store={store}
        orders={orders}
        loading={ordersLoading}
        setIsInvoiceModal={() => setIsInvoice(false)}
        isInvoice={isInvoice}
        status={2}
        invoiceRecord={invoiceRecord}
        filters={filters}
        handleInvoiceRecord={handleInvoiceRecord}
      />
    </Container>
  );
};

export default Logs;
