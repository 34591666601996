import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { Redirect, useParams } from "react-router";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import Colors from "../../constants/Colors";
import AuthService, { Auth } from "../../services/auth";
// import logo from "../../assets/images/logo.png";
import Fonts from "../../constants/Fonts";
import RedesignStyling from "../../constants/RedesignStyling";
import {
  checkUserFromAPI,
  getStoreFromEmail,
} from "../../redux/actions/storeActions";
import ResellerLoginForm from "./ResellerLoginForm";
import _ from "lodash";
import Cookies from "js-cookie";
import { message } from "antd";
import logo from "../../assets/images/VAULT.svg";

/**
 * Inventory Item Screen
 * renders a screen where user can edit a singular inventory item
 * TODO Tests:
 *  -
 * @returns
 */

//styles
const Container = styled.div`
  font-family: ${Fonts.CONSIGNER};
  background: ${Colors.CONSIGNER_BG};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: ${RedesignStyling.PAGE_PADDING};
  @media (max-width: 768px) {
    padding: ${RedesignStyling.PAGE_PADDING_MOBILE};
  }
`;
const SignupContainer = styled.div`
  width: 472px;
  max-width: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`;

const TOSContainer = styled.div`
  max-width: 100%;
  font-size: 0.8rem;
`;

const TopBarGeneric = styled.div`
  height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${Colors.WHITE};
  position: fixed;
  top: 0;
`;
const Logo = styled.img`
  height: 40px;
  width: auto;
  padding: 10px;
  margin: 10px;
`;
const Header = styled.div`
  color: var(--heading-color, #2e2e2e);
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 62px;
  letter-spacing: -2px;
  width: 100%;
`;
const Subheader = styled.div`
  color: ${Colors.CONSIGNER_GREY};
  text-align: center;
  font-family: ${Fonts.CONSIGNER};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  width: 100%;
`;

interface RouteParams {
  id?: string;
}

const ResellerLogin = () => {
  const dispatch = useAppDispatch();
  const auth = AuthService();

  //state
  const { isLoggedIn, dbUser } = useAppSelector((state) => state.UserReducer);
  const { store } = useAppSelector((state) => state.StoreReducer);

  const [email, setEmail] = useState(Cookies.get("storeLogin") ?? "");
  const [rememberSession, setRememberSession] = useState(false);
  const [password, setPassword] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);

  //helpers
  const handleChangeEmail = () => {
    Cookies.set("storeLogin", email);
    dispatch(getStoreFromEmail(email));
  };

  const login = async (email: string, password: string) => {
    setLoginLoading(true);
    try {
      const checkUser = await checkUserFromAPI(email);
      if (checkUser.isActive) {
        await auth.login(email, password);
        setLoginLoading(false);
        Cookies.remove("storeLogin");
      } else {
        message.error("User doesn't have any membership");
      }
    } catch (error) {
      setLoginLoading(false);
      message.error("User doesn't exist");
    }
  };

  useEffect(() => {
    console.log(isLoggedIn, dbUser, store);
  }, [isLoggedIn, dbUser, store]);

  if (isLoggedIn && store && dbUser)
    return <Redirect data-testid="dashboardRedirect" to="/" />;

  return (
    <>
      <div style={{ background: "#F9FAFB" }}>
        <Logo data-testid="logo" src={logo} />
      </div>
      <Container>
        <SignupContainer>
          <FormContainer>
            <Header>Welcome Back!</Header>
            <ResellerLoginForm
              onLogin={(email, password) => login(email, password)}
              email={email}
              rememberSession={rememberSession}
              onRememberSessionChange={(newValue: boolean) =>
                setRememberSession(newValue)
              }
              password={password}
              onEmailChange={(newEmail: string) => {
                setEmail(newEmail);
              }}
              onFocusOut={handleChangeEmail}
              onPasswordChange={(newPassword: string) =>
                setPassword(newPassword)
              }
              loginLoading={loginLoading}
            />
          </FormContainer>
        </SignupContainer>
      </Container>
    </>
  );
};

export default ResellerLogin;
