import React, { useEffect } from "react";
import styled from "styled-components";
import BackIcon from "../../assets/images/backIcon.svg"; // Assuming this is your imported image path
import { Button } from "antd";
import { addSelectedProduct } from "../../redux/actions/productActions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Container } from "@mui/material";
import { Wrapper } from "../Inventory/CreateOrder";
import getSymbolFromCurrency from "currency-symbol-map";

const CustomHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 12px;
  border-bottom: 1px solid #e0e0e0;
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

const BackButton = styled.button`
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-image: url(${BackIcon});
  background-repeat: no-repeat;
  background-size: cover;
`;

const MarkAsCompletedButton = styled.button`
  background-color: black;
  color: #ffffff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 8px;
`;

const OrderId = styled.span`
  margin-left: 10px;
  color: #808080;
`;

const BodyContainer = styled.div`
  display: flex;
  height: calc(100vh - 56px);
`;

const ConsignerInfo = styled.div`
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #dddddd;
`;

const InfoSection = styled.div`
  display: flex;
  margin: 5px;
`;

const TrackingInfoLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
`;

const TrackingInfoRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 40px;
`;

const TrackingInfo = styled.div`
  display: flex;
  margin: 5px;
`;

const TrackingLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`;

const TrackingRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
`;

const Divider = styled.div`
  border-bottom: 1px solid #dddddd;
  margin: 5px;
`;

const OrderInfo = styled.div`
  flex: 2;
  padding: 8px;
  background-color: #ffffff;
`;
const ProductRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;
const ProductDetails = styled.div`
  flex: 1;
  padding: 5px 16px;
  gap: 18px;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  &.pricing {
    & > div {
      &:not(.Requested) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
  &.center {
    align-items: center;
  }
  &:first-of-type {
    border-left: 1px solid #ddd;
  }
  b {
    align-self: stretch;
    flex: 1;
    a {
      color: inherit;
      white-space: nowrap;
    }
  }
  & > div {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: space-between;
    flex-wrap: nowrap;
    span {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      p {
        margin: 0 0 3px;
        color: #292d32;
      }
      label {
        color: var(--Text---Grey, #989898);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
    }
  }
  @media (max-width: 768px) {
    border-bottom: 1px solid #ddd;
    border-right: none;
    font-size: 10px;
    b {
      font-size: inherit;
    }
    & > div {
      font-size: inherit;
      gap: 16px;
      span {
        justify-content: flex-start;
        font-size: inherit;
        label {
          font-size: inherit;
        }
      }
    }
    &.center {
      align-items: flex-start;
    }
  }
`;
const SkuDivMargin = styled.div`
  margin-bottom: 1.4rem;
`;
const CompleteOrder = () => {
  const { products, updatedProduct, productsLoading, selectedProducts } =
    useAppSelector((state) => state.ProductReducer);
  const { store } = useAppSelector((state) => state.StoreReducer);

  const handleBackClick = () => {
    // console.log("Back button clicked");
  };

  const handleMarkAsCompleted = () => {
    // console.log("Mark as completed button clicked");
  };

  return (
    <>
      <CustomHeader>
        <HeaderLeft>
          <BackButton onClick={handleBackClick} />
          <OrderId>
            View Order <span style={{ color: "#747474" }}>{"#898"}</span>
          </OrderId>
        </HeaderLeft>
        <MarkAsCompletedButton onClick={handleMarkAsCompleted}>
          Mark as Completed
        </MarkAsCompletedButton>
      </CustomHeader>
      <BodyContainer>
        <ConsignerInfo>
          <InfoSection>
            <TrackingInfoLeft>
              <p style={{ fontSize: "20px", fontWeight: "700" }}>
                Order #12123
              </p>
              <p style={{ color: "#989898", fontWeight: "500" }}>Consignor</p>
            </TrackingInfoLeft>
            <TrackingInfoRight>
              <Button
                style={{
                  backgroundColor: "#eeebeb",
                  borderRadius: "18px",
                  padding: "0px 10px",
                  color: "#747474",
                  fontWeight: "600",
                }}
              >
                Pending buyer confirmation
              </Button>
              <p style={{ fontSize: "16px" }}>Personal Information</p>
            </TrackingInfoRight>
          </InfoSection>
          <Divider />
          <InfoSection>
            <TrackingLeft>
              <p style={{ color: "#989898", fontWeight: "500" }}>
                Tracking Number
              </p>
              <p style={{ color: "#989898", fontWeight: "500" }}>
                Shipping Method
              </p>
              <p style={{ color: "#989898", fontWeight: "500" }}>
                Shipping Cost
              </p>
            </TrackingLeft>
            <TrackingRight>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "10px" }}
              >
                <p style={{ fontWeight: "500" }}>12123</p>
                <Button
                  style={{
                    backgroundColor: "#eeebeb",
                    borderRadius: "12px",
                    padding: "0px 5px 20px 5px",
                    color: "#747474",
                    height: "10px",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  Copy
                </Button>
              </div>
              <p style={{ fontSize: "16px" }}>express</p>
              <p style={{ fontSize: "16px" }}>75</p>
            </TrackingRight>
          </InfoSection>
          <Divider />
          <InfoSection>
            <TrackingLeft>
              <p style={{ color: "#989898", fontWeight: "500" }}>Units</p>
              <p style={{ color: "#989898", fontWeight: "500" }}>
                Total Revenue
              </p>
            </TrackingLeft>
            <TrackingRight>
              <p style={{ fontWeight: "500" }}>7</p>
              <p style={{ fontWeight: "500" }}>44123</p>
            </TrackingRight>
          </InfoSection>
        </ConsignerInfo>
        <OrderInfo>
          <Container>
            <Wrapper>
              {selectedProducts.map((product, key) => {
                let rangeUsed = false;
                const { storeRequestPriceRange } = product;
                if (
                  storeRequestPriceRange &&
                  storeRequestPriceRange[1] &&
                  storeRequestPriceRange[0]
                ) {
                  rangeUsed = true;
                }
                return (
                  <ProductRow key={key}>
                    <ProductDetails>
                      <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={product?.image}
                            alt=""
                            style={{ height: "61px", width: "61px" }}
                          />
                        </div>
                        <div style={{ flex: "0 0 250px" }}>
                          <label style={{ color: "grey", fontWeight: "500" }}>
                            Name
                          </label>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "0.9em",
                              marginTop: "4px",
                              lineHeight: "1rem",
                            }}
                          >
                            {product?.title}
                          </p>
                        </div>

                        <div style={{ flex: "0 0 0px" }}>
                          <label style={{ color: "grey", fontWeight: "500" }}>
                            SKU
                          </label>
                          <SkuDivMargin>
                            <p
                              style={{
                                fontWeight: "bold",
                                fontSize: "0.9em",
                                marginTop: "4px",
                                lineHeight: "1rem",
                              }}
                            >
                              {product?.sku?.slice(0, 12)}
                              {product?.sku.length > 12 ? "..." : null}
                            </p>
                          </SkuDivMargin>
                        </div>
                        <div style={{ flex: "0 0 0px" }}>
                          <label style={{ color: "grey", fontWeight: "500" }}>
                            Size
                          </label>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "0.9em",
                              marginTop: "4px",
                              lineHeight: "1rem",
                            }}
                          >
                            {"size"}
                          </p>
                        </div>
                        <div style={{ flex: "0 0 0px" }}>
                          <label style={{ color: "grey", fontWeight: "500" }}>
                            Location
                          </label>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "0.9em",
                              marginTop: "4px",
                              lineHeight: "1rem",
                            }}
                          >
                            {"new york"}
                          </p>
                        </div>
                        <div style={{ flex: "0 0 0px" }}>
                          <label style={{ color: "grey", fontWeight: "500" }}>
                            Price
                          </label>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "0.9em",
                              marginTop: "4px",
                              lineHeight: "1rem",
                            }}
                          >
                            {getSymbolFromCurrency(store.currency)}
                            {rangeUsed
                              ? `${storeRequestPriceRange[0]} - ${storeRequestPriceRange[1]}`
                              : product.price}
                            {product?.requestedPrice && (
                              <b style={{ color: "green", display: "block" }}>
                                {getSymbolFromCurrency(store.currency)}
                                {product.requestedPrice}
                              </b>
                            )}
                          </p>
                        </div>
                        <div style={{ flex: "0 0 0px" }}>
                          <label style={{ color: "grey", fontWeight: "500" }}>
                            Payout
                          </label>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "0.9em",
                              marginTop: "4px",
                              lineHeight: "1rem",
                            }}
                          >
                            {getSymbolFromCurrency(store.currency)}
                            {product.cost
                              ? Number(+product.price - +product.cost).toFixed(
                                  1
                                )
                              : "0"}
                          </p>
                        </div>
                      </div>
                    </ProductDetails>
                  </ProductRow>
                );
              })}
            </Wrapper>
          </Container>
        </OrderInfo>
      </BodyContainer>
    </>
  );
};

export default CompleteOrder;
