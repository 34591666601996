// import type { InputRef } from "antd";
import {
  Button,
  Input,
  Popconfirm,
  Table,
  Select,
  InputNumber,
  Spin,
  message,
  Space,
} from "antd";
import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  InventoryPreapproval,
  InventoryState,
} from "../../redux/reducers/InventoryReducer";
import {
  calculateFees,
  calculateFeesFromAPI,
  checkPreapprovalStatusFromAPI,
} from "../../redux/actions/inventoryActions";
import { getDropDownValuesForConsigner } from "../../screens/InventoryItem";
import { UserState } from "../../redux/reducers/UserReducer";
import styled from "styled-components";
import { TextField, IconButton, ButtonGroup } from "@mui/material";
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  ArrowDownOutlined,
  FileAddOutlined,
  DeleteOutlined,
  DownOutlined,
} from "@ant-design/icons";
import Colors from "../../constants/Colors";
import { AppState } from "../../redux/reducers/AppReducer";
import getSymbolFromCurrency from "currency-symbol-map";
import { StoreState } from "../../redux/reducers/StoreReducer";
import { createProduct } from "../../redux/actions/productActions";

const { Option } = Select;

interface VariantsPrices {
  option1Value: string;
  option2Value?: string;
  option3Value?: string;
  price: string;
}

interface Props {
  inventoryList: any;
  setInventoryList: any;
  productTemplate: any;
  option1Value: any;
  setOption1Value: any;
  setCreatedInventory: any;
  id: string;
  lowestPrices: VariantsPrices[];
  currentInventory: any;
  setAddedInventories: any;
  selectedProducts: any;
}

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const ModalTableReportSpan = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 8px 16px;
  b {
    display: block;
    width: 100px;
    text-align: right;
  }
`;

const StyledInventoryAddModalTable = styled(Table)`
  .ant-table table {
    width: 80%;
  }
  .ant-table-thead .ant-table-cell {
    background-color: white;
    color: #2e2e2e;
  }

  .ant-table-tbody .ant-table-cell {
    vertical-align: top;
  }

  .ant-table-thead > tr > th {
    border-bottom: none;
  }

  .ant-table-tbody > tr > td {
    border-bottom: none;
  }

  .ant-table-tbody > tr > td:first-child {
    width: 40px;
  }

  .ant-table-tbody > tr > td:last-child {
    width: 40px;
  }

  .ant-table-tbody > tr > td:not(:first-child):not(:last-child) {
    width: 80px;
  }

  .ant-select-selector {
    height: 40px;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 0px;
    height: 1.6em;
    background-color: rgba(0, 0, 0, 0.06);
    transform: translateY(-50%);
    transition: background-color 0.3s;
    content: "";
  }

  .size-selector {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: none;
      background: rgba(244, 244, 244, 1);
    }
    .ant-select-arrow {
      color: rgba(116, 116, 116, 1);
    }
  }

  .ant-input-number {
    border-radius: 5px;
    border: 1px solid rgba(221, 221, 221, 1);
    width: 100%;
  }

  .ant-input-number-affix-wrapper {
    width: 100% !important;
    border-radius: 4px !important;
    height: 40px;
    align-items: center;
  }

  .ant-select .ant-select-selector {
    border-radius: 5px;
    align-items: center;
    height: 40px;
  }
`;

const ListingSpan = styled.span`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 8px auto;
  label {
    width: 100%;
    text-align: left;
    font-weight: 500;
    font-size: 12px;
    margin: 10px 0 5px;
  }
  .ant-input-number {
    width: 100%;
    padding: 8px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    padding: 8px;
    height: 40px;
    border-radius: 5px;
  }
  .ant-select-selector {
    align-items: center;
  }

  @media (max-width: 768px) {
    .ant-input-number input {
      font-size: 16px;
    }
  }
`;

const ComputationSpan = styled.span`
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border: 1px solid var(--stroke-color, #e4e4e7);
  background: var(--base-white, #fff);
  justify-content: space-between;
  p {
    color: ${Colors.CONSIGNER_GREY};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 216.667% */
    margin: 0;
  }
  b {
    color: var(--heading-color, #2e2e2e);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 216.667% */
  }
`;

const StyledInputNumber = styled(InputNumber)`
  @media (max-width: 768px) {
    .ant-input-number {
      width: 100%;
    }
    .ant-input-number-input {
      height: 20px;
    }
    .ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input {
      height: 20px;
    }
    .ant-input-number-handler-wrap {
      display: flex;
    }
  }
`;

const QuantityInput = styled.div`
  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  height: 40px;
  align-items: center;
  button {
    border: none;
    border-radius: 5px;
  }
  .ant-input-number {
    border: none;
    .ant-input-number-input {
      text-align: center;
    }
  }
`;

const Heading = styled.h2`
  color: ${Colors.CONSIGNER_BLUE};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 162.5% */
  text-transform: capitalize;
  text-align: left;
`;

const MuiInputNumber = (props: any) => {
  const { value, onChange, id, min } = props;

  const handleIncrement = () => {
    onChange(value + 1);
  };

  const handleDecrement = () => {
    const newVal = value - 1 < min ? min : value - 1;
    onChange(newVal);
  };

  return (
    <TextField
      type="number"
      value={value}
      id={id}
      onChange={(e) => onChange(Number(e.target.value))}
      InputProps={{
        startAdornment: (
          <IconButton onClick={handleDecrement}>
            <MinusCircleOutlined />
          </IconButton>
        ),
        endAdornment: (
          <IconButton onClick={handleIncrement}>
            <PlusCircleOutlined />
          </IconButton>
        ),
      }}
    />
  );
};

export const ListingInventoryView = (props: any) => {
  const {
    inventoryList,
    handleChange,
    setInventoryList,
    productTemplate,
    option1Value,
    setOption1Value,
    setCreatedInventory,
    store,
    applyStatusToAll,
    applyPriceToAll,
    consignersForDropdown,
    getVariantLowestPrice,
    handleNext,
    nextLoading,
  } = props;
  const [itemRefs, setItemRefs] = useState(
    inventoryList.map(() => createRef<HTMLDivElement>())
  );
  useEffect(() => {
    setItemRefs(inventoryList.map(() => createRef<HTMLDivElement>()));
  }, [inventoryList]);
  const scrollToNextElement = (currentIndex) => {
    if (currentIndex < inventoryList.length - 1) {
      itemRefs[currentIndex + 1].current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div>
      {inventoryList.map((record, key) => (
        <div
          key={key}
          ref={itemRefs[key]}
          style={{
            display: "flex",
            margin: "24px auto",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex", gap: "15px" }}>
            <ListingSpan>
              <label htmlFor="quantity">Quantity</label>
              {/* {isMobile ? (
                <MuiInputNumber
                  id="quantity"
                  value={record.quantity}
                  onChange={(value: any) =>
                    handleChange(value, record, "quantity")
                  }
                  min={1}
                />
              ) : ( */}
              <StyledInputNumber
                id="quantity"
                value={record.quantity}
                onChange={(value: any) =>
                  handleChange(value, record, "quantity")
                }
                min={1}
                style={{ borderRadius: "5px" }}
                controls={false}
              />
              {/* )} */}
            </ListingSpan>
            <ListingSpan>
              <label htmlFor="option2Value">Condition</label>
              {productTemplate.option2Values ? (
                <div style={{ display: "flex" }}>
                  <Select
                    style={{ flex: 1, borderRadius: "5px" }}
                    value={
                      inventoryList.find(
                        (inventory) => inventory.key === record.key
                      ).option2Value
                    }
                    onChange={(value) =>
                      handleChange(value, record, "option2Value")
                    }
                    id="option2Value"
                    filterOption={(input, option) =>
                      String(option?.children)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {productTemplate.option2Values.map(
                      (template: any, index: any) => (
                        <Option key={index} value={template}>
                          {template}
                        </Option>
                      )
                    )}
                  </Select>
                  {/* <Popconfirm
                    title="Apply this condition to all products?"
                    onConfirm={() => applyStatusToAll(record.option2Value)}
                    icon={() => null}
                    style={{ padding: "36px 10px" }}
                    okText="Continue"
                  > */}
                  {/* </Popconfirm> */}
                </div>
              ) : (
                ""
              )}
            </ListingSpan>
          </div>
          {productTemplate.option3Values &&
          productTemplate.option3Values.length > 0 ? (
            <ListingSpan>
              <label htmlFor="option3Value">Location</label>
              <Select
                style={{ width: "100%" }}
                onChange={(value) =>
                  handleChange(value, record, "option3Value")
                }
                filterOption={(input, option) =>
                  String(option?.children)
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {productTemplate.option3Values.map(
                  (template: any, index: any) => (
                    <Option key={index} value={template}>
                      {template}
                    </Option>
                  )
                )}
              </Select>
            </ListingSpan>
          ) : (
            <></>
          )}
          <div style={{ display: "flex", gap: "15px" }}>
            <ListingSpan>
              <label htmlFor="price">Price</label>
              <div style={{ display: "flex" }}>
                {/* {isMobile ? (
                  <MuiInputNumber
                    style={{flex: 1}}
                    id="price"
                    value={record.price}
                    onChange={(value: any) => handleChange(value, record, "price")}
                    min={0}
                  />
                ) : ( */}
                <StyledInputNumber
                  style={{ flex: 1, borderRadius: "5px" }}
                  id="price"
                  value={record.price}
                  prefix={getSymbolFromCurrency(store.currency)}
                  onChange={(value: any) =>
                    handleChange(value, record, "price")
                  }
                  min={0}
                />
              </div>
            </ListingSpan>
            <ListingSpan>
              <label htmlFor="cost">Cost</label>
              <div style={{ display: "flex" }}>
                {/* {isMobile ? (
                  <MuiInputNumber
                    style={{flex: 1}}
                    id="price"
                    value={record.price}
                    onChange={(value: any) => handleChange(value, record, "price")}
                    min={0}
                  />
                ) : ( */}
                <StyledInputNumber
                  style={{ flex: 1, borderRadius: "5px" }}
                  id="cost"
                  value={record.cost}
                  prefix={getSymbolFromCurrency(store.currency)}
                  onChange={(value: any) => handleChange(value, record, "cost")}
                  min={0}
                />
              </div>
            </ListingSpan>
          </div>
          <div style={{ marginTop: "20px", display: "flex", gap: "10px" }}>
            <Button
              style={{
                height: "40px",
                borderRadius: "7px",
                padding: "4px 14px 4px 8px",
              }}
            >
              {" "}
              <FileAddOutlined />
              Add Images
            </Button>
            <Button
              style={{
                background: "black",
                color: "white",
                borderRadius: "7px",
                padding: "4px 23px",
                height: "40px",
              }}
              onClick={handleNext}
              loading={nextLoading}
            >
              Next
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

const InventoryAddTable = (props: Props) => {
  const {
    inventoryList,
    setInventoryList,
    productTemplate,
    option1Value,
    setOption1Value,
    setCreatedInventory,
    id,
    lowestPrices,
    currentInventory,
    setAddedInventories,
    selectedProducts,
  } = props;

  const { calculatedFees }: InventoryState = useAppSelector(
    (state) => state.InventoryReducer
  );

  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);
  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);
  const [value, setValue] = useState(1);

  const dispatch = useAppDispatch();

  const {
    inventoryFilterOptions,
    createdInventory,
    inventoryFilterOptionsLoading,
  }: InventoryState = useAppSelector((state) => state.InventoryReducer);
  const { isMobile }: AppState = useAppSelector((state) => state.AppReducer);
  const [consignersForDropdown, setConsignersForDropdown] = useState([]);
  const [nextLoading, setNextLoading] = useState(false);

  const checkPreapprovalStatus = (
    option1Value = "",
    option2Value = "",
    option3Value = ""
  ): { min: number; max: number; preapproval: boolean } => {
    const { minApproval: min, maxApproval: max, autoPreapproval } = store;
    let minValue = 0,
      maxValue = 0;
    if (!autoPreapproval) return { min: 0, max: 0, preapproval: false };
    const record = lowestPrices.find(
      (variant) =>
        variant.option1Value === option1Value &&
        variant.option2Value ===
          option2Value /* && variant.option3Value === option3Value */
    );
    if (!record) {
      return {
        preapproval: autoPreapproval,
        min: 0,
        max: 0,
      };
    }
    const price = Number(record?.price ?? 0);
    minValue = price - Number(min);
    maxValue = price + Number(max);

    if (Number(min) === 0 && Number(max) === 0)
      return { preapproval: true, min: minValue, max: maxValue };
    const preapproval =
      price + Number(max) >= price && price - Number(min) <= price;

    return {
      preapproval,
      min: minValue,
      max: maxValue,
    };
  };

  const checkForPriceAlert = (
    option1Value = "",
    option2Value = "",
    option3Value = "",
    price = 0
  ): boolean => {
    if (Number(store.warnAmount) === 0) return false;
    const prc =
      lowestPrices.find(
        (variant) =>
          variant.option1Value === option1Value &&
          variant.option2Value ===
            option2Value /*  && variant.option3Value === option3Value */
      )?.price ?? 0;
    const warnAmount = Number(store?.warnAmount ?? 0);
    if (+prc - warnAmount <= 0 || prc === 0) return false;
    if (price > +prc - warnAmount) return true;
    return false;
  };

  const fetchPreapprovalData = useMemo(() => {
    const cache = new Map();

    return async (id, price, opt1, opt2, opt3, con) => {
      if (process.env.REACT_APP_TYPE === "employee") {
        // items added by admin are always preapproved
        return { min: 0, max: 0, preapproval: true };
      }
      const consigner = JSON.parse(con); //consigner object is too long
      const cacheKey = `${id}_${price}_${opt1}_${opt2}_${consigner.id}`;
      if (cache.has(cacheKey)) {
        console.info("Using cached data", cacheKey);
        return cache.get(cacheKey);
      }

      try {
        /* const data = await checkPreapprovalStatusFromAPI(
            id,
            price,
            opt1,
            opt2,
            opt3,
            con
          ); */
        const data = checkPreapprovalStatus(opt1, opt2, opt3);
        cache.set(cacheKey, data);
        return data;
      } catch (error) {
        console.warn("Error fetching data: ", error);
      }
    };
  }, [lowestPrices]);

  const applyStatusToAll = async (option2Value: string, key = 0) => {
    const newData = await Promise.all(
      inventoryList.map(async (updateItem) => {
        if (updateItem.option2Value !== option2Value) {
          const preapproval = await fetchPreapprovalData(
            id,
            Number(updateItem.price),
            updateItem.option1Value,
            option2Value,
            updateItem.option3Value,
            updateItem.consigner
          );
          const priceAlert = checkForPriceAlert(
            updateItem.option1Value,
            option2Value,
            updateItem.option3Value,
            updateItem.price
          );
          const upData = {
            ...updateItem,
            option2Value,
            min: preapproval?.min ?? 0,
            max: preapproval?.max ?? 0,
            priceAlert,
          };
          return upData;
        }
        return updateItem;
      })
    );
    setInventoryList(newData);
  };

  const fetchFees = useMemo(() => {
    const cache = new Map();

    return async (id, price, qty, opt1, opt2, opt3, cost, cat, con) => {
      const consigner = JSON.parse(con); //consigner object is too long
      const cacheKey = `${id}_${price}_${qty}_${opt1}_${opt2}_${opt3}_${cost}_${cat}_${consigner.id}`;

      if (cache.has(cacheKey)) {
        console.info("Using cached data", cacheKey);
        return cache.get(cacheKey);
      }

      try {
        const data = await calculateFeesFromAPI(
          id,
          price,
          qty,
          opt1,
          opt2,
          opt3,
          cost,
          cat,
          con
        );
        cache.set(cacheKey, data);
        return data;
      } catch (error) {
        console.warn("Error fetching data: ", error);
      }
    };
  }, []);

  const applyPriceToAll = async (price = 0, key = 0) => {
    const newData = await Promise.all(
      inventoryList.map(async (updateItem) => {
        if (Number(updateItem.price) !== price) {
          let cost = 0;
          let consigner;
          if (updateItem.consigner) {
            consigner = JSON.parse(updateItem.consigner);
            if (consigner.isStoreAccount && updateItem.payout) {
              cost = updateItem.payout;
              updateItem.cost = updateItem.payout;
            }
          }
          const calculatedFee = await fetchFees(
            updateItem.id,
            price,
            updateItem.quantity,
            updateItem.option1Value,
            updateItem.option2Value,
            updateItem.option3Value,
            Number(cost),
            updateItem.category,
            updateItem.consigner
          );
          const payout = String(calculatedFee?.payout ?? 0);
          const payoutFee = String(calculatedFee?.payoutFee ?? 0);
          const total = String(calculatedFee?.total ?? 0);
          const priceAlert = checkForPriceAlert(
            updateItem.option1Value,
            updateItem.option2Value,
            updateItem.option3Value,
            price
          );
          const upData = {
            ...updateItem,
            price,
            payout,
            payoutFee,
            priceAlert,
            total,
          };
          return upData;
        }
        return updateItem;
      })
    );
    setInventoryList(newData);
  };

  useEffect(() => {
    setCreatedInventory(createdInventory);
  }, [createdInventory]);

  useEffect(() => {
    let result: any;
    if (process.env.REACT_APP_TYPE === "consigner" && dbUser) {
      let consigner = [
        {
          label: `${dbUser.firstName} ` + `${dbUser.lastName} `,
          value: dbUser,
        },
      ];
      result = getDropDownValuesForConsigner(consigner);
    } else if (inventoryFilterOptions && inventoryFilterOptions.consigners) {
      result = getDropDownValuesForConsigner(inventoryFilterOptions.consigners);
    }
    setConsignersForDropdown((res) => result);
  }, [inventoryFilterOptions]);

  //add new row when option1Value changes
  useEffect(() => {
    if (option1Value && dbUser) {
      let defaultConsigner = [
        {
          label: `${dbUser.firstName} ` + `${dbUser.lastName} `,
          value: dbUser,
        },
      ];
      if (process.env.REACT_APP_TYPE === "employee") {
        defaultConsigner = inventoryFilterOptions.consigners.filter(
          (consigner: any) => consigner.value.isStoreAccount === true
        );
      }
      let formatedConsigner = getDropDownValuesForConsigner(defaultConsigner);
      const newData = {
        key: count,
        quantity: 1,
        option1Value,
        option2Value: "",
        option3Value: "",
        payoutFee: 0,
        price: 0,
        cost: 0,
        status: "Active",
        consigner:
          formatedConsigner?.length && formatedConsigner[0].value
            ? formatedConsigner[0].value
            : "",
        category: productTemplate?.category,
      };
      let tableInventory: any;
      if (
        inventoryList.length &&
        inventoryList.find((inv) => inv.id === currentInventory.id)
      ) {
        tableInventory = inventoryList.map((inv: any) => {
          if (inv.id === currentInventory.id) {
            return {
              ...inv,
              invArray: [
                ...(inv.invArray || []), // Ensure invArray is an array or default to an empty array
                newData, // Adding newData to the invArray
              ],
            };
          }
          return inv; // Return unchanged item if condition doesn't match
        });
      } else {
        tableInventory = selectedProducts.map((inv: any) => {
          if (inv.id === currentInventory.id) {
            return {
              ...inv,
              invArray: [
                ...(inv.invArray || []), // Ensure invArray is an array or default to an empty array
                newData, // Adding newData to the invArray
              ],
            };
          }
          return inv; // Return unchanged item if condition doesn't match
        });
      }
      console.log(tableInventory, "==============tableInventory==========");
      console.log(inventoryList, "==============currentInventory==========");
      setInventoryList([...tableInventory]);
      setCount(count + 1);
      setOption1Value("");
    }
  }, [option1Value]);

  const [updatedValue, setUpdatedValue] = useState<any>({});
  const [variableChange, setVariableChange] = useState("");
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (updatedValue && inventoryList.length > 0) {
      // Find the specific inventory to update by id
      const updatedInventoryList = inventoryList.map((inv) => {
        if (inv.id === currentInventory.id) {
          // Make a shallow copy of invArray
          const newInvArray = [...(inv.invArray || [])];
          const index = newInvArray.findIndex(
            (item) => updatedValue.key === item.key
          );

          if (index !== -1) {
            const item = newInvArray[index];

            // Re-fetch fees if needed
            if (
              variableChange === "price" ||
              variableChange === "quantity" ||
              variableChange === "payout" ||
              (variableChange === "consigner" && updatedValue.price !== 0)
            ) {
              if (calculatedFees && calculatedFees.payout) {
                updatedValue.payout = String(calculatedFees.payout ?? 0);
                updatedValue.payoutFee = String(calculatedFees.payoutFee ?? 0);
                updatedValue.total = String(calculatedFees.total ?? 0);
              }
            }

            // Check for price alert
            if (variableChange === "price") {
              const priceAlert = checkForPriceAlert(
                item.option1Value,
                item.option2Value,
                item.option3Value,
                updatedValue.price
              );
              updatedValue.priceAlert = priceAlert;
            }

            // Replace the item with updated values
            newInvArray.splice(index, 1, {
              ...item,
              ...updatedValue,
            });
          }

          // Return updated inventory object
          return {
            ...inv,
            invArray: newInvArray,
          };
        }

        // Return unchanged inventory if it's not the one we're updating
        return inv;
      });

      // Set the updated inventory list
      setInventoryList(updatedInventoryList);
    }
  }, [updatedValue, calculatedFees]);

  const handleDelete = (key: React.Key) => {
    // Map through the inventoryList and update only the matching inventory's invArray
    const updatedInventoryList = inventoryList.map((inv) => {
      if (inv.id === currentInventory.id) {
        // Filter out the item with the matching key in the invArray
        const updatedInvArray = inv.invArray.filter(
          (item: any) => item.key !== key
        );

        // Return the updated inventory with the filtered invArray
        return {
          ...inv,
          invArray: updatedInvArray,
        };
      }
      // Return unchanged inventories
      return inv;
    });

    // Update the state with the modified inventoryList
    setInventoryList(updatedInventoryList);
  };

  const handleApplyAll = (key: React.Key, variable, value) => {
    // Map through the inventoryList and update only the matching inventory's invArray
    const updatedInventoryList = inventoryList.map((inv) => {
      if (inv.id === currentInventory.id) {
        // Filter out the item with the matching key in the invArray
        const updatedInvArray = inv.invArray.map((item: any) => ({
          ...item,
          [variable]: value,
        }));

        // Return the updated inventory with the filtered invArray
        return {
          ...inv,
          invArray: updatedInvArray,
        };
      }
      // Return unchanged inventories
      return inv;
    });

    // Update the state with the modified inventoryList
    setInventoryList(updatedInventoryList);
  };

  const increment = (record: any) => {
    const newValue = record.quantity + 1;
    // setValue(newValue);
    handleChange(newValue, record, "quantity");
  };

  const decrement = (record: any) => {
    const newValue = record.quantity > 1 ? record.quantity - 1 : 1; // Prevent going below 1
    // setValue(newValue);
    handleChange(newValue, record, "quantity");
  };

  const handleChange = async (
    value: any,
    record: any,
    variableChanged: any
  ) => {
    const updateItem = {
      ...record,
      [variableChanged]: value,
    };

    let cost = 0;
    let consigner;
    let preapprovalData: InventoryPreapproval;
    if (updateItem.consigner) {
      consigner = JSON.parse(updateItem.consigner);
      if (consigner.isStoreAccount && updateItem.payout) {
        cost = updateItem.payout;
        updateItem.cost = updateItem.payout;
      }
    }

    if (
      variableChanged === "option2Value" &&
      process.env.REACT_APP_TYPE === "consigner"
    ) {
      preapprovalData = await fetchPreapprovalData(
        id,
        Number(updateItem.price),
        updateItem.option1Value,
        updateItem.option2Value,
        updateItem.option3Value,
        JSON.stringify(updateItem.consigner ?? dbUser)
      );
      updateItem.min = preapprovalData.min;
      updateItem.max = preapprovalData.max;
    }
    if (variableChange === "price") {
      const priceAlert = checkForPriceAlert(
        updateItem.option1Value,
        updateItem.option2Value,
        updateItem.option3Value,
        updateItem.price
      );
      updateItem.priceAlert = priceAlert;
    }

    if (
      (variableChanged == "price" ||
        variableChanged == "quantity" ||
        variableChanged == "payout" ||
        (variableChanged == "consigner" && record.price !== 0)) &&
      updateItem.consigner
    ) {
      dispatch(
        calculateFees(
          updateItem.id,
          Number(updateItem.price),
          updateItem.quantity,
          updateItem.option1Value,
          updateItem.option2Value,
          updateItem.option3Value,
          Number(cost),
          updateItem.category,
          updateItem.consigner
        )
      );
    }
    setVariableChange(variableChanged);
    setUpdatedValue(updateItem);
  };

  const { Search } = Input;

  const columns: //ColumnsType<DataType> = [
  (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: "Size",
      dataIndex: "option1Value",
      width: "10%",
      render: (_: any, record: any) => (
        <>
          {productTemplate.option1Values ? (
            <div
              style={{ display: "flex", width: "100%" }}
              className="size-selector"
            >
              <Select
                // style={{ flex: 1 }}
                value={record.option1Value}
                style={{ width: "100%" }}
                onChange={(value) =>
                  handleChange(value, record, "option1Value")
                }
                defaultValue={record.option1Value}
                filterOption={(input, option) =>
                  String(option?.children)
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {productTemplate.option1Values.map(
                  (template: any, index: any) => (
                    <Option key={index} value={template}>
                      {template}
                    </Option>
                  )
                )}
              </Select>
              {/* <Button
                style={{ flex: "0 0 48px", height: "auto" }}
                type="primary"
                icon={<ArrowDownOutlined />}
                onClick={() =>
                  applyStatusToAll(record.option2Value, record.key)
                }
              ></Button> */}
            </div>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      width: "15%",
      render: (_: any, record: any) => (
        <QuantityInput>
          <Button onClick={() => decrement(record)}>-</Button>
          <InputNumber
            value={record.quantity}
            onChange={(value: any) => handleChange(value, record, "quantity")}
            min={1}
            controls={false}
          />
          <Button onClick={() => increment(record)}>+</Button>
        </QuantityInput>
      ),
    },
    {
      title: "Condition",
      dataIndex: "option2Value",
      width: "18%",
      render: (_: any, record: any) => (
        <>
          {productTemplate.option2Values ? (
            <div style={{ display: "flex", width: "100%" }}>
              <Select
                // style={{ flex: 1 }}
                value={record.option2Value}
                style={{ width: "100%" }}
                onChange={(value) =>
                  handleChange(value, record, "option2Value")
                }
                filterOption={(input, option) =>
                  String(option?.children)
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {productTemplate.option2Values.map(
                  (template: any, index: any) => (
                    <Option key={index} value={template}>
                      {template}
                    </Option>
                  )
                )}
              </Select>
              <div
                style={{
                  padding: "10px",
                  border: "1px solid #d9d9d9",
                  borderRadius: "2px",
                  height: "40px",
                  background: "#fafafa",
                }}
              >
                <DownOutlined
                  onClick={() =>
                    handleApplyAll(
                      record.key,
                      "option2Value",
                      record.option2Value
                    )
                  }
                />
              </div>
              {/* <Button
                style={{ flex: "0 0 48px", height: "auto" }}
                type="primary"
                icon={<ArrowDownOutlined />}
                onClick={() =>
                  applyStatusToAll(record.option2Value, record.key)
                }
              ></Button> */}
            </div>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      width: "15%",
      render: (_: any, record: any) => (
        <div style={{ position: "relative" }}>
          <div style={{ display: "flex" }}>
            <StyledInputNumber
              value={record.price}
              onChange={(value: any) => handleChange(value, record, "price")}
              prefix={getSymbolFromCurrency(store.currency)}
              controls={false}
              min={0}
              addonAfter={
                <DownOutlined
                  onClick={() =>
                    handleApplyAll(record.key, "price", record.price)
                  }
                />
              }
            />
            {/* <Button
              style={{ flex: "0 0 48px", height: "auto" }}
              type="primary"
              icon={<ArrowDownOutlined />}
              onClick={() => applyPriceToAll(record.price, record.key)}
            ></Button> */}
          </div>
          {process.env.REACT_APP_TYPE === "consigner" &&
            (+record.min > 0 || +record.max > 0) && (
              <p
                style={{
                  color: "var(--input-field, rgba(88, 85, 85, 0.30))",
                  textAlign: "left",
                  fontSize: 12,
                  fontStyle: "normal",
                  fontWeight: 400,
                  marginTop: 2,
                  marginBottom: 0,
                  opacity: record.min > 0 || record.max > 0 ? 1 : 0,
                }}
              >
                Preapproval Range: {getSymbolFromCurrency(store.currency)}
                {record.min}-{record.max}
              </p>
            )}
          {process.env.REACT_APP_TYPE === "consigner" && record.priceAlert && (
            <p
              style={{
                textAlign: "left",
                fontSize: 12,
                fontStyle: "normal",
                fontWeight: 400,
                marginTop: 2,
                marginBottom: 0,
                color: "goldenrod",
                padding: 3,
              }}
            >
              {store.warnText} Instant accepts at (
              {getSymbolFromCurrency(store.currency)}
              {getVariantLowestPrice(
                record.option1Value,
                record.option2Value,
                record.option3Value
              )}
              )
            </p>
          )}
        </div>
      ),
    },
    {
      title: "Cost",
      dataIndex: "cost",
      fixed: "right",
      width: "15%",
      render: (_: any, record: any) => (
        <StyledInputNumber
          disabled={process.env.REACT_APP_TYPE === "consigner"}
          value={record.cost}
          controls={false}
          onChange={(value: any) => handleChange(value, record, "cost")}
          prefix={getSymbolFromCurrency(store.currency)}
          addonAfter={
            <DownOutlined
              onClick={() => handleApplyAll(record.key, "cost", record.cost)}
            />
          }
        />
      ),
    },
    {
      title: "",
      dataIndex: "operation",
      width: "10%",
      render: (_: any, record: any) =>
        inventoryList.length >= 1 ? (
          <div style={{ textAlign: "center", alignContent: "center" }}>
            <DeleteOutlined onClick={() => handleDelete(record.key)} />
          </div>
        ) : null,
    },
  ];

  let visibleColumns = columns;

  if (productTemplate && !productTemplate.option2 && !productTemplate.option3) {
    visibleColumns = columns.filter(
      (col) =>
        col.dataIndex !== "option2Value" && col.dataIndex !== "option3Value"
    );
  } else if (productTemplate && !productTemplate.option3) {
    visibleColumns = columns.filter((col) => col.dataIndex !== "option3Value");
  }
  if (process.env.REACT_APP_TYPE === "consigner") {
    visibleColumns = visibleColumns.filter(
      (col) => col.dataIndex !== "consigner" && col.dataIndex !== "payout"
    );
  }

  useEffect(() => {
    if (!selectedProducts.length) {
      setInventoryList([]);
    }
  }, [selectedProducts]);

  const handleNext = async () => {
    setNextLoading(true);
    const currentInvIndex = inventoryList.findIndex(
      (inv) => inv.id === currentInventory.id
    );

    if (currentInvIndex === -1) {
      message.error("Inventory not found");
      return;
    }

    const currentInv = inventoryList[currentInvIndex];

    const allFieldsFilled = currentInv?.invArray?.every((inv) => inv.price > 0);

    let createdProduct;
    if (allFieldsFilled) {
      // If product doesn't have a storeId, create the product
      if (!currentInventory.storeId) {
        const payload = {
          title: currentInventory.title,
          brand: currentInventory?.brand,
          category: currentInventory?.category,
          sku: currentInventory?.sku,
          images: currentInventory?.image,
          productTemplateId: productTemplate.id,
          image: currentInventory?.image,
          gender: currentInventory?.gender,
          GTIN: currentInventory?.GTIN,
          stockXId: currentInventory?.stockXId,
          shopifyId: currentInventory?.id,
        };
        createdProduct = await dispatch(createProduct(payload));
      }

      const addedInventory = currentInv?.invArray?.map((inv) => ({
        title: currentInventory.title,
        image: currentInventory.image,
        productId: currentInventory.storeId
          ? currentInventory.id
          : createdProduct.id,
        ...inv,
      }));

      // Update the inventoryList with the addedInventory for the specific inventory
      const updatedInventoryList = inventoryList.map((inv) =>
        inv.id === currentInventory.id
          ? { ...inv, invArray: addedInventory }
          : inv
      );

      setInventoryList(updatedInventoryList);
      setAddedInventories(addedInventory);
      setNextLoading(false);
    } else {
      message.error("Please ensure all fields are filled");
    }
  };

  const getVariantLowestPrice = (
    option1Value = "",
    option2Value = "",
    option3Value = ""
  ): number => {
    const prc =
      lowestPrices.find(
        (variant) =>
          variant.option1Value === option1Value &&
          variant.option2Value ===
            option2Value /*  && variant.option3Value === option3Value */
      )?.price ?? 0;
    return Number(prc) - Number(store.warnAmount);
  };

  if (inventoryFilterOptionsLoading) return <Spin />;

  return (
    <div>
      {isMobile ? (
        <ListingInventoryView
          inventoryList={
            inventoryList.find((inv) => currentInventory.id === inv.id)
              ?.invArray
          }
          handleChange={handleChange}
          productTemplate={productTemplate}
          store={store}
          applyStatusToAll={applyStatusToAll}
          applyPriceToAll={applyPriceToAll}
          consignersForDropdown={consignersForDropdown}
          getVariantLowestPrice={getVariantLowestPrice}
          handleNext={handleNext}
          nextLoading={nextLoading}
        />
      ) : (
        <>
          <StyledInventoryAddModalTable
            className="InventoryAddModalTable"
            dataSource={
              inventoryList.find((inv) => currentInventory.id === inv.id)
                ?.invArray
            }
            columns={visibleColumns as ColumnTypes}
            size="small"
            pagination={false}
          />
          {inventoryList.length > 0 && (
            <div style={{ marginTop: "20px", display: "flex", gap: "10px" }}>
              <Button
                style={{
                  height: "40px",
                  borderRadius: "7px",
                  padding: "4px 14px 4px 8px",
                }}
              >
                {" "}
                <FileAddOutlined />
                Add Images
              </Button>
              <Button
                style={{
                  background: "black",
                  color: "white",
                  borderRadius: "7px",
                  padding: "4px 23px",
                  height: "40px",
                }}
                onClick={handleNext}
                loading={nextLoading}
              >
                Next
              </Button>
            </div>
          )}
        </>
      )}
      {/* {inventoryList.every((inventory) => inventory.total) && (
        <ModalTableReportSpan>
          Total Payout:
          <b
            style={{
              color: "#2E2E2E",
              fontSize: 12,
            }}
          >
            {getSymbolFromCurrency(store.currency)}
            {inventoryList
              .reduce((sum, inventory) => sum + Number(inventory.total), 0)
              .toFixed(2)}
          </b>
        </ModalTableReportSpan>
      )} */}
    </div>
  );
};
export default InventoryAddTable;
