import React, { useState } from "react";
import { CustomModal, StyledSelect } from "./inventoryStyle";
import { Button, Col, Input, Row, Select, message } from "antd";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { DeleteOutlined } from "@ant-design/icons";
import AddImages from "./AddImages";
import {
  clearSelectedProduct,
  removeSelectedProduct,
  updateSelectedProduct,
} from "../../redux/actions/productActions";
import { createInventories } from "../../redux/actions/inventoryActions";
import InvoiceModal from "./InvoiceModal";
import { createInvoice } from "../../redux/actions/invoiceActions";

const { Option } = Select;

interface ProductModalProp {
  productModal: () => void;
  isProductModal: boolean;
}
export default function ProductModal({
  productModal,
  isProductModal,
}: ProductModalProp) {
  const { selectedProducts } = useAppSelector((state) => state.ProductReducer);
  const { store } = useAppSelector((state) => state.StoreReducer);
  const [isImageModal, setIsImageModal] = useState<boolean>(false);
  const handleCancel = () => {
    productModal();
    dispatch(clearSelectedProduct()); // Clear selected products after successful creation
  };
  const dispatch = useAppDispatch();
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0);
  const [isDropOffPending, setIsDropOffPending] = useState(false);
  const [isCreateInvoice, setIsCreateInvoice] = useState(false);
  const [isPayoutRequired, setIsPayoutRequired] = useState(false);
  const [shippingCost, setShippingCost] = useState<number>(0);
  const [seller, setSeller] = useState<string>("");
  const [isInvoiceModal, setIsInvoiceModal] = useState(false);
  const [invoiceTrackingCode, setInvoiceTrackingCode] = useState("");
  const [invoiceId, setInvoiceId] = useState();

  const [formValues, setFormValues] = useState({
    quantity: null,
    condition: "Select",
    price: null,
    cost: null,
  });

  const handleOk = async () => {
    let updatedProducts = [...selectedProducts];

    // Check if all required fields are filled
    const missingFields = updatedProducts.some((product) => {
      return (
        !product.quantity ||
        !product.price ||
        !product.cost ||
        !product.option2Value
      );
    });

    if (missingFields) {
      message.error(
        "Please ensure all products have quantity, price, condition and cost."
      );
      return;
    }
    const totalQuantity = selectedProducts.reduce(
      (sum, product) => sum + product.quantity,
      0
    );

    const shippingCostPerItem = shippingCost / totalQuantity;

    if (isDropOffPending || isPayoutRequired) {
      updatedProducts = updatedProducts.map((product) => ({
        ...product,
        price: Number(product.price) + shippingCostPerItem,
        status: isDropOffPending
          ? "Pending"
          : isPayoutRequired
          ? "Buying"
          : product.status,
        Seller: seller,
        shippingCost: shippingCost,
        invoiceId: invoiceId,
      }));
    } else {
      updatedProducts = updatedProducts.map((product) => ({
        ...product,
        price: Number(product.price) + shippingCostPerItem,
        status: "Active",
        Seller: seller,
        shippingCost: shippingCost,
        invoiceId: invoiceId,
      }));
    }
    try {
      await dispatch(createInventories(updatedProducts));
      message.success("Products created successfully!");
      await dispatch(clearSelectedProduct());
      setTimeout(() => {
        // setIsInvoiceModal(true);
        productModal();
      }, 1000);
    } catch (error) {
      message.error("An error occurred while creating products.");
      console.error(error);
    }
  };

  const handleRowClick = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };
  const handleNextIndex = (item, index) => {
    if (
      formValues.quantity &&
      formValues.condition &&
      formValues.price &&
      formValues.cost
    ) {
      if (selectedRowIndex === selectedProducts.length - 1) {
        const quantity = Number(formValues.quantity);
        const price = Number(formValues.price);
        const cost = Number(formValues.cost);
        // Check if the selectedProducts array has an element at the specified index
        if (index >= 0 && index < selectedProducts.length) {
          const productsToUpdate = selectedProducts[index];
          const updatedProduct = {
            ...productsToUpdate,
            quantity: isNaN(quantity) ? productsToUpdate.quantity : quantity,
            option2Value: formValues.condition, // Assuming this is a string selection
            price: isNaN(price) ? productsToUpdate.price : price,
            cost: isNaN(cost) ? productsToUpdate.cost : cost,
          };

          dispatch(updateSelectedProduct(updatedProduct, index));
        } else {
          console.error(`Invalid index ${index} for selectedProducts array.`);
        }
        setFormValues({
          quantity: null,
          condition: "Select",
          price: null,
          cost: null,
        });
      } else {
        const quantity = Number(formValues.quantity);
        const price = Number(formValues.price);
        const cost = Number(formValues.cost);
        // Check if the selectedProducts array has an element at the specified index
        if (index >= 0 && index < selectedProducts.length) {
          const productsToUpdate = selectedProducts[index];
          const updatedProduct = {
            ...productsToUpdate,
            quantity: isNaN(quantity) ? productsToUpdate.quantity : quantity,
            option2Value: formValues.condition, // Assuming this is a string selection
            price: isNaN(price) ? productsToUpdate.price : price,
            cost: isNaN(cost) ? productsToUpdate.cost : cost,
          };

          dispatch(updateSelectedProduct(updatedProduct, index));
        } else {
          console.error(`Invalid index ${index} for selectedProducts array.`);
        }
        setFormValues({
          quantity: null,
          condition: "Select",
          price: null,
          cost: null,
        });
        setSelectedRowIndex(selectedRowIndex + 1);
      }
    } else {
      message.error("Please select missing fields");
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleSelectChange = (value) => {
    setFormValues({
      ...formValues,
      condition: value,
    });
  };
  const handleDeleteProduct = (item) => {
    dispatch(removeSelectedProduct(item.id, item.option1Value.trim()));
  };

  const handleShippingCost = (e) => {
    const shippingCost = Number(e.target.value);
    if (
      selectedProducts.some((product) => !product.quantity || !product.price)
    ) {
      message.error("Please ensure all products have a quantity and price.");
      return;
    }
    setShippingCost(shippingCost);
  };
  const formatPrice = (price) => {
    // Check if the price has a decimal part
    return price % 1 !== 0 ? price.toFixed(2) : price;
  };
  function generateTrackingId(): string {
    const timestamp = Date.now().toString(); // Current timestamp in milliseconds
    const randomString = Math.random().toString(36).substring(2, 10); // Random string
    return `#INV-${timestamp}-${randomString}`;
  }

  const handleCreateInvoice = () => {
    setIsCreateInvoice((prevState) => {
      const newState = !prevState;

      if (newState) {
        const trackingCode = generateTrackingId();

        dispatch(
          createInvoice({ trackingCode, invoiceDate: new Date().toISOString() })
        )
          .then((response) => {
            console.log(
              "Invoice created successfully:",
              response?.invoice.trackingCode
            );
            setInvoiceTrackingCode(response?.invoice?.trackingCode);
            setInvoiceId(response?.invoice?.id);
          })
          .catch((error) => {
            console.error("Error creating invoice:", error);
          });
      }

      return newState;
    });
  };

  return (
    <>
      <InvoiceModal
        isInvoiceModal={isInvoiceModal}
        shippingCost={shippingCost}
        sellerName={seller}
        invoiceTrackingCode={invoiceTrackingCode}
        closeInvoiceModal={() => setIsInvoiceModal(false)}
      />
      {isImageModal && (
        <AddImages
          isModalVisible={isImageModal}
          setIsModalVisible={() => setIsImageModal(false)}
        />
      )}
      <CustomModal
        title="Add Inventory"
        visible={isProductModal}
        onCancel={() => handleCancel()}
        width={1200}
        footer={[
          // eslint-disable-next-line react/jsx-key
          <div style={{ display: "flex" }}>
            <Button
              key="back"
              type="primary"
              style={{
                padding: 8,
                width: 90,
                height: 52,
                borderRadius: "8px",
                border: "1px solid #DDDDDD",
                color: "black",
                background: "#F4F4F4",
              }}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              style={{
                padding: "8px 10px",
                height: 52,
                borderRadius: "8px",
                background: "black",
                right: "2%",
                position: "absolute",
              }}
              onClick={() => handleOk()}
            >
              Add Product
            </Button>
          </div>,
        ]}
      >
        <div style={{ overflowY: "auto" }}>
          <div>
            {selectedProducts?.map((item, index) => (
              <React.Fragment key={index}>
                <Row
                  gutter={1}
                  onClick={() => handleRowClick(index)}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: "8px",
                    border: "1px solid #DDDDDD",
                    padding: "8px",
                    background:
                      index === selectedRowIndex ? "transparent" : "#F9F9F9",
                    marginBottom: "1.5rem",
                    cursor: "pointer",
                  }}
                >
                  <Col span={2}>
                    <img
                      data-testid="image"
                      src={item.image}
                      height={70}
                      width={74}
                      style={{ borderRadius: "7px" }}
                    />
                  </Col>
                  <Col span={6} style={{ display: "grid" }}>
                    <span style={{ color: "#989898", fontWeight: "500" }}>
                      Title
                    </span>
                    <span>{item.title}</span>
                  </Col>
                  <Col span={4} style={{ display: "grid" }}>
                    <span style={{ color: "#989898", fontWeight: "500" }}>
                      SKU
                    </span>
                    <span>{item.sku}</span>
                  </Col>
                  <Col span={2} style={{ display: "grid" }}>
                    <span style={{ color: "#989898", fontWeight: "500" }}>
                      Size
                    </span>
                    <span>{item.option1Value}</span>
                  </Col>
                  <Col span={2} style={{ display: "grid" }}>
                    <span style={{ color: "#989898", fontWeight: "500" }}>
                      Quantity
                    </span>
                    <span>{item.quantity}</span>
                  </Col>
                  <Col span={2} style={{ display: "grid" }}>
                    <span style={{ color: "#989898", fontWeight: "500" }}>
                      Condition
                    </span>
                    <span>{item.option2Value}</span>
                  </Col>
                  <Col span={2} style={{ display: "grid" }}>
                    <span style={{ color: "#989898", fontWeight: "500" }}>
                      Price
                    </span>
                    <span>{formatPrice(Number(item.price))}</span>
                  </Col>
                  <Col span={2} style={{ display: "grid" }}>
                    <span style={{ color: "#989898", fontWeight: "500" }}>
                      Cost
                    </span>
                    <span>{item.cost}</span>
                  </Col>
                  <Col
                    span={1}
                    style={{
                      padding: "8px",
                      cursor: "pointer",
                      borderRadius: "3px",
                      border: "1px solid #DDDDDD",
                      background: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DeleteOutlined
                      style={{ display: "flex", justifyContent: "end" }}
                      onClick={() => handleDeleteProduct(item)}
                    />
                  </Col>
                </Row>
                {selectedRowIndex === index && (
                  <Row
                    gutter={10}
                    style={{
                      marginBottom: "1.5rem",
                      padding: "8px",
                      borderRadius: "8px",
                      alignItems: "center",
                    }}
                  >
                    <Col span={4} style={{ display: "grid" }}>
                      <span style={{ fontWeight: "500", marginBottom: "3px" }}>
                        Quantity
                      </span>
                      <Input
                        style={{ borderRadius: "8px", height: "48px" }}
                        type="number"
                        value={formValues?.quantity ?? item?.quantity}
                        onChange={handleChange}
                        name="quantity"
                        required
                      />
                    </Col>
                    <Col span={4} style={{ display: "grid" }}>
                      <span style={{ fontWeight: "500", marginBottom: "3px" }}>
                        Condition
                      </span>
                      <StyledSelect
                        onChange={handleSelectChange}
                        value={item.option2Value}
                      >
                        <Option value="New">New</Option>
                        <Option value="Used">Used</Option>
                        <Option value="Old">Old</Option>
                      </StyledSelect>
                    </Col>
                    <Col span={4} style={{ display: "grid" }}>
                      <span style={{ fontWeight: "500", marginBottom: "3px" }}>
                        Price
                      </span>
                      <Input
                        style={{ borderRadius: "8px", height: "48px" }}
                        type="number"
                        prefix={store?.currency}
                        value={formValues?.price ?? item?.price}
                        onChange={handleChange}
                        name="price"
                      />
                    </Col>
                    <Col span={4} style={{ display: "grid" }}>
                      <span style={{ fontWeight: "500", marginBottom: "3px" }}>
                        Cost
                      </span>
                      <Input
                        style={{ borderRadius: "8px", height: "48px" }}
                        type="number"
                        prefix={store?.currency}
                        value={formValues?.cost ?? item?.cost}
                        onChange={handleChange}
                        name="cost"
                      />
                    </Col>
                    <Col
                      span={4}
                      style={{ display: "grid", justifyContent: "center" }}
                    >
                      <span
                        style={{ marginBottom: "3px", visibility: "hidden" }}
                      >
                        .
                      </span>
                      <span
                        style={{
                          cursor: "pointer",
                          color: "black",
                        }}
                        onClick={() => setIsImageModal(true)}
                      >
                        Add Images
                      </span>
                    </Col>
                    <Col
                      span={4}
                      style={{ display: "grid", justifyContent: "center" }}
                    >
                      <span
                        style={{ marginBottom: "3px", visibility: "hidden" }}
                      >
                        .
                      </span>
                      <Button
                        type="primary"
                        style={{
                          height: "52px",
                          borderRadius: "8px",
                          background: "black",
                          width: "100px",
                        }}
                        onClick={() => handleNextIndex(item, index)}
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                )}
              </React.Fragment>
            ))}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              key="submit"
              type="primary"
              style={{
                padding: "8px 30px",
                color: "black",
                height: 52,
                borderRadius: "8px",
                background: "white",
                borderBlockColor: "black",
                display: "flex",
                alignItems: "center",
                marginTop: "2rem",
                marginBottom: "20px",
              }}
              onClick={() => productModal()}
            >
              + Add Products
            </Button>
          </div>
        </div>
        <hr></hr>
        <div
          style={{
            display: "flex",
            marginTop: "2rem",
            alignItems: "center",
            gap: "20px",
            marginBottom: "20px",
          }}
        >
          <div style={{ display: "grid" }}>
            <span
              style={{ fontWeight: "500", marginBottom: "3px", width: "400px" }}
            >
              Seller name
            </span>
            <Input
              style={{ borderRadius: "8px", height: "48px" }}
              type="text"
              placeholder="Seller Name"
              onChange={(e) => setSeller(e.target.value)}
            />
          </div>

          <div style={{ display: "grid" }}>
            <span style={{ fontWeight: "500", marginBottom: "3px" }}>
              Shipping Cost
            </span>
            <Input
              style={{ borderRadius: "8px", height: "48px" }}
              type="number"
              prefix={store?.currency}
              onChange={(e) => handleShippingCost(e)}
            />
          </div>
        </div>
        <hr></hr>
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            gap: "15px",
            marginTop: "20px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              borderRadius: "8px",
              height: "48px",
              padding: "0px 10px",
              border: "1px solid #DDDDDD",
              alignItems: "center",
              gap: "10px",
              background: "#F9F9F9",
            }}
          >
            <input
              type="checkbox"
              id="createInvoiceCheckbox"
              checked={isCreateInvoice}
              onChange={handleCreateInvoice}
            />

            <label htmlFor="shareProfitCheckbox">Create Invoice</label>
          </div>
          <div
            style={{
              display: "flex",
              borderRadius: "8px",
              height: "48px",
              padding: "0px 10px",
              border: "1px solid #DDDDDD",
              alignItems: "center",
              gap: "10px",
              background: "#F9F9F9",
              cursor: "pointer",
            }}
          >
            <input
              type="checkbox"
              id="shareProfitCheckbox"
              checked={isDropOffPending}
              onChange={(e) => setIsDropOffPending(e.target.checked)}
            />
            <label htmlFor="shareProfitCheckbox">Drop Off Pending</label>
          </div>
          <div
            style={{
              display: "flex",
              borderRadius: "8px",
              height: "48px",
              padding: "0px 10px",
              border: "1px solid #DDDDDD",
              alignItems: "center",
              gap: "10px",
              background: "#F9F9F9",
            }}
          >
            <input
              type="checkbox"
              id="shareProfitCheckbox"
              checked={isPayoutRequired}
              onChange={(e) => setIsPayoutRequired(e.target.checked)}
              disabled
            />
            <label htmlFor="shareProfitCheckbox">Payout Required</label>
          </div>
        </div>
      </CustomModal>
    </>
  );
}
